import * as React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { ResponseError } from '../../apis';
import selectors from '../../store/selectors';
import ConfirmationModal from '../modals/ConfirmationModal';
import { deleteAutomatedMailingReportAction } from '../../store/actions';

export type AutomatedReportsActionType = 'remove' | null;

interface StateProps {
    errorMessage: ResponseError | null;
}

interface OwnProps extends ButtonProps {
    reportId: string;
    disabled?: boolean;
    action: AutomatedReportsActionType;
    size?: string;
    className?: string;
    onSubmitCallback?: () => void;
}

interface DispatchProps {
    deleteAutomatedMailingReportAction: typeof deleteAutomatedMailingReportAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    ready: boolean;
    showModal: boolean;
    action: AutomatedReportsActionType;
}

class AutomatedReportsActions extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
    };

    onActionClick = async (action: AutomatedReportsActionType) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    onSubmit = async () => {
        const { reportId } = this.props;
        this.setState({ ready: false });
        if (this.state.action === null) return;
        try {
            switch (this.state.action) {
                case 'remove':
                    if (reportId) {
                        await this.props.deleteAutomatedMailingReportAction(reportId);
                        this.toggleModal();
                    }
                    break;
                default:
                    break;
            }
        } catch {
            // Error
        }
        this.setState({ ready: true });
    };

    render() {
        const { errorMessage, action, disabled, className, size } = this.props;
        const { showModal, ready } = this.state;
        const isLoading = !ready;

        return (
            <>
                <ConfirmationModal
                    amount={0}
                    isLoading={isLoading}
                    errorCode={errorMessage && errorMessage.code}
                    type={action}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    onSubmit={this.onSubmit}
                />
                <Button
                    className={['text-capitalize', className].join(' ')}
                    color={'link'}
                    disabled={disabled}
                    size={size}
                    onClick={() => this.onActionClick(action)}
                >
                    {action}
                </Button>
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorMessage: selectors.global.getResponseError(state.global.actions, 'remove_automated_mailing_report'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deleteAutomatedMailingReportAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedReportsActions);
