import * as api from '../../apis';
import { ChargeModel, ListSegment, ResponseError, TipModel, UpdateAmountPosted } from '../../apis';
import { CLEAN_CHARGES, SET_CHARGES, UPSERT_CHARGE, SET_CHARGE_TIP } from '../constants';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';
import { ChargeActionType } from '../../components/charge/ChargeActions';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetChargesAction {
    type: SET_CHARGES;
    payload: {
        list: ListSegment<ChargeModel>;
    };
}

export const setChargesAction = (list: ListSegment<ChargeModel>): SetChargesAction => ({
    type: SET_CHARGES,
    payload: {
        list,
    },
});

export interface CleanChargesAction {
    type: CLEAN_CHARGES;
}

export const cleanChargesAction = (): CleanChargesAction => ({
    type: CLEAN_CHARGES,
});

export interface UpsertChargeAction {
    type: UPSERT_CHARGE;
    payload: {
        charge: ChargeModel;
    };
}

export const upsertChargeAction = (charge: ChargeModel): UpsertChargeAction => ({
    type: UPSERT_CHARGE,
    payload: {
        charge,
    },
});

export interface SetChargeTipAction {
    type: SET_CHARGE_TIP;
    payload: {
        tip_model: TipModel;
    };
}

export const setChargeTipAction = (tip_model: TipModel): SetChargeTipAction => ({
    type: SET_CHARGE_TIP,
    payload: {
        tip_model,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ChargeAction = SetChargesAction | CleanChargesAction | UpsertChargeAction | SetChargeTipAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchChargesActionOptions {
    search?: string;
    customerId?: string;
    settlementId?: string;
    skip?: number;
    limit?: number;
    status?: string[];
    from?: string;
    to?: string;
    paymentSourceId?: string;
}

export function fetchChargesAction(options: FetchChargesActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        if (getState().merchant.selected === null) return;

        await call({
            actionName: 'fetch_charges',
            call: () => api.listCharges(options),
            dispatch,
            onSuccess: (body) => {
                dispatch(setChargesAction(body));
            },
        });
    };
}

export function fetchChargeAction(chargeId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If customer already in the list, return directly
        if (getState().charge.list.items.find((value) => value.id === chargeId)) {
            return;
        }

        await call({
            actionName: 'fetch_charge',
            call: () => api.getCharge(chargeId),
            dispatch,
            onSuccess: (body) => dispatch(upsertChargeAction(body)),
        });
    };
}

export function fetchTipAction(chargeId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_tip',
            call: () => api.getTipForCharge(chargeId),
            dispatch,
            onSuccess: (result) => {
                dispatch(setChargeTipAction(result));
            },
        });
    };
}

export function upsertTransactionAction(
    chargeId: string,
    action: ChargeActionType,
    formData: UpdateAmountPosted | null,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('upsert_charge', true));
        try {
            let body;
            switch (action) {
                case 'refund':
                    body = await api.refundCharge(chargeId);
                    break;
                case 'capture':
                    body = await api.captureCharge(chargeId);
                    break;
                case 'update-amount':
                    if (formData) {
                        body = await api.updateChargeAmount(chargeId, formData);
                    }
                    break;
                case 'cancel':
                    body = await api.cancelCharge(chargeId);
                    break;
                default:
                    break;
            }
            if (body) dispatch(upsertChargeAction(body));
            await dispatch(setActionRequestingAction('upsert_charge', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('upsert_charge', e));
            }
            await dispatch(setActionRequestingAction('upsert_charge', false));
            throw e;
        }
    };
}
