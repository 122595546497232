import { ReturnsState } from '../types';
import { CLEAN_RETURNS, SET_RETURN, SWITCH_CONTEXT } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ReturnsState = {
    arr: [],
};

export const returnsReducer: Reducer<ReturnsState, ApplicationAction> = (
    state = unloadedState,
    action,
): ReturnsState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_RETURN:
            const updatedArr = state.arr;
            updatedArr.push(action.payload.return);
            return {
                ...state,
                arr: updatedArr,
            };
        case CLEAN_RETURNS:
            return {
                ...state,
                arr: unloadedState.arr,
            };
        default:
            return state;
    }
};
