import * as React from 'react';

export interface Props {
    type: 'developers' | 'happiness';
    subject?: string;
}

export default function LinkContact(props: Props) {
    let mailto, subject;
    switch (props.type) {
        case 'developers':
            mailto = 'developers@stronghold.co';
            subject = 'Stronghold Pay developers';
            break;
        case 'happiness':
            mailto = 'happiness@stronghold.co';
            subject = 'Stronghold Pay Happiness';
            break;
        default:
            mailto = 'happiness@stronghold.co';
            subject = 'Stronghold Pay Happiness';
    }

    return (
        <a href={`mailto:${mailto}?Subject=${subject}`} target="_top">
            {mailto}
        </a>
    );
}
