import * as React from 'react';
import { ButtonProps, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { upsertTransactionAction } from '../../store/actions';
import {
    CHARGE_STATUS,
    ChargeModel,
    MerchantModel,
    ResponseError,
    UpdateAmountPosted,
    ApplicationRoleModel,
} from '../../apis';
import selectors from '../../store/selectors';
import ConfirmationModal from '../modals/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface StateProps {
    errorMessage: ResponseError | null;
    selectedMerchant: MerchantModel | null;
    roles: ApplicationRoleModel[] | null;
}

interface OwnProps extends ButtonProps {
    charge: ChargeModel;
    actionType?: ChargeActionType;
}

interface DispatchProps {
    upsertTransactionAction: typeof upsertTransactionAction;
}

type Props = StateProps & OwnProps & DispatchProps;
export type ChargeActionType = 'cancel' | 'refund' | 'capture' | 'update-amount' | null;

interface State {
    ready: boolean;
    showModal: boolean;
    action: ChargeActionType;
    amount: string;
}

class ChargeActions extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
        amount: '',
    };

    onActionClick = async (action: ChargeActionType) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    onAmountChange = (amount: string) => {
        this.setState({ amount });
    };
    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    onSubmit = async () => {
        this.setState({ ready: false });
        let formData = null;
        try {
            if (this.state.action === null) return;
            if (this.state.action === 'update-amount') {
                formData = { amount: this.state.amount } as UpdateAmountPosted;
            }
            await this.props.upsertTransactionAction(this.props.charge.id, this.state.action, formData);
            // clear amount
            this.setState({ amount: '' });
            this.toggleModal();
        } catch {
            this.setState({ ready: true });
            // Error
        }
        this.setState({ ready: true });
    };

    canUpdateAmount = () => {
        const { selectedMerchant, roles } = this.props;
        if (!selectedMerchant || !roles) return;
        return selectors.user.canUpdateAmount(roles);
    };

    disabledToolTipMessage = (action: ChargeActionType) => {
        let message;
        switch (action) {
            case 'cancel':
                message = 'Cancel only available for a Created or Authorized transaction.';
                break;
            case 'capture':
                message = 'Capture only available for a Authorized transaction.';
                break;
            case 'refund':
                message = 'Refund only available for a Captured transaction.';
                break;
            case 'update-amount':
                message = 'Updating amount only available for a Created or Authorized transaction.';
                break;
            default:
                break;
        }
        return message;
    };

    renderDropdownItem = (enabled: boolean, action: ChargeActionType) => {
        return (
            <div id={`${action}-disabled`}>
                <DropdownItem className="px-3 p-2" disabled={!enabled} onClick={() => this.onActionClick(action)}>
                    <div className="text-capitalize font-size-normal">
                        {action && selectors.format.formatHypenated(action)}
                    </div>
                    <div className="small text-muted">{this.disabledToolTipMessage(action)}</div>
                </DropdownItem>
            </div>
        );
    };

    renderDropDown = () => {
        const { charge } = this.props;
        const isLoading = !this.state.ready;
        const spinner = <Spinner type="grow" color="black" size="sm" width={'100%'} />;
        const enableCancel = charge.status === CHARGE_STATUS.CREATED || charge.status === CHARGE_STATUS.AUTHORIZED;
        const enableCapture = charge.status === CHARGE_STATUS.AUTHORIZED;
        const enableUpdateAmount =
            charge.status === CHARGE_STATUS.CREATED || charge.status === CHARGE_STATUS.AUTHORIZED;
        const enableRefund = charge.status === CHARGE_STATUS.CAPTURED;

        return (
            <UncontrolledDropdown disabled={!this.props.charge || isLoading}>
                <DropdownToggle caret={!isLoading} disabled={isLoading} color="outline-primary" size="sm">
                    Select action {isLoading && spinner}
                </DropdownToggle>
                <DropdownMenu>
                    {this.renderDropdownItem(enableCancel, 'cancel')}
                    {this.renderDropdownItem(enableCapture, 'capture')}
                    {this.canUpdateAmount() && (
                        <>
                            {this.renderDropdownItem(enableUpdateAmount, 'update-amount')}
                            <DropdownItem divider />
                        </>
                    )}
                    {this.renderDropdownItem(enableRefund, 'refund')}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    renderSingleChargeAction = (type: ChargeActionType) => {
        const enableUpdateAmount =
            this.props.charge.status === CHARGE_STATUS.CREATED || this.props.charge.status === CHARGE_STATUS.AUTHORIZED;
        if (type === 'update-amount' && (!enableUpdateAmount || !this.canUpdateAmount())) return null;
        return (
            <FontAwesomeIcon
                className="align-middle h5 ml-1 text-primary hover-pointer"
                icon={faEdit}
                onClick={() => this.onActionClick(type)}
            />
        );
    };

    render() {
        const { charge, errorMessage, actionType } = this.props;

        const { showModal, action } = this.state;
        const isLoading = !this.state.ready;

        return (
            <>
                <ConfirmationModal
                    amount={charge.amount}
                    isLoading={isLoading}
                    errorCode={errorMessage && errorMessage.code}
                    type={action}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    onSubmit={this.onSubmit}
                    onAmountChange={this.onAmountChange}
                    updatedAmount={this.state.amount}
                />
                {!actionType ? this.renderDropDown() : this.renderSingleChargeAction(actionType)}
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorMessage: selectors.global.getResponseError(state.global.actions, 'upsert_charge'),
    roles: state.authentication.user.roles || null,
    selectedMerchant: state.merchant.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            upsertTransactionAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ChargeActions);
