import { request, segmentListLimit } from './utils';
import { ListSegment } from './response';
import { stringify } from 'querystring';
import { uniqueId } from 'lodash';

export interface IndividualModel {
    first_name: string;
    last_name: string;
    date_of_birth?: string;
    email?: string;
    mobile?: string;
}

export interface CustomerModel {
    id: string;
    country: string;
    state: string;
    external_id: string;
    individual: IndividualModel;
    is_blocked: boolean;
    email_block_expires_at: string | null;
    sms_block_expires_at: string | null;
}

export interface CreateCustomerRequest {
    country: string;
    state: string;
    external_id?: string;
    individual: IndividualModel;
}

const listCustomersCancelTokenId = uniqueId();
export async function listCustomers(
    options: {
        skip?: number;
        limit?: number;
        firstName?: string;
        lastName?: string;
        email?: string;
        mobile?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<CustomerModel>>({
        method: 'GET',
        url: `/api/customers?${query}`,
        cancelTokenId: listCustomersCancelTokenId,
    });
    return res.result;
}

export async function getCustomer(customerId: string) {
    const res = await request<CustomerModel>({
        method: 'GET',
        url: `/api/customers/${customerId}`,
    });
    return res.result;
}

export async function createCustomer(data: CreateCustomerRequest) {
    const res = await request<CustomerModel>({
        method: 'POST',
        url: `/api/customers`,
        data,
    });
    return res.result;
}

export async function updateCustomer(data: CreateCustomerRequest, customerId: string) {
    const res = await request<CustomerModel>({
        method: 'PATCH',
        url: `/api/customers/${customerId}`,
        data,
    });
    return res.result;
}
