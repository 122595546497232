import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ROUTE } from '../../config';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    InputGroup,
    InputGroupAddon,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
} from 'reactstrap';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { FetchTransactionsReportActionOptions, fetchTransactionsReportAction } from '../../store/actions';
import { debounce } from 'lodash';
import selectors from '../../store/selectors';
import { DateTime } from 'luxon';
import { faCaretRight, faCaretLeft, faEllipsisH, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadTransactionsReport, SummaryModel, TransactionsReportModel } from '../../apis';
import PlaceholderLoader from '../../components/PlaceholderLoader';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';

interface StateProps {
    isLoading: boolean;
    legalName: string | null;
    merchantName: string | null;
    report: TransactionsReportModel | null;
}

type OwnProps = RouteComponentProps;

interface DispatchProps {
    fetchTransactionsReportAction: typeof fetchTransactionsReportAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    from: DateTime;
    to: DateTime;
    open: boolean;
}

class TransactionsReport extends React.Component<Props, State> {
    private today = DateTime.now();
    private monthStart = this.today.startOf('month');
    private placeHolderWidth = '200px';
    private placeHolderMargin = '2px';

    constructor(props: Props) {
        super(props);
        this.state = {
            from: this.monthStart,
            to: this.today,
            open: false,
        };
    }

    handleMonthChange = async (date: DateTime) => {
        const to = date.startOf('month').plus({ months: 1 }).minus({ days: 1 });
        await this.setState({ from: date, to });
        this.fetchTransactionsReportDebounced();
    };

    componentDidMount = async () => {
        try {
            const { from, to } = this.state;
            const options: FetchTransactionsReportActionOptions = {
                from: selectors.format.formatDateIso(from) || undefined,
                to: selectors.format.formatDateIso(to) || undefined,
            };
            await this.props.fetchTransactionsReportAction(options);
        } catch {
            // Error happen, redirect
            this.props.history.push(ROUTE.REPORTS);
        }
    };

    fetchTransactionReport = () => {
        const { from, to } = this.state;
        const options: FetchTransactionsReportActionOptions = {
            from: selectors.format.formatDateIso(from) || undefined,
            to: selectors.format.formatDateIso(to) || undefined,
        };

        this.props.fetchTransactionsReportAction(options);
    };

    fetchTransactionsReportDebounced = debounce(this.fetchTransactionReport, 500);

    downloadTransactionsReport = () => {
        const { from, to } = this.state;
        downloadTransactionsReport({
            from: selectors.format.formatDateIso(from) || undefined,
            to: selectors.format.formatDateIso(to) || undefined,
            format: 'csv',
        });
    };

    renderTransactionsSummary = (summary: SummaryModel | null, isDebit?: boolean) => {
        const { isLoading } = this.props;
        const classText = isDebit ? 'text-danger' : 'text-primary';
        return (
            <div className="mx-3 d-flex justify-content-between small">
                <div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Total
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Convenience Fees
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Gross
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Fees
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Net
                        </PlaceholderLoader>
                    </div>
                </div>
                <div className="text-right">
                    <div className={classText}>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {summary ? selectors.format.formatAmount(summary.total) : '—'}
                        </PlaceholderLoader>
                    </div>
                    <div className="text-muted">
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {summary ? selectors.format.formatAmount(summary.convenience) : '—'}
                        </PlaceholderLoader>
                    </div>
                    <div className={classText}>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {summary ? selectors.format.formatAmount(summary.gross) : '—'}
                        </PlaceholderLoader>
                    </div>
                    <div className="text-muted">
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {summary ? selectors.format.formatAmount(summary.fees) : '—'}
                        </PlaceholderLoader>
                    </div>
                    <div className={classText}>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {summary ? selectors.format.formatAmount(summary.net) : '—'}
                        </PlaceholderLoader>
                    </div>
                </div>
            </div>
        );
    };

    renderTotals = (report: TransactionsReportModel | null) => {
        const { isLoading } = this.props;
        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <PlaceholderLoader
                            isLoading={isLoading}
                            width={this.placeHolderWidth}
                            margin={this.placeHolderMargin}
                        >
                            Total
                        </PlaceholderLoader>
                    </div>
                    <div className="text-right">
                        <PlaceholderLoader isLoading={isLoading} margin={'2px'}>
                            {report ? selectors.format.formatAmount(report.total) : '—'}
                        </PlaceholderLoader>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <PlaceholderLoader
                            isLoading={isLoading}
                            width={this.placeHolderWidth}
                            margin={this.placeHolderMargin}
                        >
                            Net
                        </PlaceholderLoader>
                    </div>
                    <div className="text-right">
                        <PlaceholderLoader isLoading={isLoading} margin={'2px'}>
                            {report ? selectors.format.formatAmount(report.net) : '—'}
                        </PlaceholderLoader>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <PlaceholderLoader
                            isLoading={isLoading}
                            width={this.placeHolderWidth}
                            margin={this.placeHolderMargin}
                        >
                            Gross
                        </PlaceholderLoader>
                    </div>
                    <div className="text-right">
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {report ? selectors.format.formatAmount(report.gross) : '—'}
                        </PlaceholderLoader>
                    </div>
                </div>
            </>
        );
    };

    renderReport = () => {
        const { report, legalName, isLoading } = this.props;

        return (
            <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faReceipt} className="mr-2" />
                        <span className="ml-2">Transactions Report</span>
                    </div>
                    <Dropdown
                        isOpen={this.state.open}
                        toggle={() => this.setState({ open: !this.state.open })}
                        direction="down"
                    >
                        <DropdownToggle
                            size="sm"
                            color="secondary"
                            outline
                            caret={false}
                            className="d-flex align-items-center rounded-circle p-2"
                        >
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem
                                disabled={!report}
                                onClick={() => this.downloadTransactionsReport()}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div>Download Transactions Report</div>
                                <FontAwesomeIcon icon={faFileCsv} fixedWidth className="ml-2" />
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </CardHeader>
                <CardBody>
                    <div className="font-weight-bold">
                        <PlaceholderLoader
                            isLoading={isLoading}
                            width={this.placeHolderWidth}
                            margin={this.placeHolderMargin}
                        >
                            {this.props.merchantName}
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            {legalName}
                        </PlaceholderLoader>
                    </div>
                    <div>
                        <PlaceholderLoader
                            isLoading={isLoading}
                            width={this.placeHolderWidth}
                            margin={this.placeHolderMargin}
                        >
                            For the period {report && report.date}
                        </PlaceholderLoader>
                    </div>
                </CardBody>
                <CardBody>
                    <div className="text-right">
                        <PlaceholderLoader isLoading={isLoading} width={'200px'} margin={'2px'}>
                            {report && report.date}
                        </PlaceholderLoader>
                    </div>
                    <hr />
                    {this.renderTotals(report)}
                    <hr />
                    <div className="font-weight-bold">
                        <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                            Credit
                        </PlaceholderLoader>
                    </div>
                    <div className="ml-2">
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Charges
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.charges)}
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Returns
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.returns_credit)}
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Adjustments
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.adjustments_credit, false)}
                    </div>
                    <hr />

                    <div className="font-weight-bold">
                        <PlaceholderLoader isLoading={isLoading} margin={'2px'}>
                            Debit
                        </PlaceholderLoader>
                    </div>
                    <div className="ml-2">
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Refunds
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.refunds, true)}
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Returns
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.returns_debit, true)}
                        <div className="small mt-2 border-top">
                            <PlaceholderLoader isLoading={isLoading} margin={this.placeHolderMargin}>
                                Adjustment
                            </PlaceholderLoader>
                        </div>
                        {this.renderTransactionsSummary(report && report.adjustments_debit, true)}
                    </div>
                    <hr />
                    {this.renderTotals(report)}
                    <hr />
                </CardBody>
            </Card>
        );
    };

    render() {
        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.REPORTS,
                title: 'Reports',
            },
            {
                href: ROUTE.REPORTS_TRANSACTIONS,
                title: 'Transactions',
            },
        ];

        const { from } = this.state;

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button
                                            color="primary"
                                            onClick={() => this.handleMonthChange(from.minus({ months: 1 }))}
                                        >
                                            <span aria-hidden="true">
                                                <FontAwesomeIcon size={'lg'} icon={faCaretLeft} />
                                            </span>
                                        </Button>
                                    </InputGroupAddon>
                                    <Input
                                        className="h-5 text-center"
                                        disabled={true}
                                        readOnly={true}
                                        value={from.toFormat('MMMM yyyy')}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            color="primary"
                                            disabled={from.month === this.today.month}
                                            onClick={() => this.handleMonthChange(from.plus({ months: 1 }))}
                                        >
                                            <span aria-hidden="true">
                                                <FontAwesomeIcon size={'lg'} icon={faCaretRight} />
                                            </span>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {this.renderReport()}
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isLoading: isActionRequesting(state.global.actions, 'fetch_transactions_report'),
    legalName: state.merchant.selected ? state.merchant.selected.legal_name : null,
    merchantName: state.merchant.selected ? state.merchant.selected.display_name : null,
    report: state.reports.transactions,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchTransactionsReportAction,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionsReport));
