import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { CardBody, Table } from 'reactstrap';
import { ApiKeyModel } from '../apis';
import Hidden from '../components/Hidden';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import selectors from '../store/selectors';
import LinkContact from '../components/LinkContact';
import TableBody from '../components/TableBody';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    sandboxKeys: ApiKeyModel[];
    liveKeys: ApiKeyModel[];
    showLiveData: boolean;
    isRequesting: boolean;
    timezone: string | null;
}

interface State {
    showSecretKey: boolean;
}

type Props = StateProps;

class Developers extends React.Component<Props, State> {
    state: State = {
        showSecretKey: false,
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.showLiveData !== this.props.showLiveData) {
            this.setState({ showSecretKey: false });
        }
    }

    renderKey = (apiKey: ApiKeyModel) => {
        const keyValue = apiKey.key;

        const key = <div className="p-0">{keyValue}</div>;

        return (
            <Hidden
                show={apiKey.publishable || apiKey.environment !== 'live' || this.state.showSecretKey}
                onShow={() => this.setState({ showSecretKey: true })}
            >
                {key}
            </Hidden>
        );
    };

    renderTable = () => {
        const { isRequesting, showLiveData } = this.props;
        const sandboxKeys = this.props.sandboxKeys;
        const liveKeys = this.props.liveKeys;
        const keys = showLiveData ? liveKeys : sandboxKeys;
        let tableBody = <></>;
        let noKeysText = <></>;

        // If keys are requesting
        if (isRequesting) {
            // We show the loading table
            tableBody = <TableBody isLoading={true} rows={2} columns={4} />;
        }
        // If no keys present
        else if (keys.length === 0) {
            // We show a description why
            noKeysText = (
                <CardBody>
                    {showLiveData ? (
                        <>
                            Please contact <LinkContact type="developers" /> to request live environment access and to
                            generate live API keys.
                        </>
                    ) : (
                        <>
                            Please contact <LinkContact type="developers" /> in order to enrol as a developer.
                        </>
                    )}
                </CardBody>
            );
        }
        // If there are keys, we show them
        else {
            tableBody = (
                <tbody>
                    {keys.map((el, key) => (
                        <tr key={key}>
                            <td>{this.renderKey(el)}</td>
                            <td className="d-flex justify-content-center">
                                <FontAwesomeIcon
                                    color={el.publishable ? '#1CB06C' : '#909090'}
                                    icon={el.publishable ? faCheckCircle : faMinusCircle}
                                    size={'lg'}
                                />
                            </td>
                            <td className="text-upper">
                                {selectors.format.formatDate(el.last_used_at, this.props.timezone) || '—'}
                            </td>
                            <td className="text-upper text-right">
                                {selectors.format.formatDate(el.created_at, this.props.timezone) || '—'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            );
        }

        return (
            <>
                <Table className="mb-0 table-lg" responsive>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Publishable</th>
                            <th>Last used</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    {tableBody}
                </Table>
                {noKeysText}
            </>
        );
    };

    render() {
        return (
            <Layout>
                <div className="mt-4">
                    <h1>Developers</h1>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center">
                            <h2>API</h2>
                        </div>
                        <a
                            className="hover-pointer d-flex"
                            href="https://docs.strongholdpay.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="mr-2 font-weight-bold">Learn more about our API </span>
                            <img width={'8px'} className="link-icon" src={chevronRight} />
                        </a>
                    </div>
                    <Table className="mb-0 table-lg mb-4" responsive>
                        <thead>
                            <tr>
                                <th>Version</th>
                                <th>Last used</th>
                                <th>Last 7 Days</th>
                                <th>Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2020-03-12</td>
                                <td>—</td>
                                <td>—</td>
                                <td>—</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <hr className="mb-4" />
                <div>
                    <div className="mb-4">
                        <div className="d-flex align-items-center">
                            <h2>Keys</h2>
                            <div className="env-badge ml-2"> {this.props.showLiveData ? 'Live' : 'Sandbox'}</div>
                        </div>
                    </div>
                    {this.renderTable()}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    sandboxKeys: state.global.environments.sandbox_keys,
    liveKeys: state.global.environments.live_keys,
    showLiveData: state.global.environmentData === 'live',
    isRequesting: selectors.global.isActionRequesting(state.global.actions, 'login'),
    timezone: state.merchant.selected?.timezone || null,
});

// tslint:disable-next-line:variable-name
export default connect(mapStateToProps)(Developers);
