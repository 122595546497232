import { ApplicationState } from '../index';

export function getPortalUrl(state: ApplicationState): string {
    if (state.merchant.selected == null || state.global.configuration.portal_url == '') return '';

    return (
        state.merchant.selected.short_name +
        (state.global.environmentData != 'live' ? '-sandbox.' : '.') +
        state.global.configuration.portal_url
    );
}
