import {
    CLEAN_PAYMENT_SOURCE,
    SET_PAYMENT_SOURCE,
    SWITCH_CONTEXT,
    SET_PAYMENT_SOURCES,
    UPDATE_PAYMENT_SOURCE,
} from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { PaymentSourcesState } from '../types';
import { map, orderBy } from 'lodash';
import { segmentListLimit } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PaymentSourcesState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
};

export const paymentSourcesReducer: Reducer<PaymentSourcesState, ApplicationAction> = (
    state = unloadedState,
    action,
): PaymentSourcesState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_PAYMENT_SOURCE:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: [action.payload.data],
                },
            };
        case SET_PAYMENT_SOURCES:
            return {
                ...state,
                list: {
                    ...action.payload.list,
                    items: orderBy(action.payload.list.items, ['active'], ['desc']),
                },
            };
        case UPDATE_PAYMENT_SOURCE:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: map(state.list.items, (item) =>
                        item.id === action.payload.data.id ? action.payload.data : item,
                    ),
                },
            };
        case CLEAN_PAYMENT_SOURCE:
            return {
                ...state,
                list: unloadedState.list,
            };
        default:
            return state;
    }
};
