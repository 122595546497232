import * as React from 'react';
import { PropsWithChildren, ReactNode } from 'react';
import { USER_ROLE } from '../../store/types';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { ApplicationRoleModel } from '../../apis';
import selectors from '../../store/selectors';

type OwnProps = PropsWithChildren<{
    role: USER_ROLE;
    unactiveComponent?: ReactNode;
}>;

interface StateProps {
    roles: ApplicationRoleModel[] | null;
}

type Props = OwnProps & StateProps;

class ToggleRole extends React.Component<Props> {
    render() {
        const { role, roles, unactiveComponent, children } = this.props;
        let hasAccess;
        switch (role as USER_ROLE) {
            case USER_ROLE.MERCHANT_ALLADMIN:
                hasAccess = selectors.user.isAdmin(roles);
                break;
            case USER_ROLE.MERCHANT_ALLUSERS:
                hasAccess = selectors.user.isAdmin(roles) || selectors.user.isRegularUser(roles);
                break;
        }
        if (hasAccess === true) {
            return children;
        }

        return unactiveComponent || null;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    roles: state.authentication.user.roles,
});

export default connect(mapStateToProps, {})(ToggleRole);
