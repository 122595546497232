import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import { RouteComponentProps, withRouter } from 'react-router';
import selectors from '../store/selectors';
import SHQRCode from '../components/SHQRCode';

interface StateProps {
    portalUrl: string;
}

type Props = StateProps & RouteComponentProps;

class InStore extends React.Component<Props> {
    render() {
        const portalUrl = this.props.portalUrl;
        return (
            <Layout>
                <div className="mb-4">
                    <h1 className="text-capitalize">In Store</h1>
                </div>
                <h3>
                    Get all the benefits of digital payments with Stronghold for customers shopping in your store. Avoid
                    the pitfalls of cash and unreliable card solutions.
                </h3>
                <span>
                    Print and post the QR code below in your store.
                    <br />
                    Ask customers to sign up and link their bank account in this simple, branded flow.
                    <br />
                    Once your customers have signed up, purchasing with Stronghold is now a one-click checkout.
                    <div style={{ margin: '20px' }}>
                        <SHQRCode url={portalUrl} size={500} />
                    </div>
                </span>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    portalUrl: selectors.merchant.getPortalUrl(state),
});

export default connect(mapStateToProps, null)(withRouter(InStore));
