import * as React from 'react';
import { Row } from 'reactstrap';
import { TipModel } from '../../apis';
import Col from 'reactstrap/lib/Col';
import selectors from '../../store/selectors';
import ChargeStatusBadge from './ChargeStatusBadge';
import Tooltip from '../Tooltip';

interface Props {
    tipModel: TipModel;
    timezone: string | null;
}

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
export default function TipDetails(props: Props) {
    const { tipModel, timezone } = props;
    return (
        <div className="mb-5">
            <div className="d-flex mb-3">
                <h5 className="mb-0 text-capitalize">Tip Details</h5>
            </div>

            <Row className="mb-3">
                <Col md={4}>
                    <div className="detail-card">
                        <h3>Amount</h3>
                        <h2 className="text-success">
                            {tipModel.amount && selectors.format.formatAmount(tipModel!.amount)}
                        </h2>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="detail-card">
                        <div className={`${tipModel.fee === 0 && 'opacity-1'}`}>
                            <h3>Fee</h3>
                            <div className="m-0">
                                {tipModel.fee > 0 ? selectors.format.formatAmount(tipModel.fee) : '—'}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={4}>
                    <div className="detail-card">
                        <div className="h5">Status</div>
                        {tipModel && <ChargeStatusBadge status={tipModel.status} />}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="detail-card">
                        <div className={`${!tipModel.beneficiary_name && 'opacity-1'}`}>
                            <h3>Beneficiary Name</h3>
                            <div className="m-0">{(tipModel && tipModel.beneficiary_name) || '—'}</div>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    {tipModel && (
                        <div className="detail-card">
                            <div
                                className={`${
                                    tipModel &&
                                    !tipModel.details.drawer_id &&
                                    !tipModel.details.terminal_id &&
                                    'opacity-1'
                                }`}
                            >
                                <h3>Point of Sale</h3>
                                <Tooltip className="d-flex align-items-center" tooltip="Drawer ID">
                                    <div>{(tipModel && tipModel.details.drawer_id) || '—'}</div>
                                </Tooltip>
                                <Tooltip className="d-flex align-items-center" tooltip="Terminal ID">
                                    <div>{(tipModel && tipModel.details.terminal_id) || '—'}</div>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md={4}>
                    <div className="detail-card">
                        <h3>Created at</h3>
                        <div className="m-0">
                            {selectors.format.formatDateTimeSecondsWithYear(tipModel.created_at, timezone) || '—'}
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="detail-card">
                        <div className={`${!tipModel.authorized_at && 'opacity-1'}`}>
                            <h3>Authorized at</h3>
                            <div className="m-0">
                                {selectors.format.formatDateTimeSecondsWithYear(tipModel.authorized_at, timezone) ||
                                    '—'}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="detail-card">
                        <div className={`${!tipModel.captured_at && 'opacity-1'}`}>
                            <h3>Captured at</h3>
                            <div className="m-0">
                                <>
                                    {selectors.format.formatDateTimeSecondsWithYear(tipModel.captured_at, timezone) ||
                                        '—'}
                                </>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Tooltip tooltip="Tip Id">
                <span className="py-2 id-badge">{tipModel && tipModel.id}</span>
            </Tooltip>
        </div>
    );
}
