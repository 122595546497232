import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MapStateToPropsParam, connect } from 'react-redux';
import { ROUTE } from '../../config';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import { Button, Input, ButtonGroup, FormGroup, Col, Row, Spinner } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';
import {
    AutomatedMailingReportModel,
    AutomatedMailingReportPosted,
    DashboardUserModel,
    ResponseError,
} from '../../apis';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import {
    addAutomatedMailingReportAction,
    resetActionStatusAction,
    fetchDashboardUsersAction,
} from '../../store/actions';
import { isActionRequesting } from '../../store/selectors/global';
import selectors from '../../store/selectors';
import PlaceholderLoader from '../../components/PlaceholderLoader';

interface StateProps {
    users: DashboardUserModel[];
    automatedReports: AutomatedMailingReportModel[];
    isLoading: boolean;
    fetchingUsers: boolean;
    error: ResponseError | null;
}

interface DispatchProps {
    fetchDashboardUsersAction: typeof fetchDashboardUsersAction;
    addAutomatedMailingReportAction: typeof addAutomatedMailingReportAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

type OwnProps = RouteComponentProps;

interface State {
    interval: string;
    user: string;
    reportType: string;
    open: boolean;
    intervalType: number;
    validationError: string;
    successMessage: string;
}

type Props = StateProps & OwnProps & DispatchProps;

class AutomatedMailingReport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            successMessage: '',
            validationError: '',
            interval: '',
            user: '',
            reportType: '',
            open: false,
            intervalType: 1,
        };
    }

    componentDidMount = async () => {
        await this.props.fetchDashboardUsersAction();
    };

    handleTypeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await this.setState({ reportType: e.target.value });
    };

    handleIntervalChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await this.setState({ interval: e.target.value });
    };

    handleUserChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await this.setState({ user: e.target.value });
    };

    toggleTab = (intervalType: number) => {
        this.setState({
            intervalType,
            interval: intervalType === 3 ? 'everyday' : '',
        });
    };

    renderMonthDayInterval = () => {
        const dayCount = Array.from(Array(31).keys());
        return (
            <Input
                className="bg-light border-2 h-100"
                bsSize="lg"
                type="select"
                onChange={this.handleIntervalChange}
                value={this.state.interval}
            >
                <option value={''} disabled={true}>
                    Select one...
                </option>
                {dayCount.map((el, index) => (
                    <option key={index} value={(el + 1).toString()}>
                        {el + 1}
                    </option>
                ))}
            </Input>
        );
    };

    renderUserSelect = () => {
        const { users, fetchingUsers } = this.props;
        return (
            <FormGroup>
                <PlaceholderLoader isLoading={fetchingUsers} width={'100%'}>
                    <Input
                        className="bg-light border-2"
                        bsSize="lg"
                        type="select"
                        onChange={this.handleUserChange}
                        value={this.state.user}
                    >
                        <option value={''} disabled={true}>
                            Select one...
                        </option>
                        {users.map((el, index) => (
                            <option key={index} value={el.email}>
                                {el.email}
                            </option>
                        ))}
                    </Input>
                </PlaceholderLoader>
            </FormGroup>
        );
    };

    renderWeekDayInterval = () => {
        return (
            <Input
                className="bg-light border-2 h-100"
                bsSize="lg"
                type="select"
                onChange={this.handleIntervalChange}
                value={this.state.interval}
            >
                <option value={''} disabled={true}>
                    Select one...
                </option>
                <option value={'Monday'}>Monday</option>
                <option value={'Tuesday'}>Tuesday</option>
                <option value={'Wednesday'}>Wednesday</option>
                <option value={'Thursday'}>Thursday</option>
                <option value={'Friday'}>Friday</option>
                <option value={'Saturday'}>Saturday</option>
                <option value={'Sunday'}>Sunday</option>
            </Input>
        );
    };

    renderReportTypeSelect = () => {
        const { fetchingUsers } = this.props;
        return (
            <FormGroup>
                <PlaceholderLoader isLoading={fetchingUsers} width={'100%'}>
                    <Input
                        className="bg-light border-2 h-100"
                        bsSize="lg"
                        type="select"
                        name="reportTypeSelect"
                        onChange={this.handleTypeChange}
                        id="reportTypeSelect"
                        value={this.state.reportType}
                    >
                        <option value={''} disabled={true}>
                            Select one...
                        </option>
                        <option value={'SettlementReport'}>Settlement Report</option>
                        <option value={'SettlementDetailsReport'}>Settlement Details Report</option>
                        <option value={'ActivityDetailsReport'}>Activity Details Report</option>
                        <option value={'OutstandingReturnsByTransactionDateReport'}>
                            Outstanding Returns by Transaction Date Report
                        </option>
                        <option value={'OutstandingReturnsByReturnDateReport'}>
                            Outstanding Returns by Return Date Report
                        </option>
                    </Input>
                </PlaceholderLoader>
            </FormGroup>
        );
    };

    resetForm = async () => {
        await this.setState({ interval: '' });
        await this.setState({ user: '' });
        await this.setState({ reportType: '' });
        await this.setState({ intervalType: 1 });
    };

    onSubmit = async () => {
        await this.props.resetActionStatusAction('create_automated_mailing_report');
        const automatedMailingReport = {
            email: this.state.user,
            interval: this.state.interval,
            type: this.state.reportType,
        } as AutomatedMailingReportPosted;

        try {
            const user = this.state.user;
            await this.props.addAutomatedMailingReportAction(automatedMailingReport);
            await this.resetForm();
            this.setState({ successMessage: `Automated report created for ${user}.` });
        } catch {
            // show error
        }
    };

    formatErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            default:
                return 'An error has occured during the action.';
        }
    };

    render() {
        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.REPORTS,
                title: 'Reports',
            },
            {
                href: ROUTE.REPORTS_AUTOMATED_MAILING,
                title: 'Automated Mailing Report',
            },
        ];

        const { error, isLoading, fetchingUsers } = this.props;
        const { interval, reportType, successMessage, validationError, intervalType, user } = this.state;
        const valid = !validationError;
        const spinner = <Spinner className="mr-2" type="grow" color="black" size="sm" width={'100%'} />;
        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div>
                    <div className="border-bottom-gray-sm mb-4">
                        <div className="h3">Opt-in for automated mailing report</div>
                    </div>

                    <div>
                        <div className="h5 mb-4">User Details</div>
                        {validationError && <Alert color="danger-light">{validationError}</Alert>}
                        {successMessage && <Alert color="success-light">{successMessage}</Alert>}
                        {error && <Alert color="danger-light">{this.formatErrorMessage(error.type)}</Alert>}

                        <div className="border-bottom-gray-sm mb-4">
                            <div className="mb-1 font-weight-bold ">Choose User *</div>
                            {this.renderUserSelect()}
                        </div>

                        <div className="h5 mb-4">Mailing Details</div>

                        <div>
                            <div className="mb-1 font-weight-bold ">Report Type *</div>
                            {this.renderReportTypeSelect()}
                        </div>

                        <Row>
                            <Col md={8}>
                                <div className="font-weight-bold ">Choose Interval *</div>
                            </Col>
                            <Col>
                                <div className={`font-weight-bold  ${intervalType === 3 && 'text-gray-300'}`}>
                                    Choose Day {`${intervalType !== 3 ? '*' : ''}`}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <PlaceholderLoader isLoading={fetchingUsers} width={'100%'}>
                                    <ButtonGroup className="w-100">
                                        <Button
                                            color="light"
                                            size="lg"
                                            onClick={() => this.toggleTab(1)}
                                            className={`${intervalType === 1 ? 'btn-dark' : 'btn-gray-150'}`}
                                        >
                                            Month
                                        </Button>
                                        <Button
                                            color="light"
                                            size="lg"
                                            onClick={() => this.toggleTab(2)}
                                            className={`${intervalType === 2 ? 'btn-dark' : 'btn-gray-150'}`}
                                        >
                                            Weekday
                                        </Button>
                                        <Button
                                            color="light"
                                            size="lg"
                                            onClick={() => this.toggleTab(3)}
                                            className={`${intervalType === 3 ? 'btn-dark' : 'btn-gray-150'}`}
                                        >
                                            Everyday
                                        </Button>
                                    </ButtonGroup>
                                </PlaceholderLoader>
                            </Col>
                            <Col>
                                <PlaceholderLoader isLoading={fetchingUsers} width={'100%'}>
                                    {intervalType === 1 && this.renderMonthDayInterval()}
                                    {intervalType === 2 && this.renderWeekDayInterval()}
                                    {intervalType === 3 && (
                                        <div className="h-100">
                                            <span className="d-flex flex-column  py-1 h-100 text-center justify-content-center bg-grey-150">
                                                <div>Report will be sent out everyday</div>
                                            </span>
                                        </div>
                                    )}
                                </PlaceholderLoader>
                            </Col>
                        </Row>
                    </div>

                    <hr />

                    <div className="pt-0">
                        <div className="d-flex justify-content-end">
                            <Button
                                color="primary"
                                size="lg"
                                onClick={() => this.onSubmit()}
                                disabled={!interval || !reportType || !valid || !user}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        {isLoading && spinner}
                                        Create automated mailing
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    users: state.users.arr || [],
    automatedReports: state.reports.automatedMailing.arr || [],
    error: selectors.global.getResponseError(state.global.actions, 'create_automated_mailing_report'),
    fetchingUsers: isActionRequesting(state.global.actions, 'fetch_dashboard_users'),
    isLoading: isActionRequesting(state.global.actions, 'create_automated_mailing_report'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchDashboardUsersAction,
            addAutomatedMailingReportAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedMailingReport);
