import React from 'react';
import { Alert, Input } from 'reactstrap';
const uploadIcon = '/upload.svg';

interface Props {
    onChangeFile: (e: FileList | null) => void;
    fileType: string;
    multiple: boolean;
}

interface State {
    errors: string[];
    files: string[];
}

export default class PayLinkFileUploader extends React.Component<Props, State> {
    private MAX_LENGTH = 5;
    state: State = {
        errors: [],
        files: [],
    };

    fileValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ errors: [] });
        const fileList = e.target.files;
        const { errors } = this.state;
        const errorArray = [];
        const fileArray = [];
        if (fileList) {
            if (fileList.length > this.MAX_LENGTH) errorArray.push(`Cannot upload files more than ${this.MAX_LENGTH}`);
            else {
                for (let i = 0; i < fileList.length; i++) {
                    fileArray.push(fileList[i].name);
                    const fileSize = fileList[i].size;
                    const formattedFileSize = Math.round(fileSize / 1024);
                    if (formattedFileSize >= 20096) {
                        errorArray.push(`${fileList[i].name} is larger than 20mb`);
                    }
                }
            }

            this.setState({ errors: errorArray });
        }
        if (errors.length === 0) {
            this.setState({ files: fileArray });
            this.props.onChangeFile(fileList);
        }
    };

    renderFileNames = () => {
        const { files } = this.state;
        if (files.length > 0) {
            return files.map((fileName, index) => (
                <span key={index}>{fileName + (index === files.length - 1 ? '' : ', ')}</span>
            ));
        }
    };
    render() {
        const isMultiple = this.props.multiple ?? false;
        const { errors, files } = this.state;
        const hasFiles = files.length > 0 && errors.length === 0;
        const uploadDocClasses = hasFiles ? 'no-hover text-success border border-success bg-light' : 'text-dark';
        return (
            <>
                <div>
                    <div>
                        <label
                            htmlFor="upload-document"
                            className={`text-center w-100  hover-pointer form-control form-control-lg ${uploadDocClasses}`}
                        >
                            {hasFiles ? (
                                this.renderFileNames()
                            ) : (
                                <div className="d-flex justify-content-center">
                                    <span className="mr-2">Choose files</span>{' '}
                                    <div>
                                        <img src={uploadIcon} width="19px" height="19px" />
                                    </div>
                                </div>
                            )}
                        </label>
                    </div>
                    <Input
                        id="upload-document"
                        type="file"
                        onChange={this.fileValidation}
                        accept={this.props.fileType}
                        multiple={isMultiple}
                        style={{ display: 'none' }}
                    />
                </div>

                {errors &&
                    errors.map((error, index) => (
                        <Alert key={index} color="danger" className="my-1">
                            {error}
                        </Alert>
                    ))}
            </>
        );
    }
}
