import * as React from 'react';
import { PaymentSourceModel } from '../../apis';
import PlaceholderLoader from '../PlaceholderLoader';
import { Link } from 'react-router-dom';
import selectors from '../../store/selectors';
import Table from 'reactstrap/lib/Table';
import Tooltip from '../Tooltip';
const chevronRight = '/Text-Link-Arrow.svg';

interface Props {
    paymentSource: PaymentSourceModel | null;
}

export default function PaymentSourceDetails(props: Props) {
    const { paymentSource } = props;
    const isLoading = !paymentSource;
    return (
        <div className="mb-5">
            <div className="d-flex mb-3">
                <h5 className="mb-0 text-capitalize">Payment source</h5>
            </div>
            <div className="mb-4">
                <Table responsive className="table-sm mb-0">
                    <thead>
                        <tr>
                            <th className="hover-text-cursor border-bottom-gray-sm">Payment source type</th>
                            <th className="hover-text-cursor border-bottom-gray-sm">Label</th>
                            <th className="border-bottom-gray-sm"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="hover-text-cursor text-capitalize py-3 border-bottom-gray-sm">
                                <PlaceholderLoader width="100%" isLoading={isLoading}>
                                    {paymentSource && paymentSource.type}
                                </PlaceholderLoader>
                            </td>
                            <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                <PlaceholderLoader width="100%" isLoading={isLoading}>
                                    {paymentSource && paymentSource.label}{' '}
                                </PlaceholderLoader>
                            </td>
                            <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                <PlaceholderLoader width="100%" isLoading={isLoading}>
                                    <Link
                                        to={selectors.global.transactionsPaymentFilterRoute(
                                            (paymentSource && paymentSource.id) || '',
                                        )}
                                    >
                                        <span className="mr-2">Filter transactions by source</span>
                                        <span>
                                            <img width={'8px'} className="link-icon" src={chevronRight} />
                                        </span>
                                    </Link>
                                </PlaceholderLoader>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <Tooltip tooltip="Payment Source Id">
                <PlaceholderLoader isLoading={isLoading}>
                    <span className="py-2 id-badge text-small bg-dark-grey-3">{paymentSource && paymentSource.id}</span>
                </PlaceholderLoader>
            </Tooltip>
        </div>
    );
}
