import * as React from 'react';
import { PaymentSourceModel } from '../../apis';
import TableBody from '../TableBody';
import Table from 'reactstrap/lib/Table';
import PaymentSourceActions from './PaymentSourceActions';
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'reactstrap/lib/Alert';
import UnlinkAllButton from './UnlinkAllButton';

interface Props {
    paymentSources: PaymentSourceModel[] | null;
    customerId: string | null;
    isLoading: boolean;
}

function renderNoRows() {
    return (
        <div>
            <Alert color="info-light">There are no sources to display</Alert>
        </div>
    );
}

export default function PaymentSourceTable(props: Props) {
    const { customerId, isLoading, paymentSources } = props;
    const activeIcon = <FontAwesomeIcon size="lg" color="#1CB06C" icon={faCheckCircle} />;
    const unActiveIcon = <FontAwesomeIcon size="lg" color="#909090" icon={faMinusCircle} />;
    const activeSources = paymentSources
        ? paymentSources.filter((s) => {
              return s.active;
          }).length > 0
        : false;

    return (
        <div className="mb-4">
            <div className="mb-3">
                <h2>Payment sources</h2>
            </div>

            <Table hover responsive className="table-lg">
                <thead>
                    <tr>
                        <th className="text-center">Active</th>
                        <th>Payment source type</th>
                        <th>Label</th>
                        <th>{customerId && activeSources ? <UnlinkAllButton customerId={customerId} /> : null}</th>
                    </tr>
                </thead>
                <TableBody isLoading={isLoading} rows={2} columns={4} loaderWidth="100%">
                    {customerId &&
                        paymentSources &&
                        paymentSources.map((source, index) => (
                            <tr key={index}>
                                <td className="text-center">{source.active ? activeIcon : unActiveIcon}</td>
                                <td className={`text-capitalize ${source.active ? '' : 'text-secondary'}`}>
                                    {source && source.type}
                                </td>
                                <td className={`text-capitalize ${source.active ? '' : 'text-secondary'}`}>
                                    {source && source.label}
                                </td>
                                <td>
                                    <PaymentSourceActions paymentSource={source} customerId={customerId} />
                                </td>
                            </tr>
                        ))}
                </TableBody>
            </Table>
            {customerId && paymentSources && paymentSources.length === 0 && renderNoRows()}
        </div>
    );
}
