import { request, segmentListLimit } from './utils';
import { stringify } from 'querystring';
import { ListSegment } from './response';
import { CustomerModel } from './customers';

export interface CreditModel {
    id: string;
    merchant_id: string;
    promotion_id: string;
    customer: CustomerModel;
    redeemed: boolean;
    amount: number;
    originated_from_charge_id: string;
    redeemed_with_charge_id?: string;
    created_at: string;
    updated_at?: string;
}

export async function getCredit(creditId: string) {
    const res = await request<CreditModel>({
        method: 'GET',
        url: `/api/credits/${creditId}`,
    });
    return res.result;
}

export async function listCredits(
    options: {
        skip?: number;
        originatedFromChargeId?: string[];
        redeemedWithChargeId?: string;
        promotionId?: string;
        customerId?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<CreditModel>>({
        method: 'GET',
        url: `/api/credits?${query}`,
    });
    return res.result;
}
