import { PAY_LINK_STATUS, ResponseError } from '../../apis';
import { upperFirst } from 'lodash';

export function formatStatus(status: PAY_LINK_STATUS) {
    return upperFirst(status.replace('_', ' '));
}

export function formatErrorMessage(error: ResponseError) {
    switch (error.code.toString()) {
        case 'pay_link_canceled':
            return 'The pay link was canceled.';
        case 'pay_link_expired':
            return 'The pay link has expired.';
        case 'customer_required':
            return 'The pay link is missing a customer.';
        case 'paylink_missing_delivery_info':
            return "The pay link's customer does not have either one of mobile phone number or email specified.";
        case 'paylink_send_error':
            return error.message;
        case 'pay_link_send_customer_blocked':
            return error.message + " Updating the customer's mobile or email will remove the block.";
        case 'pay_link_send_success_threshold':
            return error.message;
        case 'pay_link_send_failure_threshold':
            return error.message;
        case 'paylink_send_sms_carrier_blocked':
            return error.message;
        default:
            return 'An error has occured during the action.';
    }
}
