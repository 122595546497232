import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Row } from 'reactstrap';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { RouteComponentProps } from 'react-router';
import { ROUTE } from '../../config';
import Col from 'reactstrap/lib/Col';
import Tooltip from '../../components/Tooltip';
import { CreditModel, PromotionModel, TRIGGER_TYPE } from '../../apis';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchCreditsAction, fetchPromotionAction } from '../../store/actions';
import { isActionRequesting } from '../../store/selectors/global';
import PlaceholderLoader from '../PlaceholderLoader';
import CreditsTable from '../credits/CreditsTable';
import { history } from '../../index';
import PromotionReportDetails from './PromotionReportDetails';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    promotion: PromotionModel | null;
    timezone: string | null;
    isLoading: boolean;
}

type OwnProps = RouteComponentProps<{
    promotionId: string;
}>;

interface DispatchProps {
    fetchPromotionAction: typeof fetchPromotionAction;
    fetchCreditsAction: typeof fetchCreditsAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class PromotionDetails extends React.Component<Props> {
    componentDidMount = async () => {
        // If promotion pulled from the State's list of promotions, fetch it.
        if (!this.props.promotion) {
            try {
                await this.props.fetchPromotionAction(this.props.match.params.promotionId);
            } catch {
                this.props.history.push(ROUTE.PROMOTIONS);
                return;
            }
        }
        await this.props.fetchCreditsAction({ promotionId: this.props.match.params.promotionId });
    };

    onOriginChargeIdClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.TRANSACTIONS}/${credit.originated_from_charge_id}`);
    };

    onRedeemedChargeIdClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.TRANSACTIONS}/${credit.redeemed_with_charge_id}`);
    };

    onCreditCustomerClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.CUSTOMERS}/${credit.customer.id}`);
    };

    fetchCredits = (skip?: number) => {
        const { promotion } = this.props;
        this.props.fetchCreditsAction({
            skip,
            promotionId: promotion ? promotion.id : undefined,
        });
    };

    setEndDate = (promotion: PromotionModel) => {
        if (promotion.disabled_on) {
            return selectors.format.formatDateTimeSecondsWithYear(promotion.disabled_on, this.props.timezone);
        }
        if (promotion.end_date) {
            return selectors.format.formatDateTimeSecondsWithYear(promotion.end_date, this.props.timezone);
        }
        return '-';
    };

    render() {
        const { promotion, timezone, isLoading } = this.props;

        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.PROMOTIONS,
                title: 'Promotions',
            },
        ];

        if (promotion) {
            layoutBreadcrumbItems.push({
                href: selectors.global.promotionRoute(promotion.id),
                title: promotion.id.replace('promotion_', ''),
                isId: true,
            });
        }

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div className="mb-5">
                    <div className="d-flex mb-4">
                        <div className="col-8 px-0">
                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                <h1>{promotion && `${promotion.name}`}</h1>
                            </PlaceholderLoader>
                        </div>
                    </div>
                    <div className="border-bottom-gray mb-4">
                        <div className="d-flex mb-4">
                            <h2>Promotion details</h2>
                            {promotion && promotion.external_id ? (
                                <div className="ml-auto mt-auto">
                                    <Tooltip className="d-flex align-items-center" tooltip="External ID">
                                        <span className="id-badge">{promotion.external_id}</span>
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <Row className="mb-3">
                            <Col md={4}>
                                <div className="detail-card">
                                    <h3>Start Date</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        <div className="m-0 ">
                                            {promotion &&
                                                selectors.format.formatDateTimeSecondsWithYear(
                                                    promotion.start_date,
                                                    timezone,
                                                )}
                                        </div>
                                    </PlaceholderLoader>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="detail-card">
                                    <h3>{promotion?.disabled_on ? 'Disabled On' : 'End Date'}</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        <div className="m-0 ">{promotion && this.setEndDate(promotion)}</div>
                                    </PlaceholderLoader>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="detail-card">
                                    <Tooltip tooltip="Indicates if this Promotion has been disabled prior to the end date.">
                                        <h3>Disabled</h3>
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {promotion && promotion.disabled_on ? 'Yes' : 'No'}
                                        </PlaceholderLoader>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={4}>
                                <div className="detail-card">
                                    <h3>Type</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        {promotion &&
                                            selectors.format.formatCapitalizeFirstLetter(promotion.promotion_type)}
                                    </PlaceholderLoader>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="detail-card">
                                    <h3>Benefit Type</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        {promotion &&
                                            selectors.format.formatCapitalizeFirstLetter(promotion.benefit_type)}
                                    </PlaceholderLoader>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="detail-card">
                                    <h3>Trigger Type</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        {promotion && promotion.trigger_type == TRIGGER_TYPE.PURCHASE
                                            ? 'Purchase'
                                            : 'Source Link'}
                                    </PlaceholderLoader>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {promotion && promotion.trigger_type == TRIGGER_TYPE.PURCHASE ? (
                        <div>
                            <Row className="mb-3">
                                <Col md={4}>
                                    <div className="detail-card">
                                        <Tooltip tooltip="Indicates if this Promotional Credit can be redeemed within the same transaction it is earned in.">
                                            <h3>Immediate Use</h3>
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {promotion && promotion.immediate_use ? 'Yes' : 'No'}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="detail-card">
                                        <Tooltip tooltip="Indicates if this Promotional Credit is reserved for a Customer's first purchase, i.e. a new Customer.">
                                            <h3>First Purchase Only</h3>
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {promotion && promotion.first_purchase_only ? 'Yes' : 'No'}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="detail-card">
                                        <Tooltip tooltip="Indicates if a Customer can only earn this Promotional Credit once.">
                                            <h3>Single Use</h3>
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {promotion && promotion.single_use ? 'Yes' : 'No'}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-5">
                                <Col md={4}>
                                    <div className="detail-card">
                                        <h3>Fixed Amount</h3>
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {promotion && selectors.format.formatAmount(promotion.fixed_amount)}
                                        </PlaceholderLoader>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="detail-card">
                                        <Tooltip tooltip="The minimum cart total required to trigger the creation of a Credit for this Promotion.">
                                            <h3>Minimum Charge Amount</h3>
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {promotion &&
                                                    selectors.format.formatAmount(promotion.min_charge_amount || 0)}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            <Row className="mb-5">
                                <Col md={4}>
                                    <div className="detail-card">
                                        <h3>Fixed Amount</h3>
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {promotion && selectors.format.formatAmount(promotion.fixed_amount)}
                                        </PlaceholderLoader>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="detail-card">
                                        <Tooltip tooltip="Whether relinking a previously linked Payment Source qualifies.">
                                            <h3>Relink Allowed</h3>
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {promotion && promotion.relink_allowed ? 'True' : 'False'}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="mb-4 border-bottom-gray">
                        <PromotionReportDetails promotionId={promotion?.id || ''} isLoading={!promotion} />
                    </div>
                    <div className="d-flex">
                        <div className="ml-auto text-right">
                            <h5>Need help with this promotion?</h5>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://stronghold.co/happiness-support-contact"
                                className=" text-primary view-link"
                            >
                                <span className="mr-2 font-weight-bolder">Contact Support</span>
                                <span>
                                    <img width={'8px'} src={chevronRight} className="link-icon" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />

                <CreditsTable
                    onOriginChargeClick={this.onOriginChargeIdClicked}
                    onRedeemedChargeClick={this.onRedeemedChargeIdClicked}
                    onFlexColumnClick={this.onCreditCustomerClicked}
                    onGoTo={(page, skip) => this.fetchCredits(skip)}
                    isLoading={isLoading}
                    showPromoColumn={false}
                />
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => ({
    promotion:
        state.promotions.list.items.find((el) => el.id === ownProps.match.params.promotionId) ||
        state.promotions.selectedPromotion ||
        null,
    timezone: state.merchant.selected?.timezone || null,
    isLoading: isActionRequesting(state.global.actions, 'fetch_promotion'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPromotionAction,
            fetchCreditsAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetails);
