import * as React from 'react';
import { DashboardUserModel, DashboardUserPosted } from '../../apis';
import TableBody from '../TableBody';
import Table from 'reactstrap/lib/Table';
import UsersActions from './UsersActions';
import Alert from 'reactstrap/lib/Alert';
import { sortBy } from 'lodash';

interface Props {
    dashboardUsers: DashboardUserModel[] | null;
    isLoading: boolean;
}

export default function UsersTable(props: Props) {
    const { isLoading, dashboardUsers } = props;
    const sortedDashboardUsers = sortBy(dashboardUsers);

    return (
        <div>
            {sortedDashboardUsers && (
                <Table responsive className="table-lg">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>User Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <TableBody loaderWidth="100%" isLoading={isLoading} rows={3} columns={6}>
                        {sortedDashboardUsers.map((user, index) => (
                            <tr key={index}>
                                <td>{user && user.email}</td>
                                <td>{user && user.first_name}</td>
                                <td>{user && user.last_name}</td>
                                <td>
                                    <UsersActions
                                        action={user && 'update-roles'}
                                        userType={user && user.user_type}
                                        dashBoardUser={
                                            {
                                                last_name: user.last_name,
                                                first_name: user.first_name,
                                                email: user.email,
                                                user_type: user.user_type,
                                            } as DashboardUserPosted
                                        }
                                        dashBoardUserId={user.id}
                                        size="sm"
                                    />
                                </td>
                                <td>
                                    <UsersActions
                                        action={'Remove User'}
                                        dashBoardUser={
                                            {
                                                last_name: user.last_name,
                                                first_name: user.first_name,
                                                email: user.email,
                                                user_type: user.user_type,
                                            } as DashboardUserPosted
                                        }
                                        dashBoardUserId={user.id}
                                        size="sm"
                                    />
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
            )}
            {!isLoading && sortedDashboardUsers && sortedDashboardUsers.length < 1 && (
                <Alert color="info">There are no users to to display</Alert>
            )}
        </div>
    );
}
