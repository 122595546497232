import { request } from './utils';

export interface ProcessingFeeAmountModel {
    payment_processing_fee: number;
}

export interface ProcessingFeeModel {
    id: string;
    charge_id: string;
    config_id: string;
    amount: number;
}

export async function calculateProcessingFee(chargeAmount: number) {
    const res = await request<ProcessingFeeAmountModel>({
        method: 'GET',
        url: `/api/processing-fees?charge_amount=${chargeAmount}`,
    });
    return res.result;
}
