import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { SET_PROCESSING_FEE_AMOUNT } from '../constants';
import { ProcessingFeeState } from '../types';

const unloadedState: ProcessingFeeState = {
    amount: null,
};

export const processingFeeReducer: Reducer<ProcessingFeeState, ApplicationAction> = (
    state = unloadedState,
    action,
): ProcessingFeeState => {
    switch (action.type) {
        case SET_PROCESSING_FEE_AMOUNT:
            return {
                ...state,
                amount: action.payload.processing_fee_amount,
            };
        default:
            return state;
    }
};
