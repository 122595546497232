import * as React from 'react';
import { Link } from 'react-router-dom';
const chevronRight = '/Text-Link-Arrow.svg';
const chevronRightLight = '/white-arrow.svg';
const newImageUrl = '/Rewards-Pattern-small-display.svg';

type Props = React.PropsWithChildren<{
    size: TileSize;
    bgColor: BgColor;
    link?: string;
    externalLink?: string;
    title: string;
    subTitle?: string;
    newTile?: boolean;
    fullHeight?: boolean;
    disabled?: boolean;
}>;

type TileSize = 's' | 'm' | 'l' | 'xl';
type BgColor = 'primary' | 'grey-100' | 'grey-150' | 'cyan' | 'teal' | 'sh-blue';

function getBgColor(bgColor: BgColor) {
    switch (bgColor) {
        case 'primary':
            return 'bg-primary';
            break;
        case 'grey-100':
            return 'bg-grey-100';
            break;
        case 'grey-150':
            return 'bg-grey-150';
            break;
        case 'cyan':
            return 'bg-secondary-2';
            break;
        case 'teal':
            return 'bg-secondary-1';
            break;
        case 'sh-blue':
            return 'bg-primary-2';
            break;
        default:
            return 'bg-grey';
    }
}

function getHeight(size: TileSize) {
    switch (size) {
        case 's':
            return 'px-2 py-1';
            break;
        case 'm':
            return 'px-2 py-3';
            break;
        case 'l':
            return 'px-4 py-4';
            break;
        case 'xl':
            return 'px-4 py-3 h-100';
            break;
        default:
            return 'p-2';
    }
}

function getTitleSize(size: TileSize) {
    switch (size) {
        case 's':
            return 'p';
            break;
        case 'm':
            return 'h3 mt-3';
            break;
        case 'l':
            return 'h2 mt-3 w-50';
        case 'xl':
            return 'h2 mt-3 w-75';
            break;
        default:
            return 'h-5';
    }
}

function renderLink(bgColor: BgColor, subTitle: string, textColor: string) {
    return (
        <div className="font-weight-bold mt-4 mx-4 mb-3 font-weight-bolder">
            <div className={`d-flex align-middle`}>
                <span className={`text-${bgColor === 'grey-150' || bgColor === 'grey-100' ? 'primary' : 'white'} mr-2`}>
                    {subTitle}
                </span>{' '}
                <span>
                    {textColor !== 'dark' ? (
                        <img className="link-icon" width={'8px'} src={chevronRightLight} />
                    ) : (
                        <img className="link-icon" width={'8px'} src={chevronRight} />
                    )}
                </span>
            </div>
        </div>
    );
}

export default function TileLink(props: Props) {
    const { size, link, subTitle, title, bgColor, newTile, fullHeight, externalLink, children } = props;
    const textColor = bgColor === 'grey-100' || bgColor === 'grey-150' ? 'dark' : 'white';
    return externalLink ? (
        <a href={externalLink} target="_blank" rel="noopener noreferrer">
            <div className={`${getBgColor(bgColor)} tile-link ${getHeight(size)} ${fullHeight && 'h-100'}`}>
                {newTile && (
                    <div className="d-flex">
                        <p className="mt-auto mb-0 mr-2">
                            <span className="new-feature">New</span>
                        </p>
                        <img className="img-fluid" style={{ width: '65px' }} src={newImageUrl} />
                    </div>
                )}
                <div className={`d-flex flex-column justify-content-between ${fullHeight && 'h-100'}`}>
                    <div className={`${getTitleSize(size)} text-${textColor} p-3 d-none d-md-block`}>{title}</div>
                    <div className={`text-${textColor} d-md-none text-center  mb-2`}>{title}</div>
                    {children}
                    {subTitle && (
                        <>
                            <div className="d-none d-md-block">{renderLink(bgColor, subTitle, textColor)}</div>
                            <div className="d-flex d-md-none justify-content-center">
                                {renderLink(bgColor, subTitle, textColor)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </a>
    ) : (
        <Link to={link ? link : '#'}>
            <div className={`${getBgColor(bgColor)} tile-link ${getHeight(size)} ${fullHeight && 'h-100'}`}>
                {newTile && (
                    <div className="d-flex">
                        <p className="mt-auto mb-0 mr-2">
                            <span className="new-feature">New</span>
                        </p>
                        <img className="img-fluid" style={{ width: '65px' }} src={newImageUrl} />
                    </div>
                )}
                <div className={`d-flex flex-column justify-content-between ${fullHeight && 'h-100'}`}>
                    <div className={`${getTitleSize(size)} text-${textColor} p-3 d-none d-md-block`}>{title}</div>
                    <div className={`text-${textColor} d-md-none text-center  mb-2`}>{title}</div>
                    {children}
                    {subTitle && (
                        <>
                            <div className="d-none d-md-block">{renderLink(bgColor, subTitle, textColor)}</div>
                            <div className="d-flex d-md-none justify-content-center">
                                {renderLink(bgColor, subTitle, textColor)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Link>
    );
}
