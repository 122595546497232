import * as React from 'react';
import { PromotionReportModel } from '../../apis';
import selectors from '../../store/selectors';
import Table from 'reactstrap/lib/Table';
import TableBody from '../TableBody';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchPromotionReportAction } from '../../store/actions';
import Alert from 'reactstrap/lib/Alert';
import Tooltip from '../Tooltip';

interface OwnerProps {
    promotionId: string;
    isLoading?: boolean;
}

interface StateProps {
    promotionReport: PromotionReportModel | null;
    isRequesting: boolean;
}

interface DispatchProps {
    fetchPromotionReportAction: typeof fetchPromotionReportAction;
}

type Props = StateProps & DispatchProps & OwnerProps;

class PromotionReportDetails extends React.Component<Props> {
    componentDidMount = async () => {
        if (!this.props.isLoading) {
            this.props.fetchPromotionReportAction(this.props.promotionId);
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.props.fetchPromotionReportAction(this.props.promotionId);
        }
    }

    renderCount = (label: string, count: string, toolTipMessage: string) => {
        return (
            <tr>
                <td>
                    <Tooltip className="d-flex align-items-center" tooltip={toolTipMessage}>
                        <span className="text-capitalize id-badge py-2 text-small text-dark">{label}</span>
                    </Tooltip>
                </td>
                <td>
                    <div className={'h5'}>{count || '0'}</div>
                </td>
            </tr>
        );
    };

    renderValue = (label: string, value: string, toolTipMessage: string) => {
        return (
            <tr>
                <td>
                    <Tooltip className="d-flex align-items-center" tooltip={toolTipMessage}>
                        <span className="text-capitalize id-badge py-2 text-small text-dark">{label}</span>
                    </Tooltip>
                </td>
                <td>
                    <div className={'h5'}>{(value && selectors.format.formatAmount(value)) || '$0.00'}</div>
                </td>
            </tr>
        );
    };

    renderNoRows = () => {
        if (this.props.promotionReport !== null || this.props.isRequesting) return null;
        return <Alert color="info-light">There is no report to display</Alert>;
    };

    render() {
        const { promotionReport } = this.props;
        const isLoading = this.props.isRequesting;

        return (
            <div>
                <div className="mb-2">
                    <h2>Promotion Performance Details</h2>
                </div>
                <Table responsive className="table-lg">
                    <TableBody isLoading={isLoading} rows={10} columns={2} loaderWidth="100%">
                        {promotionReport && (
                            <>
                                {this.renderCount(
                                    'Promotional Transactions Earned (#)',
                                    promotionReport.promo_transactions_earned_count,
                                    'Number of transactions where a credit was earned for this promotion.',
                                )}
                                {this.renderValue(
                                    'Promotional Transactions Earned ($)',
                                    promotionReport.promo_transactions_earned_value,
                                    'Sum value of the transactions where a credit was earned for this promotion.',
                                )}
                                {this.renderCount(
                                    'Promotional Transactions Redeemed (#)',
                                    promotionReport.promo_transactions_redeemed_count,
                                    'Number of transactions where a child credit of this promotion was redeemed.',
                                )}
                                {this.renderValue(
                                    'Promotional Transactions Redeemed ($)',
                                    promotionReport.promo_transactions_redeemed_value,
                                    'Sum value of the transactions where a child credit of this promotion was redeemed.',
                                )}

                                {this.renderCount(
                                    'Transactions (#)',
                                    promotionReport.transactions_in_promo_period_count,
                                    "Total number of transactions within this promotion's start and end date.",
                                )}
                                {this.renderValue(
                                    'Transactions ($)',
                                    promotionReport.transactions_in_promo_period_value,
                                    "Sum value of all transactions within this promotion's start and end date.",
                                )}

                                {this.renderCount(
                                    'Transactions per day (#)',
                                    promotionReport.average_transactions_in_promo_period_count,
                                    "Average number of transactions per day within this promotion's start and end date.",
                                )}
                                {this.renderValue(
                                    'Transactions per day ($)',
                                    promotionReport.average_transaction_in_promo_period_value,
                                    "Average transaction value per day within this promotion's start and end date.",
                                )}

                                {this.renderCount(
                                    'Previous 30 Days (# Txn / Day)',
                                    promotionReport.average_transactions_by_day_prior30_count,
                                    "Average number of transactions per day during the 30-day period before the promotion's start date.",
                                )}
                                {this.renderValue(
                                    'Previous 30 Days ($ Txn / Day)',
                                    promotionReport.average_transaction_by_day_prior30_value,
                                    "Average transaction value per day during the 30-day period before the promotion's start date.",
                                )}
                                {this.renderCount(
                                    'Previous 90 Days (# Txn / Day)',
                                    promotionReport.average_transactions_by_day_prior90_count,
                                    "Average number of transactions per day during the 90-day period before the promotion's start date.",
                                )}
                                {this.renderValue(
                                    'Previous 90 Days ($ Txn / Day)',
                                    promotionReport.average_transaction_by_day_prior90_value,
                                    "Average transaction value per day during the 90-day period before the promotion's start date.",
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    promotionReport: state.reports.promotionReport,
    isRequesting: isActionRequesting(state.global.actions, 'fetch_promotion_report') || isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPromotionReportAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PromotionReportDetails);
