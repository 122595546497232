import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Col, InputGroup, InputGroupAddon, InputGroupText, Row, TabContent, TabPane } from 'reactstrap';
import { ApplicationState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Input from 'reactstrap/lib/Input';
import { fetchCustomersAction, FetchCustomersActionOptions, cleanCustomersAction } from '../../store/actions';
import { debounce } from 'lodash';
import { CustomerModel, ResponseError } from '../../apis';
import selectors from '../../store/selectors';
import InputMask from 'react-input-mask';
import CustomerForm from '../../components/forms/Customer';
import Alert from 'reactstrap/lib/Alert';
import CustomersTable from '../../components/customer/CustomersTable';
const searchIcon = '/Search-Icon.svg';

interface OwnProps {
    setCustomer: (customer: CustomerModel) => void;
}

interface StateProps {
    customers: CustomerModel[];
    isRequesting: boolean;
    error: ResponseError | null;
}

interface DispatchProps {
    fetchCustomersAction: typeof fetchCustomersAction;
    cleanCustomersAction: typeof cleanCustomersAction;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    mobile: string;
    email: string;
    activeTab: number;
}

type Field = 'mobile' | 'email';

export class CreateStep1 extends React.Component<Props, State> {
    state: State = {
        mobile: '',
        email: '',
        activeTab: 1,
    };

    componentDidMount = async () => {
        await this.props.cleanCustomersAction();
        await this.props.fetchCustomersAction();
    };

    fetchCustomers = (skip?: number) => {
        const { mobile, email } = this.state;

        let options: FetchCustomersActionOptions = {
            skip,
        };
        options = {
            ...options,
            mobile: selectors.format.formatMobile(mobile, true) || undefined,
            email: email || undefined,
        };

        this.props.fetchCustomersAction(options);
    };

    setCustomer = async (customer: CustomerModel) => {
        await this.props.setCustomer(customer);
    };

    fetchCustomersDebounced = debounce(this.fetchCustomers, 1000);

    onInputChange = async (field: string, label: Field) => {
        await this.setState({ [label]: field } as Pick<State, Field>);
        if ((field.length > 2 || !field) && this.state.activeTab === 1) {
            this.fetchCustomersDebounced();
        }
    };

    onSubmitCustomerCallback = async (customer: CustomerModel) => {
        await this.setCustomer(customer);
    };

    toggleTab = async (activeTab: number) => {
        this.setState({ activeTab }, async () => {
            if (activeTab === 1) {
                await this.props.fetchCustomersAction();
            }
        });
    };

    renderCreateCustomer = () => {
        return <CustomerForm onSubmitCallback={this.onSubmitCustomerCallback} />;
    };

    renderNoRows = () => {
        return <Alert color="info-light">There are no customers to display</Alert>;
    };

    renderSearchCustomer = () => {
        const { isRequesting } = this.props;
        const { mobile, email } = this.state;
        return (
            <>
                <div>
                    <div className="h5 mb-4">Choose an existing paylink customer</div>

                    <Row className="mb-2">
                        <Col>
                            <InputGroup>
                                <InputMask
                                    placeholder="Search by mobile..."
                                    className="form-control form-control-lg"
                                    mask="(999) 999-9999"
                                    value={mobile.toString() || ''}
                                    onChange={(e) => this.onInputChange(e.target.value, 'mobile')}
                                    data-sh="mobile-input"
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <img src={searchIcon} width={'21px'} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup>
                                <Input
                                    value={email.toString() || ''}
                                    onChange={(e) => this.onInputChange(e.target.value, 'email')}
                                    placeholder="Search by email..."
                                    data-sh="email-input"
                                    bsSize="lg"
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <img src={searchIcon} width={'21px'} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <CustomersTable
                        onRowClick={this.setCustomer}
                        onGoTo={(page, skip) => this.fetchCustomers(skip)}
                        isLoading={isRequesting}
                        strongholdDirectPage={true}
                    />
                </div>
            </>
        );
    };

    render() {
        const { activeTab } = this.state;

        return (
            <div>
                <div className="mt-3">
                    <div className="d-flex mt-3 col-12 col-md-8 mb-4">
                        <div>
                            <Button
                                color="none"
                                className={`mr-4  ${activeTab === 1 ? 'btn-gray-100' : 'btn-gray-150'}`}
                                onClick={() => this.toggleTab(1)}
                            >
                                <div className="d-flex">
                                    <div className="mr-3 d-flex flex-column ju<stify-content-center">
                                        <span className="d-none d-md-flex">Choose an existing customer</span>
                                        <span className="d-md-none d-flex">Existing customer</span>
                                    </div>
                                    <span className="d-none d-md-flex">
                                        {activeTab === 1 ? (
                                            <span className="fade-in">
                                                <FontAwesomeIcon
                                                    color="#909090"
                                                    className="fade-in"
                                                    size={'2x'}
                                                    icon={faMinusCircle}
                                                />
                                            </span>
                                        ) : (
                                            <span className="fade-in">
                                                <FontAwesomeIcon
                                                    color="#0b2993"
                                                    className="fade-in"
                                                    size={'2x'}
                                                    icon={faPlusCircle}
                                                />
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </Button>
                        </div>
                        <div>
                            <Button
                                color="none"
                                className={`mr-4  ${activeTab === 2 ? 'btn-gray-100' : 'btn-gray-150'}`}
                                onClick={() => this.toggleTab(2)}
                            >
                                <div className="d-flex">
                                    <div className="mr-3 d-flex flex-column justify-content-center">
                                        <span className="d-none d-md-flex"> Add a new customer</span>
                                        <span className="d-md-none d-flex">New customer</span>
                                    </div>
                                    <span className="d-none d-md-flex">
                                        {activeTab === 2 ? (
                                            <span className="fade-in">
                                                <FontAwesomeIcon color="#909090" size={'2x'} icon={faMinusCircle} />
                                            </span>
                                        ) : (
                                            <span className="fade-in">
                                                <FontAwesomeIcon
                                                    color="#0b2993"
                                                    className="fade-in"
                                                    size={'2x'}
                                                    icon={faPlusCircle}
                                                />
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>

                <TabContent activeTab={activeTab}>
                    <TabPane className="fade-in" tabId={1}>
                        {this.renderSearchCustomer()}
                    </TabPane>
                    <TabPane className="fade-in" tabId={2}>
                        {this.renderCreateCustomer()}
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        customers: state.customer.list.items,
        error: selectors.global.getResponseError(state.global.actions, 'create_pay_link'),
        isRequesting: selectors.global.isActionRequesting(state.global.actions, 'fetch_customers'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchCustomersAction,
            cleanCustomersAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateStep1);
