import { Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { numberRange } from '../../store/selectors/global';
import * as React from 'react';
import { DateTime } from 'luxon';

interface Props {
    from?: DateTime;
    to?: DateTime;
    onChangeFrom: (date: DateTime) => void;
    onChangeTo: (date: DateTime) => void;
}

export default class MonthSelector extends React.Component<Props> {
    private today = DateTime.now();
    constructor(props: Props) {
        super(props);
    }

    handleMonthChange = async (monthNumber: number) => {
        if (this.props.from && this.props.to) {
            const startDate = new Date(this.props.from.year, monthNumber - 1, 1);
            const endDate = new Date(this.props.to.year, monthNumber, 0);
            const from = DateTime.fromJSDate(startDate);
            const to = DateTime.fromJSDate(endDate);
            this.props.onChangeFrom(from);
            this.props.onChangeTo(to);
        }
    };

    handleYearChange = async (date: DateTime) => {
        const endDate = new Date(date.year, date.month, 0);
        const to = DateTime.fromJSDate(endDate);
        this.props.onChangeFrom(date);
        this.props.onChangeTo(to);
    };

    renderMonthButton = (monthNumber: number) => {
        const isActive = this.props.from && this.props.from.month === monthNumber;
        const readableMonth = DateTime.local(1, monthNumber, 1).toFormat('MMMM');
        return (
            <Button
                color="light"
                className="w-100 py-3  mb-1 bg-grey-150"
                onClick={() => this.handleMonthChange(monthNumber)}
                active={isActive}
            >
                {readableMonth}
            </Button>
        );
    };

    render() {
        const { from } = this.props;
        return (
            <>
                <div className="d-flex justify-content-between mb-2">
                    <Button color={'link'} onClick={() => from && this.handleYearChange(from.minus({ years: 1 }))}>
                        <span className="text-gray">
                            <FontAwesomeIcon size={'lg'} icon={faCaretLeft} />
                        </span>
                    </Button>
                    <div className="h5">{from?.toFormat('yyyy')}</div>
                    <Button
                        color={'link'}
                        disabled={from?.year === this.today.year}
                        onClick={() => from && this.handleYearChange(from.plus({ years: 1 }))}
                    >
                        <span className="text-gray">
                            <FontAwesomeIcon size={'lg'} icon={faCaretRight} />
                        </span>
                    </Button>
                </div>
                <div>
                    <Row>
                        {numberRange(1, 12).map((value, index) => {
                            return (
                                <div className="col-3" key={index}>
                                    {this.renderMonthButton(value)}
                                </div>
                            );
                        })}
                    </Row>
                </div>
            </>
        );
    }
}
