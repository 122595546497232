import { LOGIN, SET_ROLES, READY } from '../constants';
import { UserState } from '../types';
import { AppThunkAction } from '../index';
import {
    ApplicationAction,
    resetActionStatusAction,
    setActionRequestingAction,
    setEnvironmentDataAction,
    setEnvironmentsAction,
    setMerchantAction,
    setMerchantsAction,
} from './index';
import * as api from '../../apis';
import { getApiEnvironment, getMerchantId, setAccessToken, setApiEnvironment, setMerchantId } from '../../config';
import { fetchMerchantTreatmentsAction } from './splitio';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { ApplicationRoleModel, MerchantModel } from '../../apis';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface LoginAction {
    type: LOGIN;
    payload: {
        accessToken: string;
        tokenType: string;
        user: UserState;
    };
}

export function loginAction(payload: { user: UserState; accessToken: string; tokenType: string }): LoginAction {
    setAccessToken(payload.accessToken);
    return {
        type: LOGIN,
        payload,
    };
}

export interface SetUserRolesAction {
    type: SET_ROLES;
    payload: {
        user: {
            roles: ApplicationRoleModel[];
        };
    };
}

export const setUserRolesAction = (roles: ApplicationRoleModel[]): SetUserRolesAction => ({
    type: SET_ROLES,
    payload: {
        user: {
            roles,
        },
    },
});

export interface ReadyAction {
    type: READY;
    payload: {
        isReady: boolean;
    };
}

export const setReadyAction = (isReady: boolean): ReadyAction => ({
    type: READY,
    payload: {
        isReady: isReady,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AuthenticationAction = LoginAction | SetUserRolesAction | ReadyAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
export function loginUser(oktaAuth: OktaAuth, authState: AuthState): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(resetActionStatusAction('login'));
        dispatch(setActionRequestingAction('login', true));

        const user = await oktaAuth.getUser();
        dispatch(
            loginAction({
                accessToken: authState.accessToken!.accessToken,
                tokenType: authState.accessToken!.tokenType,
                user: {
                    email: user.email || null,
                    roles: null,
                },
            }),
        );

        // Save the previously cached merchantId before invalidating the cache, just in case.
        const cached_merchant_id = getMerchantId();
        setMerchantId('');

        // Reset api-environment to sandbox for default behavior
        setApiEnvironment('sandbox');

        let merchant_id: string | null = null;
        let merchants: MerchantModel[] | null = null;
        try {
            merchants = await api.getMerchants(false);
            if (!merchants || merchants.length == 0) {
                // no access
                throw new Error('No merchants found');
            }
            dispatch(setMerchantsAction(merchants));
            // if (!cached_merchant_id || cached_merchant_id === '') {
            if (
                !cached_merchant_id ||
                cached_merchant_id === '' ||
                !merchants.find((merchant) => merchant.id == cached_merchant_id)
            ) {
                // merchants array not empty, but did not find merchantId
                // update merchantId to the first element in the merchants array
                setMerchantId(merchants[0].id);
            } else {
                // set merchantId to the previously cached value since it is a valid one
                setMerchantId(cached_merchant_id);
            }
            const merchant = await api.getMerchant(getMerchantId());
            dispatch(setUserRolesAction(merchant.roles));

            const environments = await api.getEnvironments();
            dispatch(setEnvironmentsAction(environments));
            if (!environments.has_live_data || getApiEnvironment() === 'sandbox') {
                dispatch(setEnvironmentDataAction('sandbox'));
            }

            if (!merchant.is_approved_live) {
                dispatch(setEnvironmentDataAction('sandbox'));
            } else {
                dispatch(setEnvironmentDataAction('live'));
            }

            if (merchant) {
                merchant_id = merchant.id;
                dispatch(setMerchantAction(merchant));
                dispatch(fetchMerchantTreatmentsAction(merchant) as unknown as ApplicationAction);
            }
        } catch {
            // No merchants found. Setting default for RestrictedAccess workflow
            if (merchants && merchants.length > 0) {
                // by doing this we allow the SelectMerchantModal to successfully grab a merchant
                setMerchantId(merchants[0].id);
            }
        }

        dispatch(setReadyAction(true));
        dispatch(setActionRequestingAction('login', false));

        // notify segment of logged in user
        window.analytics.identify(user.sub, {
            name: user.sub,
            email: user.email,
            merchant: merchant_id,
            domain: window.location.hostname,
        });
    };
}
