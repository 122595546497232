import * as React from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledDropdown,
} from 'reactstrap';
import { ResponseError } from '../../apis';
import { RolesType, UserRoleTypes } from '../../store/types';
import selectors from '../../store/selectors';

type Props = {
    showModal: boolean;
    isLoading: boolean;
    toggle: () => void;
    error?: ResponseError | null;
    onSubmit: () => void;
    roleType: RolesType;
    onRoleChange: (roles: RolesType) => void;
};

interface State {
    userType: UserRoleTypes;
}

export default class EditUserRolesModal extends React.Component<Props, State> {
    private initState = {
        userType: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = this.initState;
    }

    componentDidMount = async () => {
        this.setRole(this.props.roleType);
    };

    setRole = (roleType: RolesType) => {
        switch (roleType) {
            case 'AllAdmin':
                this.setState({ userType: 'Admin' });
                break;
            case 'AllUsers':
                this.setState({ userType: 'Default' });
                break;
            case 'AllReporting':
                this.setState({ userType: 'Reporting' });
        }
    };

    renderDropdownItem = (roleType: RolesType, hideDivider?: boolean) => {
        return (
            <>
                {roleType !== this.props.roleType && (
                    <>
                        <div>
                            <DropdownItem
                                onClick={() => this.props.onRoleChange(roleType)}
                                disabled={roleType === this.props.roleType}
                            >
                                <div className="text-capitalize font-weight-bold">
                                    {selectors.user.formatRoleType(roleType)}
                                </div>
                                <div className="small text-muted">{selectors.user.toolTipMessage(roleType)}</div>
                            </DropdownItem>
                        </div>
                        {!hideDivider && <DropdownItem divider />}
                    </>
                )}
            </>
        );
    };

    renderRoleSelect = () => {
        const { roleType, isLoading } = this.props;
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    className="text-left w-100 dropdown-input bg-light border-2 border-dark text-dark d-flex justify-content-between align-middle"
                    caret={true}
                    color="outline-secondary"
                    size="md"
                    disabled={isLoading}
                >
                    {selectors.user.formatRoleType(roleType)}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    {this.renderDropdownItem('AllReporting')}
                    {this.renderDropdownItem('AllUsers')}
                    {this.renderDropdownItem('AllAdmin', true)}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    render() {
        const { showModal, isLoading } = this.props;
        const spinner = <Spinner type="grow" color="black" size="sm" width={'100%'} />;

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Edit user role</ModalHeader>
                <ModalBody>
                    <div className="py-5">
                        <div className="font-weight-bold mb-1">Select admin role assignment for this user. *</div>
                        <Row>
                            <Col>{this.renderRoleSelect()}</Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={'primary'} disabled={isLoading} onClick={this.props.onSubmit}>
                        {!isLoading ? 'Confirm' : spinner}
                    </Button>
                    <Button color="outline-secondary" disabled={isLoading} onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
