import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
    DashboardResponse,
    DashboardResponseFailed,
    ResponseError,
    ResponseErrorCode,
    ResponseErrorType,
} from './response';
import { merge } from 'lodash';
import { getAccessToken, getApiEnvironment, getMerchantId } from '../config';
import fileDownload from 'js-file-download';
import contentDisposition from 'content-disposition';

export const segmentListLimit = 20;

export interface RequestConfig extends AxiosRequestConfig {
    suppressMerchantHeader?: boolean;
    /**
     * Give an unique ID to the request enabling the cancelation of old requests
     */
    cancelTokenId?: string;
}

function updateRequestConfig(config: RequestConfig) {
    const accessToken = getAccessToken();

    return merge({}, config, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'SH-API-ENVIRONMENT': getApiEnvironment(),
            'SH-MERCHANT-ID': config.suppressMerchantHeader ? '' : getMerchantId(),
        },
    });
}

const cancelTokenIds: { [key: string]: CancelTokenSource } = {};

async function sendRequest(config: RequestConfig) {
    let cancelTokenSource: CancelTokenSource | undefined;
    if (config.cancelTokenId) {
        const cancelTokenId = config.cancelTokenId;

        if (cancelTokenIds[cancelTokenId]) {
            const token = cancelTokenIds[cancelTokenId];
            token.cancel('Request has been canceled');
        }

        cancelTokenSource = axios.CancelToken.source();
        cancelTokenIds[config.cancelTokenId] = cancelTokenSource;
    }

    try {
        return await axios.request({
            cancelToken: cancelTokenSource?.token,
            ...config,
        });
    } catch (e) {
        // If is cancel, we throw
        if (axios.isCancel(e)) throw e;

        const error = e as AxiosError<DashboardResponseFailed>;

        // If forbidden or unauthorized refresh the page
        // TODO: See how to deal with unauthorized or forbidden calls
        // if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
        //     window.location.reload();
        // }

        const errorData = error && error.response && error.response.data.error;

        if (!errorData) {
            throw new ResponseError(ResponseErrorType.Unknown, ResponseErrorCode.Unknown);
        }

        throw new ResponseError(
            errorData.type as ResponseErrorType,
            errorData.code as ResponseErrorCode,
            errorData.message,
            errorData.property,
        );
    }
}

export async function request<T>(config: RequestConfig): Promise<DashboardResponse<T>> {
    const requestConfig = updateRequestConfig(config);
    const res = await sendRequest(requestConfig);
    return res.data;
}

export async function download(config: RequestConfig) {
    const requestConfig = updateRequestConfig(config);
    try {
        const res = await sendRequest(requestConfig);
        const disposition = contentDisposition.parse(res.headers['content-disposition']);
        fileDownload(res.data, disposition.parameters.filename as string);
    } catch {
        // Catch error
    }
}

export function isValidEmail(input: string) {
    const format =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return format.test(input);
}
