import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchSettlementsAction } from '../../store/actions';
import { ApplicationState } from '../../store';
import { SettlementModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import selectors from '../../store/selectors';
import Pagination from '../../components/Pagination';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    settlements: SettlementModel[];
    settlementsTotal: number;
    settlementsSkipped: number;
    settlementsLimit: number;
    isRequesting: boolean;
    timezone: string | null;
}

interface OwnerProps {
    settlementId?: string;
    isLoading?: boolean;
    onRowClick: (settlement: SettlementModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
}

interface DispatchProps {
    fetchSettlementsAction: typeof fetchSettlementsAction;
}

type Props = StateProps & OwnerProps & DispatchProps;

class SettlementsTable extends React.Component<Props> {
    hasNoRows = () => this.props.settlements.length === 0 && !this.props.isRequesting;
    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no settlements to display'}
            </Alert>
        );
    };

    render() {
        return (
            <>
                <Table hover responsive className={['table-sm', this.hasNoRows() ? 'mb-2' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 140 }}>Date</th>
                            <th>Gross Amount</th>
                            <th>Net Amount</th>
                            <th>Fee</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <TableBody
                        loaderWidth="100%"
                        isLoading={this.props.isRequesting}
                        rows={this.props.settlementsLimit}
                        columns={6}
                    >
                        {this.props.settlements.map((settlement, index) => (
                            <tr onClick={() => this.props.onRowClick(settlement)} key={index}>
                                <td>{selectors.format.formatDate(settlement.date, this.props.timezone)}</td>
                                <td>{selectors.format.formatAmount(settlement.gross_amount)}</td>
                                <td>{selectors.format.formatAmount(settlement.net_amount)}</td>
                                <td>{selectors.format.formatAmount(settlement.fee)}</td>
                                <td>{settlement.description}</td>
                                <td className="text-primary">
                                    <span className="mr-2">View</span>
                                    <span>
                                        <img width={'8px'} src={chevronRight} className="link-icon" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.settlementsTotal}
                    skipped={this.props.settlementsSkipped}
                    limit={this.props.settlementsLimit}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.settlementsLimit + 1, skip)}
                    disabled={this.props.isRequesting}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    settlements: state.settlement.list.items,
    settlementsSkipped: state.settlement.list.skipped,
    settlementsTotal: state.settlement.list.total,
    settlementsLimit: state.settlement.list.limit,
    isRequesting: isActionRequesting(state.global.actions, 'fetch_settlements') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchSettlementsAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsTable);
