import { ADD_PAYLINK, CLEAN_PAYLINK, SET_PAYLINK, SET_PAYLINKS, SWITCH_CONTEXT, UPSERT_PAYLINK } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { PayLinksState } from '../types';
import { PayLinkModel, segmentListLimit } from '../../apis';
import { unionBy } from 'lodash';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PayLinksState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
    selected: null,
};

export const payLinksReducer: Reducer<PayLinksState, ApplicationAction> = (
    state = unloadedState,
    action,
): PayLinksState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_PAYLINKS:
            return {
                ...state,
                list: action.payload.list,
            };
        case ADD_PAYLINK:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: unionBy<PayLinkModel>(state.list.items, [action.payload.payLink], (el) => el.id),
                },
                selected: action.payload.payLink,
            };
        case UPSERT_PAYLINK:
            const payLink = state.list.items.find((el) => el.id);
            const updatedPayLink = { ...payLink, ...action.payload.payLink };
            return {
                ...state,
                list: {
                    ...state.list,
                    items: unionBy<PayLinkModel>([updatedPayLink], state.list.items, (el) => el.id),
                },
                selected: updatedPayLink,
            };
        case SET_PAYLINK:
            return {
                ...state,
                selected: action.payload.payLink,
            };
        case CLEAN_PAYLINK:
            return {
                ...state,
                selected: unloadedState.selected,
            };
        default:
            return state;
    }
};
