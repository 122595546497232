import * as React from 'react';

export interface Props {
    isLoading: boolean;
    width?: string;
    margin?: string;
    children?: React.ReactNode;
}

export default class PlaceholderLoader extends React.Component<Props> {
    render() {
        const { isLoading, children, width, margin } = this.props;

        if (!isLoading) {
            return children || null;
        }

        return (
            <div
                className="placeholder-animated"
                style={{
                    width: width || '100px',
                    margin: margin || '0px',
                }}
            >
                -
            </div>
        );
    }
}
