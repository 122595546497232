import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchBalanceTransfersAction } from '../../store/actions';
import { ApplicationState } from '../../store';
import { BalanceTransferModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Pagination from '../../components/Pagination';
import Alert from 'reactstrap/lib/Alert';
import selectors from '../../store/selectors';
import * as api from '../../apis';
import { Button, Row } from 'reactstrap';
import { history } from '../../index';
import Tooltip from '../Tooltip';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    balanceTransfers: BalanceTransferModel[];
    balanceTransfersTotal: number;
    balanceTransfersSkipped: number;
    balanceTransfersLimit: number;
    isRequesting: boolean;
    timezone: string | null;
}

interface OwnerProps {
    isLoading?: boolean;
    settlementId: string;
    noRowsComponent?: () => React.ReactNode;
}

interface State {
    redirectingTipRoute: boolean;
}

interface DispatchProps {
    fetchBalanceTransfersAction: typeof fetchBalanceTransfersAction;
}

type Props = StateProps & OwnerProps & DispatchProps;

class BalanceTransfersTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectingTipRoute: false,
        };
    }

    componentDidMount(): void {
        if (!this.props.isLoading) {
            this.props.fetchBalanceTransfersAction({ settlementId: this.props.settlementId, limit: 4 });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.props.fetchBalanceTransfersAction({ settlementId: this.props.settlementId, limit: 4 });
        }
    }

    renderNoRows = () => {
        if (this.props.balanceTransfers.length !== 0 || this.props.isRequesting) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no transfers to display'}
            </Alert>
        );
    };

    tipChargeRoute = async (tipId: string) => {
        this.setState({ redirectingTipRoute: true });
        const tip = await api.getTip(tipId);
        if (history && tip) {
            history.push(selectors.global.transactionRoute(tip.charge_id));
        }
    };

    transactionLink = (balanceTransfer: BalanceTransferModel) => {
        switch (balanceTransfer.type) {
            case 'charge_settlement':
            case 'charge_reversal':
                return (
                    <div
                        onClick={() =>
                            history.push(selectors.global.transactionRoute(balanceTransfer.associated_with) || '')
                        }
                    >
                        <div className="hover-pointer font-weight-bold mr-4">
                            <span className="mr-2">View details</span>
                            <img width={'8px'} src={chevronRight} className="link-icon" />
                        </div>
                    </div>
                );
            case 'tip_settlement':
            case 'tip_reversal':
                return (
                    <Button
                        className="p-0 m-0 tile-link"
                        color="link"
                        onClick={() => this.tipChargeRoute(balanceTransfer.associated_with)}
                        disabled={this.state.redirectingTipRoute}
                    >
                        <span className="mr-2">
                            {this.state.redirectingTipRoute ? 'Redirecting..' : 'View Details'}{' '}
                        </span>
                        <span>
                            <img width={'8px'} src={chevronRight} className="link-icon" />
                        </span>
                    </Button>
                );
            case 'adjustment':
            case 'merchant_financing_disbursement':
            case 'merchant_financing_repayment':
            default:
                return '—';
        }
    };

    renderLoadingPlaceholder = () => {
        return (
            <>
                <div className="col-12 col-md-6 mb-3">
                    <div className="balance-card placeholder-animated w-100"></div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <div className="balance-card placeholder-animated w-100"></div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <div className="balance-card placeholder-animated w-100"></div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <div className="balance-card placeholder-animated w-100"></div>
                </div>
            </>
        );
    };

    renderTransfer = (balanceTransfer: BalanceTransferModel) => {
        return (
            <div className="balance-card">
                <div className="border-bottom-gray-sm">
                    <div className="py-2 d-flex justify-content-between">
                        <div className="font-weight-bolder">Type</div>
                        <div>
                            <span className="text-small id-badge font-weight-semi-bold text-dark">
                                {balanceTransfer.type.replace(/_/g, ' ')}
                            </span>
                        </div>
                    </div>
                    <div className="py-2 d-flex justify-content-between">
                        <div className="font-weight-bolder">Total</div>
                        <div>{selectors.format.formatAmount(balanceTransfer.gross_amount)}</div>
                    </div>
                    <div className="py-2 d-flex justify-content-between">
                        <div className="font-weight-bolder">Net</div>
                        <div>{selectors.format.formatAmount(balanceTransfer.net_amount)}</div>
                    </div>
                    <div className="py-2 mb-3 d-flex justify-content-between">
                        <div className="font-weight-bolder">Capture date</div>
                        <div>
                            {(balanceTransfer.captured_at &&
                                selectors.format.formatDate(balanceTransfer.captured_at, this.props.timezone)) ||
                                '—'}
                        </div>
                    </div>
                </div>
                <div className="border-bottom-gray-sm mb-3">
                    <div className="my-3">
                        <Tooltip tooltip="Associated with Id">
                            <span className="py-2 id-badge">{balanceTransfer && balanceTransfer.associated_with}</span>
                        </Tooltip>
                    </div>
                    {(balanceTransfer && balanceTransfer.external_associated_with && (
                        <div className="mb-3">
                            <Tooltip tooltip="External Id associated with">
                                <span className="py-2 id-badge">{balanceTransfer.external_associated_with}</span>
                            </Tooltip>
                        </div>
                    )) ||
                        '—'}
                </div>
                <div className="d-flex">
                    {this.transactionLink(balanceTransfer) !== '—' && (
                        <div className="ml-auto text-primary">{this.transactionLink(balanceTransfer)}</div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="mb-4">
                <div className="mb-3">
                    <h2>Balance Transfers</h2>
                </div>
                <Row>
                    {this.props.isRequesting && this.renderLoadingPlaceholder()}
                    {!this.props.isRequesting &&
                        this.props.balanceTransfers.map((balanceTransfer, index) => (
                            <div className="col-12 col-md-6 mb-3" key={index}>
                                {this.renderTransfer(balanceTransfer)}
                            </div>
                        ))}
                </Row>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.balanceTransfersTotal}
                    skipped={this.props.balanceTransfersSkipped}
                    limit={4}
                    onClick={(skip) =>
                        this.props.fetchBalanceTransfersAction({
                            skip,
                            settlementId: this.props.settlementId,
                            limit: 4,
                        })
                    }
                    disabled={this.props.isRequesting}
                />
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    balanceTransfers: state.balanceTransfer.list.items,
    balanceTransfersSkipped: state.balanceTransfer.list.skipped,
    balanceTransfersTotal: state.balanceTransfer.list.total,
    balanceTransfersLimit: state.balanceTransfer.list.limit,
    isRequesting: isActionRequesting(state.global.actions, 'fetch_balance_transfers') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchBalanceTransfersAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(BalanceTransfersTable);
