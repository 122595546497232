import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    fetchChargesAction,
    fetchCreditsAction,
    fetchCustomerAction,
    fetchPaymentSourcesAction,
} from '../store/actions';
import { ApplicationState } from '../store';
import Layout, { LayoutBreadcrumbItem } from '../components/Layout';
import { RouteComponentProps, withRouter } from 'react-router';
import { isActionRequesting } from '../store/selectors/global';
import { ChargeModel, CreditModel, CustomerModel, CustomizationType, PaymentSourceModel } from '../apis';
import { history } from '../index';
import { ROUTE } from '../config';
import ChargesTable from '../components/charge/ChargesTable';
import CustomerDetails from '../components/customer/CustomerDetails';
import selectors from '../store/selectors';
import PaymentSourceTable from '../components/paymentSource/PaymentSourceTable';
import { uniqBy } from 'lodash';
import CreditsTable from '../components/credits/CreditsTable';

interface StateProps {
    customer: CustomerModel | null;
    isRequestingCharges: boolean;
    isRequestingPaymentSources: boolean;
    customerName: string | null;
    paymentSources: PaymentSourceModel[] | null;
    timezone: string | null;
}

type OwnProps = RouteComponentProps<{
    customerId: string;
}>;

interface DispatchProps {
    fetchCustomerAction: typeof fetchCustomerAction;
    fetchChargesAction: typeof fetchChargesAction;
    fetchPaymentSourcesAction: typeof fetchPaymentSourcesAction;
    fetchCreditsAction: typeof fetchCreditsAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    open: boolean;
}

class Customer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    componentDidMount = async () => {
        await this.props.fetchCustomerAction(this.props.match.params.customerId);

        if (!this.props.customer) {
            this.props.history.push(ROUTE.CUSTOMERS);
        }

        await this.props.fetchPaymentSourcesAction({ customerId: this.props.match.params.customerId });
        await this.props.fetchChargesAction({ customerId: this.props.match.params.customerId });
        await this.props.fetchCreditsAction({ customerId: this.props.match.params.customerId });
    };

    onChargeRowClicked = (data: ChargeModel) => {
        history.push(`${ROUTE.TRANSACTIONS}/${data.id}`);
    };

    onCreditOriginChargeIdClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.TRANSACTIONS}/${credit.originated_from_charge_id}`);
    };

    onCreditRedeemedChargeIdClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.TRANSACTIONS}/${credit.redeemed_with_charge_id}`);
    };

    onCreditPromotionIdClicked = (credit: CreditModel) => {
        history.push(`${ROUTE.PROMOTIONS}/${credit.promotion_id}`);
    };

    fetchCharges = (skip?: number) => {
        const { customer } = this.props;
        this.props.fetchChargesAction({
            skip,
            customerId: customer ? customer.id : undefined,
        });
    };

    fetchCredits = (skip?: number) => {
        const { customer } = this.props;
        this.props.fetchCreditsAction({
            skip,
            customerId: customer ? customer.id : undefined,
        });
    };

    render() {
        const { customer, customerName, paymentSources } = this.props;
        const customerNamePlural = selectors.customizations.pluralize(customerName);

        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.CUSTOMERS,
                title: customerNamePlural || '',
            },
        ];

        if (customer) {
            layoutBreadcrumbItems.push({
                href: selectors.global.customerRoute(customer.id),
                title: selectors.format.formatCustomerName(customer),
            });
        }

        const customerId = (customer && customer.id) || undefined;
        const isLoading = this.props.isRequestingCharges || this.props.isRequestingPaymentSources || !customer;

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div className="mb-5">
                    <CustomerDetails
                        canEditDetails={true}
                        customer={this.props.customer}
                        customCustomerName={customerName}
                        customerDetailsPage={true}
                        timezone={this.props.timezone}
                    />

                    <ChargesTable
                        title="Recent Transactions"
                        onRowClick={this.onChargeRowClicked}
                        onGoTo={(page, skip) => this.fetchCharges(skip)}
                        customerId={customerId}
                        isLoading={isLoading}
                    />

                    <CreditsTable
                        onOriginChargeClick={this.onCreditOriginChargeIdClicked}
                        onRedeemedChargeClick={this.onCreditRedeemedChargeIdClicked}
                        onFlexColumnClick={this.onCreditPromotionIdClicked}
                        onGoTo={(page, skip) => this.fetchCredits(skip)}
                        isLoading={isLoading}
                        showPromoColumn={true}
                    />

                    <PaymentSourceTable
                        customerId={customer && customer.id}
                        paymentSources={paymentSources}
                        isLoading={isLoading}
                    />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => ({
    customer: state.customer.list.items.find((value) => value.id === ownProps.match.params.customerId) || null,
    paymentSources: uniqBy(state.paymentSources.list.items, (el) => el.unique_hash),
    isRequestingCharges: isActionRequesting(state.global.actions, 'fetch_customer'),
    isRequestingPaymentSources: isActionRequesting(state.global.actions, 'fetch_payment_sources'),
    customerName: selectors.customizations.getCustomization(state, CustomizationType.CustomerName),
    timezone: state.merchant.selected?.timezone || null,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchCustomerAction,
            fetchChargesAction,
            fetchPaymentSourcesAction,
            fetchCreditsAction,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customer));
