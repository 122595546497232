import { download, request, segmentListLimit } from './utils';
import { stringify } from 'querystring';

export interface SummaryModel {
    total: string;
    convenience: string;
    gross: string;
    fees: string;
    net: string;
}

export interface TransactionsReportModel {
    date: string;
    charges: SummaryModel;
    refunds: SummaryModel;
    returns_credit: SummaryModel;
    returns_debit: SummaryModel;
    adjustments_credit: SummaryModel;
    adjustments_debit: SummaryModel;
    total: string;
    net: string;
    gross: string;
}

export interface PromotionReportModel {
    name: string;
    start_date: string;
    end_date?: string;
    promo_transactions_earned_count: string;
    promo_transactions_earned_value: string;
    promo_transactions_redeemed_count: string;
    promo_transactions_redeemed_value: string;
    transactions_in_promo_period_count: string;
    transactions_in_promo_period_value: string;
    average_transactions_in_promo_period_count: string;
    average_transaction_in_promo_period_value: string;
    average_transactions_by_day_prior30_count: string;
    average_transaction_by_day_prior30_value: string;
    average_transactions_by_day_prior90_count: string;
    average_transaction_by_day_prior90_value: string;
}

export interface AutomatedMailingReportPosted {
    type: string;
    email: string;
    interval: string;
}

export interface AutomatedMailingReportModel extends AutomatedMailingReportPosted {
    id: string;
}

export async function downloadOutstandingReturnsReport(options: { from: string; to: string; filterType: string }) {
    const query = stringify({
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/returns/download?${query}`,
    });
}

export async function getTransactionsReport(options: { from?: string; to?: string } = {}) {
    const query = stringify({
        ...options,
    });

    const res = await request<TransactionsReportModel>({
        method: 'GET',
        url: `/api/reports/transactions?${query}`,
    });

    return res.result;
}

export async function downloadTransactionsReport(options: { from?: string; to?: string; format: string }) {
    const query = stringify({
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/transactions/download?${query}`,
    });
}

export async function downloadChargesReport(options: { status?: string[]; from?: string; to?: string } = {}) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/charges?${query}`,
    });
}

export async function downloadSettlementsReport(options: { from?: string; to?: string } = {}) {
    const query = stringify({
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/settlements?${query}`,
    });
}

export async function downloadSettlementDetailsReport(options: { from?: string; to?: string } = {}) {
    const query = stringify({
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/settlement-details?${query}`,
    });
}

export async function downloadSettlementDetailsReportById(settlementId: string) {
    await download({
        method: 'GET',
        url: `/api/reports/settlements/${settlementId}`,
    });
}

export async function downloadActivityDetailsReport(options: { from?: string; to?: string } = {}) {
    const query = stringify({
        ...options,
    });

    await download({
        method: 'GET',
        url: `/api/reports/activity-details?${query}`,
    });
}

export async function createAutomatedMailingReport(details: AutomatedMailingReportPosted) {
    const res = await request<AutomatedMailingReportModel>({
        method: 'POST',
        url: `/api/reports/automated-mailing`,
        data: details,
    });

    return res.result;
}

export async function removeAutomatedMailingReport(reportId: string) {
    const res = await request({
        method: 'DELETE',
        url: `/api/reports/automated-mailing/${reportId}/delete`,
    });
    return res.result;
}

export async function fetchAutomatedMailingReports() {
    const res = await request<AutomatedMailingReportModel[]>({
        method: 'GET',
        url: `/api/reports/automated-mailing`,
    });

    return res.result;
}

export async function fetchPromotionReport(promotionId: string) {
    const res = await request<PromotionReportModel>({
        method: 'GET',
        url: `/api/reports/promotions/${promotionId}`,
    });
    return res.result;
}
