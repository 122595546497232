import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import Layout from '../../components/Layout';
import { ROUTE } from '../../config';
import ToggleSplit from '../../components/split/ToggleSplit';
import { SPLIT_FLAG } from '../../store/types';
import { Button, Row } from 'reactstrap';
import TileLink from '../../components/launchpad/TileLink';
import { history } from '../../index';
import AutomatedReportsTable from '../../components/reports/AutomatedReportsTable';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import {
    fetchAutomatedMailingReportsAction,
    removeAutomatedMailingReportAction,
    resetActionStatusAction,
} from '../../store/actions';
import { AutomatedMailingReportModel, ResponseError } from '../../apis';
import { RouteComponentProps } from 'react-router';
const plusIcon = '/plus-svg.svg';

interface StateProps {
    automatedReports: AutomatedMailingReportModel[];
    isLoading: boolean;
    error: ResponseError | null;
}

interface DispatchProps {
    fetchAutomatedMailingReportsAction: typeof fetchAutomatedMailingReportsAction;
    removeAutomatedMailingReportAction: typeof removeAutomatedMailingReportAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

type OwnProps = RouteComponentProps;

type Props = StateProps & OwnProps & DispatchProps;

class Reports extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount = async () => {
        try {
            if (!this.props.isLoading) {
                await this.props.fetchAutomatedMailingReportsAction();
            }
        } catch {
            // throw error
        }
    };

    onSubmit = async (automatedMailingReportId: string) => {
        await this.props.resetActionStatusAction('remove_automated_mailing_report');

        try {
            await this.props.removeAutomatedMailingReportAction(automatedMailingReportId);
        } catch {
            // show error
        }
    };

    formatErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case 'invalid_request_error':
                return `Unable to remove the automated mailing report.`;
            default:
                return 'An error has occured during the action.';
        }
    };

    render() {
        const { automatedReports, isLoading } = this.props;
        return (
            <Layout>
                <div className="mb-4 border-bottom-gray">
                    <div className="mb-4 border-bottom-gray">
                        <h1>Reports</h1>
                    </div>

                    <div>
                        <h2 className="mb-4">Financial report</h2>
                        <Row className="mb-5">
                            <ToggleSplit flag={SPLIT_FLAG.TRANSACTIONS_REPORTS}>
                                <div className="col mb-2">
                                    <TileLink
                                        bgColor={'grey-150'}
                                        size="m"
                                        link={ROUTE.REPORTS_TRANSACTIONS}
                                        title={'Transactions report'}
                                        subTitle={'Get report'}
                                        fullHeight={true}
                                    />
                                </div>
                            </ToggleSplit>
                            <div className="col mb-2">
                                <TileLink
                                    bgColor={'grey-150'}
                                    size="m"
                                    link={selectors.global.outstandingReportsRoute('transaction-date')}
                                    title={'Outstanding Returns Report by Transaction Date'}
                                    subTitle={'Get report'}
                                    fullHeight={true}
                                />
                            </div>
                            <div className="col mb-2">
                                <TileLink
                                    bgColor={'grey-150'}
                                    size="m"
                                    link={selectors.global.outstandingReportsRoute('return-date')}
                                    title={'Outstanding Returns Report by Return Date'}
                                    subTitle={'Get report'}
                                    fullHeight={true}
                                />
                            </div>
                            <div className="col mb-2">
                                <TileLink
                                    bgColor={'grey-150'}
                                    size="m"
                                    link={ROUTE.REPORTS_SETTLEMENTS}
                                    title={'Settlements Report'}
                                    subTitle={'Get report'}
                                    fullHeight={true}
                                />
                            </div>
                            <div className="col mb-2">
                                <TileLink
                                    bgColor={'grey-150'}
                                    size="m"
                                    link={ROUTE.REPORTS_SETTLEMENT_DETAILS}
                                    title={'Settlement Details Report'}
                                    subTitle={'Get report'}
                                    fullHeight={true}
                                />
                            </div>
                            <div className="col mb-2">
                                <TileLink
                                    bgColor={'grey-150'}
                                    size="m"
                                    link={ROUTE.REPORTS_ACTIVITY_DETAILS}
                                    title={'Activity Details Report'}
                                    subTitle={'Get report'}
                                    fullHeight={true}
                                />
                            </div>
                        </Row>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="mb-4 h4">
                            <h2 className="mb-4">Your automated mailing reports</h2>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                size="lg"
                                onClick={() => history.push(ROUTE.REPORTS_AUTOMATED_MAILING)}
                            >
                                <div className="d-flex">
                                    <img src={plusIcon} width="17px" className="mr-3" />
                                    <span>Create automated mailing</span>
                                </div>
                            </Button>
                        </div>
                    </div>

                    <AutomatedReportsTable automatedReports={automatedReports} isLoading={isLoading} />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    automatedReports: state.reports.automatedMailing.arr || [],
    error: selectors.global.getResponseError(state.global.actions, 'remove_automated_mailing_report'),
    isLoading:
        isActionRequesting(state.global.actions, 'remove_automated_mailing_report') ||
        isActionRequesting(state.global.actions, 'fetch_automated_mailing_report'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchAutomatedMailingReportsAction,
            removeAutomatedMailingReportAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
