import * as React from 'react';
import { CHARGE_STATUS } from '../../apis';

export interface Props {
    status: CHARGE_STATUS;
}

export default function ChargeStatusBadge(props: Props) {
    const classes = ['text-capitalize', 'id-badge', 'font-weight-normal', 'py-1', 'text-dark'];

    return <span className={classes.join(' ')}>{props.status.replace(/_/g, ' ')}</span>;
}
