import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ResponseError } from '../../apis';
import { formatErrorMessage } from '../../store/selectors/paylink';

type Props = {
    showModal: boolean;
    toggle: () => void;
    error: ResponseError | null;
    onCreate?: boolean;
};

export default class PayLinkSendErrorModal extends React.Component<Props> {
    render() {
        const { showModal, error, onCreate } = this.props;

        if (!error) {
            return null;
        }

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader className="font-weight-bold" toggle={this.props.toggle}>
                    Unable to send pay link
                </ModalHeader>
                <ModalBody>
                    <div>
                        {formatErrorMessage(error)}
                        {onCreate && ' Please visit the Details page to attempt to send the pay link again.'}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
