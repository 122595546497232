import * as api from '../../apis';
import {
    AutomatedMailingReportModel,
    AutomatedMailingReportPosted,
    PromotionReportModel,
    ResponseError,
    TransactionsReportModel,
} from '../../apis';
import {
    ADD_AUTOMATED_MAILING_REPORT,
    REMOVE_AUTOMATED_MAILING_REPORT,
    SET_AUTOMATED_MAILING_REPORTS,
    SET_PROMOTION_REPORT,
    SET_TRANSACTIONS_REPORT,
} from '../constants';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetTransactionsReportAction {
    type: SET_TRANSACTIONS_REPORT;
    payload: {
        transactions: TransactionsReportModel;
    };
}

export const setTransactionsReportAction = (transactions: TransactionsReportModel): SetTransactionsReportAction => ({
    type: SET_TRANSACTIONS_REPORT,
    payload: {
        transactions,
    },
});

export interface SetAutomatedMailingReportAction {
    type: SET_AUTOMATED_MAILING_REPORTS;
    payload: {
        automatedMailingReports: AutomatedMailingReportModel[];
    };
}

export const setAutomatedMailingReportsAction = (
    automatedMailingReports: AutomatedMailingReportModel[],
): SetAutomatedMailingReportAction => ({
    type: SET_AUTOMATED_MAILING_REPORTS,
    payload: {
        automatedMailingReports,
    },
});

export interface CreateAutomatedMailingReportAction {
    type: ADD_AUTOMATED_MAILING_REPORT;
    payload: {
        automatedMailingReport: AutomatedMailingReportModel;
    };
}

export const createAutomatedMailingReportAction = (
    automatedMailingReport: AutomatedMailingReportModel,
): CreateAutomatedMailingReportAction => ({
    type: ADD_AUTOMATED_MAILING_REPORT,
    payload: {
        automatedMailingReport,
    },
});

export interface RemoveAutomatedMailingReportAction {
    type: REMOVE_AUTOMATED_MAILING_REPORT;
    payload: {
        automatedMailingReportId: string;
    };
}

export const removeAutomatedMailingReportAction = (
    automatedMailingReportId: string,
): RemoveAutomatedMailingReportAction => ({
    type: REMOVE_AUTOMATED_MAILING_REPORT,
    payload: {
        automatedMailingReportId,
    },
});

export interface SetPromotionReportAction {
    type: SET_PROMOTION_REPORT;
    payload: {
        promotionReport: PromotionReportModel;
    };
}

export const setPromotionReportAction = (promotionReport: PromotionReportModel): SetPromotionReportAction => ({
    type: SET_PROMOTION_REPORT,
    payload: {
        promotionReport: promotionReport,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ReportsAction =
    | SetTransactionsReportAction
    | SetAutomatedMailingReportAction
    | CreateAutomatedMailingReportAction
    | RemoveAutomatedMailingReportAction
    | SetPromotionReportAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchTransactionsReportActionOptions {
    from?: string;
    to?: string;
}

export interface FetchOutstandingReturnsReportActionOptions {
    from?: string;
    to?: string;
}

export function fetchTransactionsReportAction(
    options: FetchTransactionsReportActionOptions = {},
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_transactions_report', true));

        try {
            const body = await api.getTransactionsReport(options);
            await dispatch(setTransactionsReportAction(body));
            await dispatch(setActionRequestingAction('fetch_transactions_report', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_transactions_report', e));
            }
            await dispatch(setActionRequestingAction('fetch_transactions_report', false));
            throw e;
        }
    };
}

export function fetchAutomatedMailingReportsAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_automated_mailing_report', true));
        try {
            const body = await api.fetchAutomatedMailingReports();
            await dispatch(setAutomatedMailingReportsAction(body));
            await dispatch(setActionRequestingAction('fetch_automated_mailing_report', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_automated_mailing_report', e));
            }
            await dispatch(setActionRequestingAction('fetch_automated_mailing_report', false));
            throw e;
        }
    };
}

export function addAutomatedMailingReportAction(data: AutomatedMailingReportPosted): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('create_automated_mailing_report', true));

        try {
            const body = await api.createAutomatedMailingReport(data);
            await dispatch(createAutomatedMailingReportAction(body));
            await dispatch(setActionRequestingAction('create_automated_mailing_report', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('create_automated_mailing_report', e));
            }
            await dispatch(setActionRequestingAction('create_automated_mailing_report', false));
            throw e;
        }
    };
}

export function deleteAutomatedMailingReportAction(id: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('remove_automated_mailing_report', true));

        try {
            await api.removeAutomatedMailingReport(id);
            await dispatch(removeAutomatedMailingReportAction(id));
            await dispatch(setActionRequestingAction('remove_automated_mailing_report', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('remove_automated_mailing_report', e));
            }
            await dispatch(setActionRequestingAction('remove_automated_mailing_report', false));
            throw e;
        }
    };
}

export function fetchPromotionReportAction(promotionId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_promotion_report', true));

        try {
            const body = await api.fetchPromotionReport(promotionId);
            await dispatch(setPromotionReportAction(body));
            await dispatch(setActionRequestingAction('fetch_promotion_report', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_promotion_report', e));
            }
            await dispatch(setActionRequestingAction('fetch_promotion_report', false));
            throw e;
        }
    };
}
