import * as React from 'react';
import { Tooltip as BSTooltip } from 'reactstrap';
import { useState } from 'react';
import { uniqueId } from 'lodash';
import { Placement } from 'popper.js';

export type Props = React.PropsWithChildren<{
    className?: string;
    tooltip: string | JSX.Element;
    placement?: Placement;
}>;

export default function Tooltip(props: Props) {
    const { className, children, tooltip, placement = 'top' } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [id] = useState(`tooltip-id-${uniqueId()}`);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <div id={id} className={className}>
                {children}
            </div>
            <BSTooltip placement={placement} isOpen={tooltipOpen} target={id} toggle={toggle}>
                {tooltip}
            </BSTooltip>
        </>
    );
}
