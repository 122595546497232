import { ApplicationState } from '../index';
import { CustomizationType } from '../../apis';

export function getCustomization(state: ApplicationState, type: CustomizationType): string {
    const merchant = state.merchant.selected;
    let result = '';
    switch (type) {
        case CustomizationType.CustomerName:
            result = 'Customer';
            break;
        case CustomizationType.ProviderName:
            result = '';
            break;
    }
    result = merchant?.customization?.items.find((value) => value.type === type)?.data.toLowerCase() || result;
    return result.toLowerCase();
}

export function pluralize(customization: string | null) {
    if (!customization) return null;
    return `${customization}s`;
}
