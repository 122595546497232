import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    fetchDashboardUsersAction,
    resetActionResponseErrorAction,
    resetActionStatusAction,
} from '../../store/actions';
import { ApplicationState } from '../../store';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { ROUTE } from '../../config';
import { DashboardUserModel, DashboardUserPosted, isValidEmail, ResponseError } from '../../apis';
import UsersActions from '../../components/users/UsersActions';
import Alert from 'reactstrap/lib/Alert';
import { UncontrolledAlert } from 'reactstrap';
import selectors from '../../store/selectors';
import { RolesType, UserRoleTypes } from '../../store/types';

interface StateProps {
    dashboardUsers: DashboardUserModel[] | null;
    addUserError: ResponseError | null;
}

interface DispatchProps {
    fetchDashboardUsersAction: typeof fetchDashboardUsersAction;
    resetActionStatusAction: typeof resetActionStatusAction;
    resetActionResponseErrorAction: typeof resetActionResponseErrorAction;
}

type Props = StateProps & DispatchProps;

interface State {
    email: string;
    confirmEmail: string;
    firstName: string;
    lastName: string;
    firstNameError: string;
    lastNameError: string;
    emailConfirmationError: string;
    isEmailConfirmed: boolean;
    emailValidationError: string;
    successMessage: string;
    roles: RolesType;
    userType: UserRoleTypes;
}

class AddUser extends React.Component<Props, State> {
    private initState = {
        email: '',
        confirmEmail: '',
        firstName: '',
        lastName: '',
        firstNameError: '',
        lastNameError: '',
        emailConfirmationError: '',
        isEmailConfirmed: false,
        emailValidationError: '',
        successMessage: '',
        roles: null,
        userType: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = this.initState;
    }

    componentDidMount = async () => {
        try {
            await this.props.fetchDashboardUsersAction();
            await this.props.resetActionStatusAction('add_dashboard_user');
        } catch {}
    };

    onEmailChange = async (email: string) => {
        await this.setState({ email, confirmEmail: '' });
        await this.setState({ emailValidationError: '' });
        await this.setState({ isEmailConfirmed: false });
        await this.setState({ emailConfirmationError: '' });
    };

    onConfirmEmailChange = async (confirmEmail: string) => {
        await this.setState({ confirmEmail });
    };

    onFirstNameChange = async (firstName: string) => {
        await this.setState({ firstName });
    };

    onLastNameChange = async (lastName: string) => {
        await this.setState({ lastName });
    };

    validateEmail = async () => {
        if (!this.state.email) {
            return;
        } else if (!isValidEmail(this.state.email)) {
            await this.setState({ emailValidationError: 'Email address is invalid.' });
        } else {
            await this.setState({ emailValidationError: '' });
        }
    };

    validateEmailsMatch = async () => {
        if (this.state.email !== this.state.confirmEmail) {
            await this.setState({ emailConfirmationError: 'Email addresses do not match.' });
            await this.setState({ isEmailConfirmed: false });
        } else {
            await this.setState({ emailConfirmationError: '' });
            await this.setState({ isEmailConfirmed: true });
        }
    };

    validateFirstName = async () => {
        if (this.state.firstName !== this.state.firstName.trim()) {
            await this.setState({ firstNameError: 'First name cannot have leading or trailing spaces.' });
        } else if (this.state.firstName.length < 1 || this.state.firstName.length > 64) {
            await this.setState({ firstNameError: 'First name must be between 1 and 64 characters.' });
        } else {
            await this.setState({ firstNameError: '' });
        }
    };

    validateLastName = async () => {
        if (this.state.lastName !== this.state.lastName.trim()) {
            await this.setState({ lastNameError: 'Last name cannot have leading or trailing spaces.' });
        } else if (this.state.lastName.length < 1 || this.state.lastName.length > 64) {
            await this.setState({ lastNameError: 'Last name must be between 1 and 64 characters.' });
        } else {
            await this.setState({ lastNameError: '' });
        }
    };

    resetForm = async () => {
        this.setState(this.initState);
    };

    onSubmit = async () => {
        await this.props.resetActionStatusAction('add_dashboard_user');
        const email = this.state.email;
        await this.resetForm();
        await this.props.resetActionResponseErrorAction('add_dashboard_user');
        await this.setState({
            successMessage: `User ${email} has been added successfully.`,
        });
    };

    onRoleChange = async (roleType: RolesType) => {
        await this.setState({ roles: roleType as RolesType }, () => {
            if (this.state.roles) {
                switch (this.state.roles) {
                    case 'AllAdmin':
                        this.setState({ userType: 'Admin' });
                        break;
                    case 'AllUsers':
                        this.setState({ userType: 'Default' });
                        break;
                    case 'AllReporting':
                        this.setState({ userType: 'Reporting' });
                }
            }
        });
    };

    renderDropdownItem = (roleType: RolesType, hideDivider?: boolean) => {
        return (
            <>
                {roleType !== this.state.roles && (
                    <>
                        <div>
                            <DropdownItem
                                onClick={() => this.onRoleChange(roleType)}
                                disabled={roleType === this.state.roles}
                            >
                                <div className="text-capitalize font-weight-bold">
                                    {selectors.user.formatRoleType(roleType)}
                                </div>
                                <div className="small text-muted">{selectors.user.toolTipMessage(roleType)}</div>
                            </DropdownItem>
                        </div>
                        {!hideDivider && <DropdownItem divider />}
                    </>
                )}
            </>
        );
    };

    renderRoleSelect = () => {
        const { roles, userType } = this.state;
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    className="text-left w-100 dropdown-input bg-light border-2 border-dark text-dark d-flex justify-content-between"
                    caret={!userType}
                    color="outline-secondary"
                    size="lg"
                >
                    {roles ? selectors.user.formatRoleType(roles) : 'Select one...'}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    {this.renderDropdownItem('AllReporting')}
                    {this.renderDropdownItem('AllUsers')}
                    {this.renderDropdownItem('AllAdmin', true)}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    render() {
        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.ADMIN,
                title: 'Admin',
            },
            {
                href: ROUTE.ADMIN_ADD_USER,
                title: 'Add User',
            },
        ];

        const {
            email,
            confirmEmail,
            firstName,
            lastName,
            roles,
            firstNameError,
            lastNameError,
            emailConfirmationError,
            isEmailConfirmed,
            emailValidationError,
            successMessage,
            userType,
        } = this.state;
        const { addUserError } = this.props;
        const hasEmailValidationError = emailValidationError ? true : false;
        const hasEmailConfirmationError = emailConfirmationError ? true : false;
        const hasSuccessMsg = successMessage ? true : false;
        const hasFirstNameError = firstNameError ? true : false;
        const hasLastNameError = lastNameError ? true : false;

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div>
                    <div className="mb-4 h3">Add a user</div>
                    <div className="mb-5">
                        <div className="h5 font-weight-bold">User Details</div>
                        {hasEmailValidationError && <Alert color="danger-light">{emailValidationError}</Alert>}
                        {hasEmailConfirmationError && <Alert color="danger-light">{emailConfirmationError}</Alert>}
                        {hasFirstNameError && <Alert color="danger-light">{firstNameError}</Alert>}
                        {hasLastNameError && <Alert color="danger-light">{lastNameError}</Alert>}
                        {addUserError && <Alert color="danger-light">{addUserError.message}</Alert>}
                        {hasSuccessMsg && <UncontrolledAlert color="success-light">{successMessage}</UncontrolledAlert>}
                        <Row className="mb-2">
                            <Col>
                                <div className="font-size-lg font-weight-bold mb-1">Email *</div>
                                <Input
                                    placeholder={'user@stronghold.co'}
                                    className="bg-light border-2 border-dark text-dark"
                                    value={email}
                                    bsSize="lg"
                                    onChange={(e) => this.onEmailChange(e.target.value)}
                                    onBlur={() => this.validateEmail()}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="font-size-lg font-weight-bold mb-1">Email confirm</div>
                                <Input
                                    value={confirmEmail}
                                    bsSize="lg"
                                    className="bg-light border-2 border-dark text-dark"
                                    onChange={(e) => this.onConfirmEmailChange(e.target.value)}
                                    onBlur={() => this.validateEmailsMatch()}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="font-size-lg font-weight-bold mb-1">First name *</div>
                                <Input
                                    placeholder={'John'}
                                    value={firstName}
                                    bsSize="lg"
                                    className="bg-light border-2 border-dark text-dark"
                                    onChange={(e) => this.onFirstNameChange(e.target.value)}
                                    onBlur={() => this.validateFirstName()}
                                />
                            </Col>
                            <Col>
                                <div className="font-size-lg font-weight-bold mb-1">Last name *</div>
                                <Input
                                    placeholder={'Smith'}
                                    value={lastName}
                                    bsSize="lg"
                                    className="bg-light border-2 border-dark text-dark"
                                    onChange={(e) => this.onLastNameChange(e.target.value)}
                                    onBlur={() => this.validateLastName()}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="font-size-lg font-weight-bold mb-1">Choose role *</div>
                                {this.renderRoleSelect()}
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <UsersActions
                            disabled={
                                !lastName ||
                                hasLastNameError ||
                                !firstName ||
                                hasFirstNameError ||
                                !email ||
                                !confirmEmail ||
                                hasEmailValidationError ||
                                !isEmailConfirmed ||
                                hasEmailConfirmationError ||
                                !roles
                            }
                            action="add"
                            dashBoardUser={
                                {
                                    last_name: lastName,
                                    first_name: firstName,
                                    email,
                                    roles,
                                    user_type: userType,
                                } as DashboardUserPosted
                            }
                            onSubmitCallback={this.onSubmit}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    dashboardUsers: state.users.arr || [],
    addUserError: selectors.global.getResponseError(state.global.actions, 'add_dashboard_user'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchDashboardUsersAction,
            resetActionStatusAction,
            resetActionResponseErrorAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
