import { BalanceTransferState } from '../types';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { segmentListLimit } from '../../apis';
import { SET_BALANCE_TRANSFERS, SET_BALANCE_TRANSFER_SUMMARY, SWITCH_CONTEXT } from '../constants';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: BalanceTransferState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
    summary: null,
};

export const balanceTransferReducer: Reducer<BalanceTransferState, ApplicationAction> = (
    state = unloadedState,
    action,
): BalanceTransferState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_BALANCE_TRANSFERS:
            return {
                ...state,
                list: action.payload.list,
            };
        case SET_BALANCE_TRANSFER_SUMMARY:
            return {
                ...state,
                summary: action.payload.data,
            };
        default:
            return state;
    }
};
