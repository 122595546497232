import { ApiEnvironment, ConfigurationModel, EnvironmentsModel, ResponseError } from '../../apis';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type ActionName =
    | 'login'
    | 'fetch_configuration'
    | 'fetch_treatment'
    | 'fetch_settlement'
    | 'fetch_settlements'
    | 'fetch_balance_transfers'
    | 'fetch_balance_transfers_summary'
    | 'fetch_charges'
    | 'fetch_aggregated_charges'
    | 'fetch_charge'
    | 'refund_charge'
    | 'upsert_charge'
    | 'fetch_return'
    | 'fetch_tip'
    | 'fetch_customers'
    | 'fetch_customer'
    | 'create_customer'
    | 'update_customer'
    | 'fetch_payment_source'
    | 'fetch_payment_sources'
    | 'update_payment_source'
    | 'create_automated_mailing_report'
    | 'remove_automated_mailing_report'
    | 'fetch_automated_mailing_report'
    | 'fetch_transactions_report'
    | 'fetch_outstanding_returns_report'
    | 'fetch_dashboard_users'
    | 'fetch_pay_links'
    | 'fetch_pay_link'
    | 'create_pay_link'
    | 'cancel_pay_link'
    | 'send_sms'
    | 'send_email'
    | 'add_dashboard_user'
    | 'remove_dashboard_user'
    | 'update_dashboard_user_roles'
    | 'set_data'
    | 'set_merchant'
    | 'fetch_earned_points_ytd'
    | 'add_developer_user'
    | 'set_developer_sign_up'
    | 'reset_action_response_error'
    | 'deactivate_all_payment_sources'
    | 'fetch_promotion'
    | 'fetch_promotions'
    | 'fetch_credit'
    | 'fetch_credits'
    | 'fetch_promotion_report'
    | 'fetch_processing_fee_amount';

export interface ActionStatus {
    name: ActionName;
    isRequesting: boolean;
    responseError?: ResponseError;
}

export interface GlobalState {
    actions: ActionStatus[];
    configuration: ConfigurationModel;
    environments: EnvironmentsModel;
    environmentData: ApiEnvironment;
    showRequestLiveDataMessage: boolean;
    showSwitchedToSandboxMessage: boolean;
    switchingContext: boolean;
}

// ================================
// Split
export enum SPLIT_FLAG {
    NEW_DASHBOARD = 'New_Dashboard',
    REPORTS_TAB = 'Reports',
    TRANSACTIONS_REPORTS = 'Transaction_Reports',
}

// UserRoles
export enum USER_ROLE {
    STRONGHOLD_DIRECT = 'MERCHANT:STRONGHOLDDIRECT',
    NO_STRONGHOLD_DIRECT = 'MERCHANT:NOSTRONGHOLDDIRECT',
    STRONGHOLD_DIRECT_B2B = 'MERCHANT:STRONGHOLDDIRECTB2B',
    MERCHANT_REPORTING = 'MERCHANT:REPORTING',
    MERCHANT_TRANSACTIONS_EDIT = 'MERCHANT:TRANSACTIONSEDIT',
    MERCHANT_ALLUSERS = 'MERCHANT:ALLUSERS',
    MERCHANT_ALLADMIN = 'MERCHANT:ALLADMIN',
}
