import * as React from 'react';
import QRCodeCanvas from 'qrcode.react';

type Props = {
    url: string | null;
    imgUrl?: string;
    size?: number;
    height?: number;
    width?: number;
};

const defaultImgUrl = 'https://tutorials.strongholdpay.com/images/stronghold-icon.png';

export default class SHQRCode extends React.Component<Props> {
    render() {
        const { url, imgUrl = defaultImgUrl, size = 325, height = 48, width = 48 } = this.props;

        if (!url) {
            return null;
        }

        return (
            <>
                <QRCodeCanvas
                    includeMargin={false}
                    size={size}
                    value={url}
                    level={'M'}
                    imageSettings={{
                        src: imgUrl,
                        height: height,
                        width: width,
                        excavate: true,
                    }}
                />
            </>
        );
    }
}
