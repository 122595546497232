import { Button, Col, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import InputMask from 'react-input-mask';
import Loader from '../Loader';
import * as React from 'react';
import { CreateCustomerRequest, CustomerModel, MerchantModel, ResponseError } from '../../apis';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { createCustomerAction, resetActionStatusAction } from '../../store/actions';

interface StateProps {
    customers: CustomerModel[];
    isLoading: boolean;
    merchant: MerchantModel | null;
    error: ResponseError | null;
    customerError: ResponseError | null;
}

interface DispatchProps {
    createCustomerAction: typeof createCustomerAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

interface OwnerProps {
    onSubmitCallback?: (customer: CustomerModel) => void;
}

type Props = StateProps & DispatchProps & OwnerProps;

interface State {
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    externalId: string;
    dob: string;
}

type Field = 'firstName' | 'lastName' | 'mobile' | 'email' | 'externalId' | 'dob';

class CustomerForm extends React.Component<Props, State> {
    state: State = {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        externalId: '',
        dob: '',
    };

    onInputChange = async (field: string, label: Field) => {
        await this.setState({ [label]: field } as Pick<State, Field>);
    };

    onSubmit = async () => {
        const { merchant } = this.props;
        const { mobile, email, firstName, lastName, dob } = this.state;
        await this.props.resetActionStatusAction('create_customer');

        const formData: CreateCustomerRequest = {
            country: merchant ? merchant.street_location.country : '',
            state: merchant ? merchant.street_location.state : '',
            individual: {
                first_name: firstName,
                last_name: lastName,
                mobile: selectors.format.formatMobile(mobile) || undefined,
                email: email || undefined,
                date_of_birth: dob ? dob : undefined,
            },
        };
        try {
            await this.props.createCustomerAction(formData);
            const customer = this.props.customers.find(
                (customer) =>
                    customer.individual.email === formData.individual.email &&
                    customer.individual.first_name === formData.individual.first_name &&
                    customer.individual.last_name === formData.individual.last_name &&
                    customer.individual.date_of_birth === formData.individual.date_of_birth &&
                    customer.individual.mobile === formData.individual.mobile,
            );
            if (this.props.onSubmitCallback && customer) {
                await this.props.onSubmitCallback(customer);
            }
        } catch {
            // handle silently
        }
    };

    render() {
        const { isLoading, customerError } = this.props;
        const { mobile, email, firstName, lastName, dob } = this.state;
        const missingFields = !firstName || !lastName;

        const noDob = !dob;
        const disabled = noDob || missingFields;

        return (
            <div>
                <div className="h5 mb-4">Add a new customer</div>

                <Row className="mb-2">
                    <Col>
                        <div className="mb-1 font-weight-bold">First name *</div>
                        <Input
                            placeholder={'John'}
                            className="bg-light border-2 border-dark"
                            bsSize="lg"
                            value={firstName.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'firstName')}
                            data-sh="new-customer-first-name-input"
                        />
                    </Col>
                    <Col>
                        <div className="mb-1 font-weight-bold">Last name *</div>
                        <Input
                            placeholder={'Smith'}
                            className="bg-light border-2 border-dark"
                            bsSize="lg"
                            value={lastName.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'lastName')}
                            data-sh="new-customer-last-name-input"
                        />
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col>
                        <div className="mb-1 font-weight-bold">Date of Birth</div>
                        <InputMask
                            value={dob.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'dob')}
                            data-sh="new-customer-dob-input"
                            placeholder="yyyy-mm-dd"
                            className={`form-control form-control-lg bg-light border-2 border-dark${
                                customerError && customerError.property === 'individual.date_of_birth'
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            mask="9999-99-99"
                        />
                        {customerError && customerError.property === 'individual.date_of_birth' && (
                            <>
                                <div className="text-danger my-1">{customerError.message}</div>
                            </>
                        )}
                    </Col>
                    <Col>
                        <div className="mb-1 font-weight-bold">Mobile</div>
                        <InputMask
                            placeholder="(___) ___-____"
                            className={`form-control form-control-lg bg-light border-2 border-dark${
                                customerError && customerError.property === 'individual.mobile' ? 'is-invalid' : ''
                            }`}
                            mask="(999) 999-9999"
                            value={mobile.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'mobile')}
                            data-sh="new-customer-mobile-input"
                        />
                        {customerError && customerError.property === 'individual.mobile' && (
                            <>
                                <div className="text-danger my-1">{customerError.message}</div>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <div className="mb-1 font-weight-bold ">Email</div>
                        <Input
                            placeholder={'john.smith@exmaple.com'}
                            className={`form-control-lg bg-light border-2 border-dark ${
                                customerError && customerError.property === 'individual.email' ? 'is-invalid' : ''
                            }`}
                            value={email.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'email')}
                            data-sh="new-customer-email-input"
                        />
                        {customerError && customerError.property === 'individual.email' && (
                            <>
                                <div className="text-danger my-1">{customerError.message}</div>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="mb-2 d-flex justify-content-start">
                    <Col md={3}>
                        <Button
                            className="w-100 d-flex justify-content-center "
                            color="primary"
                            onClick={this.onSubmit}
                            disabled={disabled}
                            data-sh="create-customer-button"
                        >
                            <div>{isLoading ? <Loader color={'white'} size="sm" /> : `Create new customer`}</div>
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        customers: state.customer.list.items,
        merchant: state.merchant.selected,
        customerError: selectors.global.getResponseError(state.global.actions, 'create_customer'),
        error: selectors.global.getResponseError(state.global.actions, 'create_customer'),
        isLoading: selectors.global.isActionRequesting(state.global.actions, 'create_customer'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createCustomerAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
