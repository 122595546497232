import { request } from './utils';
import { CustomizationModel } from './customization';
import { stringify } from 'querystring';
import { ApplicationRoleModel } from './users';
export interface StreetLocationModel {
    street1: string;
    city: string;
    postcode: string;
    country: string;
    state: string;
}

export interface MerchantModel {
    id: string;
    legal_name: string;
    display_name: string;
    short_name: string;
    created_at: string;
    convenience_fee: string;
    customization: CustomizationModel;
    street_location: StreetLocationModel;
    timezone: string;
    email?: string;
    is_approved_live: boolean;
    image_url: string;
    roles: ApplicationRoleModel[];
    communication_safe_name: string;
}

export async function getMerchants(withAssociations: boolean) {
    const query = stringify({
        withAssociations: withAssociations,
    });
    const res = await request<MerchantModel[]>({
        method: 'GET',
        url: `/api/merchants?${query}`,
        suppressMerchantHeader: true,
    });
    const the_result = res.result;
    return the_result;
}

export async function getMerchant(merchantId: string) {
    const res = await request<MerchantModel>({
        method: 'GET',
        url: `/api/merchants/${merchantId}`,
    });
    let the_result = res.result;
    // on local, the_result is a MerchantModel object, but on non-local it is an Array for some reason
    if (Array.isArray(the_result)) {
        the_result = the_result[0];
    }
    return the_result;
}
