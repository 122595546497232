import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { MerchantModel } from '../../apis';
import { initSplitSdk, selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { getTreatments } from '@splitsoftware/splitio-redux';
import { SPLIT_FLAG } from '../types';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchSplitTreatmentAction(flag: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If treatment already in the list, return directly
        if (selectTreatmentValue(getState().splitio, flag)) {
            return;
        }

        await getTreatments({ splitNames: [flag] });
    };
}

export function fetchMerchantTreatmentsAction(merchant: MerchantModel): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // fetch splits
        const sdkBrowserConfig = {
            core: {
                authorizationKey: getState().global.configuration.split.api_key,
                key: merchant.id,
            },
        };

        dispatch(initSplitSdk({ config: sdkBrowserConfig }) as unknown as ApplicationAction);
        dispatch(getTreatments({ splitNames: Object.values(SPLIT_FLAG) }) as unknown as ApplicationAction);
    };
}
