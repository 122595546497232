import * as React from 'react';
import PlaceholderLoader from './PlaceholderLoader';

export type Props = React.PropsWithChildren<{
    isLoading: boolean;
    rows: number;
    columns: number;
    loaderWidth?: string;
}>;

export default function TableBody(props: Props) {
    if (!props.isLoading) {
        return <tbody>{props.children}</tbody>;
    }

    const rows: JSX.Element[] = [];

    for (let row = 0; row < props.rows; row++) {
        const columns: JSX.Element[] = [];
        for (let col = 0; col < props.columns; col++) {
            columns.push(
                <td key={`table-body-column-${col}`}>
                    <PlaceholderLoader width={props.loaderWidth} isLoading={true} />
                </td>,
            );
        }
        rows.push(<tr key={`table-body-row-${row}`}>{columns}</tr>);
    }

    return <tbody>{rows}</tbody>;
}
