import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { ChargeModel, CreditModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import selectors from '../../store/selectors';
import Pagination from '../../components/Pagination';
import ChargeStatusBadge from './ChargeStatusBadge';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
import Tooltip from '../Tooltip';
import { getChargeStatusDescription } from '../../pages/Transaction';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    charges: ChargeModel[];
    chargesTotal: number;
    chargesSkipped: number;
    chargesLimit: number;
    isLoading: boolean;
    timezone: string | null;
    credits: CreditModel[];
}

interface OwnerProps {
    title?: string;
    customerId?: string;
    isLoading?: boolean;
    onRowClick: (charge: ChargeModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
}

type Props = StateProps & OwnerProps;

class ChargesTable extends React.Component<Props> {
    hasNoRows = () => this.props.charges.length === 0 && !this.props.isLoading;

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no transactions to display'}
            </Alert>
        );
    };

    render() {
        return (
            <div className="mb-4 border-bottom-gray">
                {this.props.title && (
                    <div className="d-flex">
                        <div className="mr-auto">
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className="ml-auto">
                            <Pagination
                                total={this.props.chargesTotal}
                                limit={this.props.chargesLimit}
                                skipped={this.props.chargesSkipped}
                                onClick={(skip) => this.props.onGoTo(skip / this.props.chargesLimit + 1, skip)}
                                disabled={this.props.isLoading}
                            />
                        </div>
                    </div>
                )}
                <Table hover responsive className={['table-lg', this.hasNoRows() ? 'mb-3' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 140 }}>Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>External ID</th>
                            <th>
                                <Tooltip tooltip="Indicates if a Promotional Credit was created from the transaction.">
                                    Promotion
                                </Tooltip>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <TableBody
                        isLoading={this.props.isLoading}
                        rows={this.props.chargesLimit}
                        columns={5}
                        loaderWidth="100%"
                    >
                        {this.props.charges.map((charge, index) => (
                            <tr key={index} onClick={() => this.props.onRowClick(charge)}>
                                <td>
                                    {selectors.format.formatDateTimeMinutesWithYear(
                                        charge.created_at,
                                        this.props.timezone,
                                    )}
                                </td>
                                <td className="font-size-normal">
                                    <Tooltip tooltip={getChargeStatusDescription(charge?.status)}>
                                        <ChargeStatusBadge status={charge.status} />
                                    </Tooltip>
                                </td>
                                <td>{selectors.format.formatAmount(charge.amount)}</td>
                                <td>{charge.external_id}</td>
                                <td>
                                    {this.props.credits.some((c) => c.originated_from_charge_id == charge.id)
                                        ? 'Yes'
                                        : 'No'}
                                </td>
                                <td className="text-primary">
                                    <span className="mr-2">View</span>
                                    <span>
                                        <img width={'8px'} src={chevronRight} className="link-icon" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                {!this.props.title && (
                    <Pagination
                        total={this.props.chargesTotal}
                        limit={this.props.chargesLimit}
                        skipped={this.props.chargesSkipped}
                        onClick={(skip) => this.props.onGoTo(skip / this.props.chargesLimit + 1, skip)}
                        disabled={this.props.isLoading}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    charges: state.charge.list.items,
    chargesSkipped: state.charge.list.skipped,
    chargesTotal: state.charge.list.total,
    chargesLimit: state.charge.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_charges') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
    credits: state.credits.list.items,
});

export default connect(mapStateToProps)(ChargesTable);
