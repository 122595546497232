import { SET_PROMOTION, SET_PROMOTIONS } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { segmentListLimit } from '../../apis';
import { PromotionsState } from '../types/promotions';

const unloadedState: PromotionsState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
    selectedPromotion: null,
};

export const promotionsReducer: Reducer<PromotionsState, ApplicationAction> = (
    state = unloadedState,
    action,
): PromotionsState => {
    switch (action.type) {
        case SET_PROMOTION:
            return {
                ...state,
                selectedPromotion: action.payload.selectedPromotion,
            };
        case SET_PROMOTIONS:
            return {
                ...state,
                list: action.payload.list,
            };
        default:
            return state;
    }
};
