import * as React from 'react';
import Layout from '../../components/Layout';
import { ROUTE } from '../../config';
import { CustomerModel, MerchantModel, PayLinkModel, StrongholdDirectRequestModel } from '../../apis';
import CreateStep1 from './CreateStep1';
import CreateStep2, { LinkType } from './CreateStep2';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { cleanPayLinkAction, createPayLinkAction, resetActionStatusAction } from '../../store/actions';
import CreateStep3 from './CreateStep3';
import CreateStep4 from './CreateStep4';

interface State {
    step: 1 | 2 | 3 | 4;
    strongholdDirectRequest: StrongholdDirectRequestModel | null;
}

interface StateProps {
    payLink: PayLinkModel | null;
    isCreatingPayLink: boolean;
    merchant: MerchantModel | null;
}

interface DispatchProps {
    createPayLinkAction: typeof createPayLinkAction;
    cleanPayLinkAction: typeof cleanPayLinkAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

type Props = StateProps & DispatchProps;

export class CreatePaylink extends React.Component<Props, State> {
    state: State = {
        strongholdDirectRequest: null,
        step: 1,
    };

    resetForm = () => {
        this.setState({
            strongholdDirectRequest: null,
            step: 1,
        });
    };

    setCustomer = (customer: CustomerModel) => {
        this.setState({
            strongholdDirectRequest: {
                ...this.state.strongholdDirectRequest,
                customer: customer,
            },
            step: 2,
        });
    };

    setStep = (stepNumber: 1 | 2 | 3 | 4) => {
        this.setState({ step: stepNumber });
    };

    setDeliveryType = (deliveryType: LinkType) => {
        this.setState({
            strongholdDirectRequest: {
                ...this.state.strongholdDirectRequest,
                successScreenType: deliveryType,
            },
            step: 3,
        });
    };

    setAmount = (amount: number) => {
        this.setState({
            strongholdDirectRequest: {
                ...this.state.strongholdDirectRequest,
                amount,
            },
        });
    };

    setFiles = (fileList: FileList | null) => {
        if (!fileList) return;
        this.setState({
            strongholdDirectRequest: {
                ...this.state.strongholdDirectRequest,
                fileList: fileList,
            },
        });
    };

    setOrderId = (orderId: string) => {
        this.setState({
            strongholdDirectRequest: {
                ...this.state.strongholdDirectRequest,
                externalId: orderId,
            },
        });
    };

    renderSteps = () => {
        const { step } = this.state;
        const baseClasses = 'circle-border';
        return (
            <div className="steps-wrap">
                <div
                    className={`${baseClasses} text-success border-success ${
                        (step === 2 || step === 3 || step === 4) && 'bg-success text-light step-grow'
                    } font-size-lg`}
                >
                    1
                </div>
                <div className={`${step !== 1 ? 'step-dash bg-success' : 'step-dash'}`}></div>
                <div
                    className={`${baseClasses} ${step === 2 && 'text-success border-success'} ${
                        (step === 3 || step === 4) && 'bg-success text-light border-success step-grow'
                    } font-size-lg`}
                >
                    2
                </div>
                <div className={`${step === 3 || step === 4 ? 'step-dash bg-success' : 'step-dash'}`}></div>
                <div
                    className={`${baseClasses} ${
                        step === 4 && 'bg-success text-light border-success step-grow show-scaled'
                    }`}
                >
                    <div className="d-block">
                        <div className={`${step === 4 && 'bg-white'} checkmark-line`}></div>
                        <div className={`${step === 4 && 'bg-white'} checkmark-line top`}></div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { payLink } = this.props;
        const { step, strongholdDirectRequest } = this.state;

        return (
            <Layout
                breadcrumbItems={[
                    {
                        href: ROUTE.PAYLINKS,
                        title: 'Stronghold Direct',
                    },
                    {
                        href: ROUTE.PAYLINK_CREATE,
                        title: 'Create a paylink',
                    },
                ]}
            >
                <div>
                    <div className="row border-bottom-gray-sm mb-3 d-flex justify-content-between">
                        <div className="col-12 col-md-9">
                            <div className="d-flex mb-2">
                                <h1 className="mb-0">
                                    {step === 1 && 'Create a paylink'}
                                    {step === 2 && 'Paylink customer'}
                                    {step === 3 && 'Confirmation of paylink'}
                                    {step === 4 && 'Paylink created'}
                                </h1>
                            </div>
                            <div className="font-weight-semi-bold mb-4">
                                {step === 1 && 'Choose an existing paylink customer or add a new customer.'}
                                {step === 2 &&
                                    "Fill out the below details, then choose how you'd like to send your paylink."}
                                {step === 3 && 'Review the details of your paylink and confirm.'}
                                {step === 4 && 'The paylink was created successfully.'}
                            </div>
                        </div>
                        <div className="col-12 col-md-3 d-flex flex-column justify-content-center">
                            {this.renderSteps()}
                        </div>
                    </div>

                    <div>
                        {step === 1 && <CreateStep1 setCustomer={this.setCustomer} />}
                        {step === 2 && strongholdDirectRequest && strongholdDirectRequest.customer && (
                            <CreateStep2
                                setDeliveryType={this.setDeliveryType}
                                customer={strongholdDirectRequest.customer}
                                setStep={this.setStep}
                                setAmount={this.setAmount}
                                setFiles={this.setFiles}
                                setOrderId={this.setOrderId}
                                strongholdDirectRequest={strongholdDirectRequest}
                                setCustomer={this.setCustomer}
                            />
                        )}
                        {step === 3 && strongholdDirectRequest && strongholdDirectRequest.customer && (
                            <CreateStep3
                                strongholdDirectRequestModel={strongholdDirectRequest}
                                setStep={this.setStep}
                            />
                        )}
                        {step === 4 &&
                            payLink &&
                            strongholdDirectRequest &&
                            strongholdDirectRequest.successScreenType && (
                                <CreateStep4 url={payLink.url} payLinkId={payLink.id} resetForm={this.resetForm} />
                            )}
                    </div>
                </div>
            </Layout>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        payLink: state.payLinks.selected,
        merchant: state.merchant.selected,
        isCreatingPayLink: selectors.global.isActionRequesting(state.global.actions, 'create_pay_link'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createPayLinkAction,
            cleanPayLinkAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaylink);
