import { SET_PROCESSING_FEE_AMOUNT } from '../constants';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';
import * as api from '../../apis';

export interface SetProcessingFeeAmount {
    type: SET_PROCESSING_FEE_AMOUNT;
    payload: {
        processing_fee_amount: number;
    };
}

export const setProcessingFeeAmount = (processing_fee_amount: number): SetProcessingFeeAmount => ({
    type: SET_PROCESSING_FEE_AMOUNT,
    payload: {
        processing_fee_amount,
    },
});

export function fetchProcessingFeeAmountAction(chargeAmount: number): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_processing_fee_amount',
            call: () => api.calculateProcessingFee(chargeAmount),
            dispatch,
            onSuccess: (body) => dispatch(setProcessingFeeAmount(body.payment_processing_fee)),
        });
    };
}

export type ProcessingFeeAction = SetProcessingFeeAmount;
