import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from 'reactstrap';
import { CreateCustomerRequest, CustomerModel, ResponseError } from '../../apis';
import { patchCustomersAction, resetActionStatusAction } from '../../store/actions';
import selectors from '../../store/selectors';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import InputMask from 'react-input-mask';
import Input from 'reactstrap/lib/Input';
import Loader from '../Loader';

interface OwnProps {
    customer: CustomerModel | null;
    showModal: boolean;
    toggle: () => void;
    isLoading: boolean;
    showMobileOnly?: boolean;
    showEmailOnly?: boolean;
    callback?: () => void;
    setCustomer?: (customer: CustomerModel) => void;
}

interface DispatchProps {
    patchCustomersAction: typeof patchCustomersAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

interface StateProps {
    isLoading: boolean;
    errorMessage: ResponseError | null;
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
    first_name: string;
    last_name: string;
    date_of_birth?: string;
    email: string;
    mobile: string;
}

type Field = 'first_name' | 'last_name' | 'email' | 'mobile' | 'date_of_birth';

class CustomersProfileEditModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            date_of_birth: '',
        };
    }

    componentDidMount = async () => {
        const { customer } = this.props;
        await this.props.resetActionStatusAction('update_customer');
        if (customer) {
            this.setState({
                first_name: customer.individual.first_name,
                last_name: customer.individual.last_name,
            });

            if (customer.individual.date_of_birth) {
                this.setState({ date_of_birth: customer.individual.date_of_birth });
            }

            if (customer.individual.email) {
                this.setState({ email: customer.individual.email });
            }

            if (customer.individual.mobile) {
                this.setState({ mobile: selectors.format.formatMobilePhoneNumberReadable(customer.individual.mobile) });
            }
        }
    };

    handleChange = (value: string, label: Field) => {
        this.setState({ [label]: value } as Pick<State, Field>);
    };

    onSubmit = async () => {
        const { mobile, first_name, last_name, email, date_of_birth } = this.state;
        const { customer } = this.props;
        await this.props.resetActionStatusAction('update_customer');
        if (customer) {
            const formData: CreateCustomerRequest = {
                state: customer.state,
                country: customer.country,
                external_id: customer.external_id,
                individual: {
                    first_name,
                    last_name,
                },
            };

            if (date_of_birth) {
                formData.individual.date_of_birth = date_of_birth;
            }

            if (email) {
                formData.individual.email = email;
            }

            if (mobile) {
                formData.individual.mobile = selectors.format.formatMobile(mobile);
            }

            try {
                await this.props.patchCustomersAction(customer.id, formData);
                if (!this.props.errorMessage) {
                    this.props.toggle();
                    if (this.props.callback) {
                        this.props.callback();
                    }
                    if (this.props.setCustomer) {
                        const updatedCustomer = {
                            ...customer,
                        };
                        if (email) {
                            updatedCustomer.individual.email = email;
                        }

                        if (mobile) {
                            updatedCustomer.individual.mobile = selectors.format.formatMobile(mobile);
                        }
                        this.props.setCustomer(updatedCustomer);
                    }
                }
            } catch {
                // handle silently
            }
        }
    };

    render() {
        const { showModal, isLoading, errorMessage, customer, showMobileOnly, showEmailOnly } = this.props;
        const { email, mobile, last_name, first_name, date_of_birth } = this.state;
        const disabled = (!mobile && !email) || !first_name || !last_name;
        const editForm = (
            <Row className="d-flex w-100 justify-content-end">
                <Col md={6}>
                    <Button
                        onClick={() => this.onSubmit()}
                        color="primary"
                        className="w-100"
                        data-sh="customer-profile-edit-confirm"
                        disabled={disabled}
                    >
                        <div>{isLoading ? <Loader color={'white'} size="sm" /> : `Update`}</div>
                    </Button>
                </Col>
                <Col md={6}>
                    <Button
                        color="outline-secondary"
                        className="w-100"
                        onClick={this.props.toggle}
                        data-sh="customer-profile-edit-cancel"
                    >
                        <div>{`Cancel`}</div>
                    </Button>
                </Col>
            </Row>
        );
        let headerTitle = 'Edit customer details';
        if (this.props.showEmailOnly) {
            headerTitle = 'Add customer email';
        }
        if (this.props.showMobileOnly) {
            headerTitle = 'Add mobile email';
        }

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>{headerTitle}</ModalHeader>
                <ModalBody className="d-flex">
                    <div className="container">
                        {!showMobileOnly && !showEmailOnly && (
                            <>
                                <Row className="mb-2">
                                    <Col sm={12} md={3}>
                                        <div className="d-flex align-items-center h-100">
                                            <div>First Name</div>
                                            <div className="text-danger"> *</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Input
                                            className={
                                                errorMessage && errorMessage.property === 'individual.first_name'
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            value={first_name}
                                            placeholder="First Name"
                                            type="text"
                                            onChange={(e) => this.handleChange(e.target.value, 'first_name')}
                                            data-sh="new-customer-first-name-input"
                                        />
                                        {errorMessage && errorMessage.property === 'individual.first_name' && (
                                            <>
                                                <div className="text-danger my-1">{errorMessage.message}</div>
                                            </>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col sm={12} md={3}>
                                        <div className="d-flex align-items-center h-100">
                                            <div>Last Name</div>
                                            <div className="text-danger"> *</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Input
                                            className={
                                                errorMessage && errorMessage.property === 'individual.last_name'
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            value={last_name}
                                            placeholder="Last Name"
                                            onChange={(e) => this.handleChange(e.target.value, 'last_name')}
                                            data-sh="new-customer-last-name-input"
                                        />
                                        {errorMessage && errorMessage.property === 'individual.last_name' && (
                                            <>
                                                <div className="text-danger my-1">{errorMessage.message}</div>
                                            </>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col sm={12} md={3}>
                                        <div className="d-flex align-items-center h-100">
                                            <div>DOB</div>{' '}
                                        </div>
                                    </Col>
                                    <Col>
                                        <InputMask
                                            value={date_of_birth}
                                            onChange={(e) => this.handleChange(e.target.value, 'date_of_birth')}
                                            data-sh="new-customer-dob-input"
                                            placeholder="yyyy-mm-dd"
                                            className={`form-control ${
                                                errorMessage && errorMessage.property === 'individual.date_of_birth'
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            mask="9999-99-99"
                                        />
                                        {errorMessage && errorMessage.property === 'individual.date_of_birth' && (
                                            <>
                                                <div className="text-danger my-1">{errorMessage.message}</div>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {customer && !showMobileOnly && (
                            <Row className="mb-2">
                                <Col md={3}>
                                    <div className="d-flex align-items-center h-100">
                                        <div>Email</div>
                                    </div>
                                </Col>
                                <Col>
                                    <Input
                                        className={
                                            errorMessage && errorMessage.property === 'individual.email'
                                                ? 'is-invalid'
                                                : ''
                                        }
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => this.handleChange(e.target.value, 'email')}
                                        data-sh="new-customer-email-input"
                                    />
                                    {errorMessage && errorMessage.property === 'individual.email' && (
                                        <>
                                            <div className="text-danger my-1">{errorMessage.message}</div>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        )}
                        {customer && !showEmailOnly && (
                            <Row className="mb-2">
                                <Col md={3}>
                                    <div className="d-flex align-items-center h-100">
                                        <div>Mobile</div>
                                    </div>
                                </Col>
                                <Col>
                                    <InputMask
                                        placeholder={customer && customer.individual.mobile}
                                        className={`form-control ${
                                            errorMessage && errorMessage.property === 'individual.mobile'
                                                ? 'is-invalid'
                                                : ''
                                        }`}
                                        mask="(999) 999-9999"
                                        value={mobile}
                                        onChange={(e) => this.handleChange(e.target.value, 'mobile')}
                                        data-sh="new-customer-mobile-input"
                                    />
                                    {errorMessage && errorMessage.property === 'individual.mobile' && (
                                        <>
                                            <div className="text-danger my-1">{errorMessage.message}</div>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>{editForm}</ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isLoading: selectors.global.isActionRequesting(state.global.actions, 'update_customer'),
    errorMessage: selectors.global.getResponseError(state.global.actions, 'update_customer'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            patchCustomersAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CustomersProfileEditModal);
