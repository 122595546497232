import * as React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { loginUser } from '../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { OktaStateProps, ROUTE } from '../config';
import { SPLIT_FLAG, USER_ROLE } from '../store/types';
import selectors from '../store/selectors';
import ToggleSplit from './split/ToggleSplit';
import { MerchantModel } from '../apis';
import { withOktaAuth } from '@okta/okta-react';
import RestrictedAccess from './RestrictedAccess';
import ToggleRole from './roles/ToggleRole';
import AppLoader from './AppLoader';
import HomeWrapped from '../pages/Home';

interface StateProps {
    isInitialized: boolean;
    isAuthenticating: boolean;
    hasMerchants: boolean;
    merchant: MerchantModel | null;
    isReady: boolean;
}

interface DispatchProps {
    loginUser: typeof loginUser;
}

type OwnProps = RouteProps & {
    loginIfNotAuthenticated?: boolean;
    featureFlag?: SPLIT_FLAG;
    userRole?: USER_ROLE;
};

type Props = StateProps & DispatchProps & OwnProps & OktaStateProps;

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    hasMerchants: state.merchant.arr && state.merchant.arr.length > 0,
    merchant: state.merchant.selected,
    isAuthenticating: selectors.global.isActionRequesting(state.global.actions, 'login'),
    isInitialized: state.authentication.isInitialized,
    isReady: state.authentication.isReady,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            loginUser,
        },
        dispatch,
    );

const AuthorizeRouteWrapped = withOktaAuth(
    class AuthorizeRoute extends React.Component<Props> {
        static defaultProps: OwnProps = {
            loginIfNotAuthenticated: true,
        };

        signIn = async () => {
            await this.props.oktaAuth.signInWithRedirect();
        };

        render() {
            const {
                loginIfNotAuthenticated,
                featureFlag,
                isAuthenticating,
                // hasMerchants,
                merchant,
                userRole,
                isInitialized,
                isReady,
            } = this.props;

            const isAuthenticated = this.props.authState?.isAuthenticated ?? false;

            if (!isAuthenticated && !isAuthenticating) {
                return <HomeWrapped />;
            }

            if (isAuthenticating || (isAuthenticated && !isInitialized)) {
                return <AppLoader />;
            }

            if (!merchant && isAuthenticated && !loginIfNotAuthenticated) {
                return <RestrictedAccess />;
            }

            const redirectUrl = ROUTE.HOME;
            if (isReady && !isAuthenticated && loginIfNotAuthenticated) {
                return <Redirect to={redirectUrl} push />;
            }

            if (isReady) {
                if (featureFlag) {
                    return (
                        <ToggleSplit flag={featureFlag} unactiveComponent={<Redirect to={ROUTE.HOME} />}>
                            <Route {...this.props} />
                        </ToggleSplit>
                    );
                }

                if (userRole) {
                    return (
                        <ToggleRole role={userRole} unactiveComponent={<Redirect to={ROUTE.HOME} />}>
                            <Route {...this.props} />
                        </ToggleRole>
                    );
                }
            }

            return <Route {...this.props} />;
        }
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeRouteWrapped);
