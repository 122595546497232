import { stringify } from 'querystring';
import { request, segmentListLimit } from './utils';
import { ListSegment } from './response';

export interface BalanceTransferModel {
    id: string;
    type:
        | 'charge_settlement'
        | 'charge_reversal'
        | 'adjustment'
        | 'tip_settlement'
        | 'tip_reversal'
        | 'charge_collection_settlement'
        | 'tip_collection_settlement'
        | 'merchant_financing_disbursement'
        | 'merchant_financing_repayment'
        | 'promotional_credit_deduction'
        | 'promotional_credit_stronghold_contribution'
        | 'platform_fee';
    gross_amount: number;
    net_amount: number;
    fee: number;
    associated_with: string;
    external_associated_with?: string;
    captured_at?: string;
}

export interface GrossNetFeeAmount {
    gross: number;
    net: number;
    fee: number;
}

export interface BalanceTransferSummary {
    total_amount: GrossNetFeeAmount;
    charge_settlement_amount: GrossNetFeeAmount;
    charge_reversal_amount: GrossNetFeeAmount;
    adjustment_amount: GrossNetFeeAmount;
    tip_settlement_amount: GrossNetFeeAmount;
    tip_reversal_amount: GrossNetFeeAmount;
    charge_collection_settlement_amount: GrossNetFeeAmount;
    tip_collection_settlement_amount: GrossNetFeeAmount;
    promotional_credit_settlement_amount: GrossNetFeeAmount;
    promotional_credit_reversal_amount: GrossNetFeeAmount;
}

export async function listBalanceTransfers(options: { skip?: number; limit?: number; settlementId: string }) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<BalanceTransferModel>>({
        method: 'GET',
        url: `/api/balance-transfers?${query}`,
    });
    return res.result;
}

export async function getBalanceTransferSummary(options: { settlementId: string }) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<BalanceTransferSummary>({
        method: 'GET',
        url: `/api/balance-transfers/summary?${query}`,
    });
    return res.result;
}
