import { request } from './utils';

export interface DashboardUser {
    first_name: string;
    last_name: string;
    email: string;
    title: string;
}

export interface DashboardUserPosted extends DashboardUser {
    roles: string;
    user_type: string;
}

export interface DashboardUserUpdated extends DashboardUser {
    roles: string;
    owner_id: string;
}

export interface DashboardUserModel extends DashboardUser {
    mobile: string;
    id: string;
    user_type: string;
}

export interface DashboardUserWithId extends DashboardUser {
    owner_id: string;
}

export interface ApplicationRoleModel {
    scope: string;
    user_id: string;
    name: string;
    normalized_name: string;
    type: string;
}

export async function getDashboardUsers() {
    const res = await request<DashboardUserModel[]>({
        method: 'GET',
        url: `/api/users`,
    });
    return res.result;
}

export async function getRoles(userId: string) {
    const res = await request<ApplicationRoleModel[]>({
        method: 'GET',
        url: `/api/users/${userId}/roles`,
    });
    return res.result;
}
export async function addDashboardUser(user: DashboardUser) {
    const res = await request<DashboardUserModel>({
        method: 'POST',
        url: `/api/users`,
        data: user,
    });
    return res.result;
}

export async function removeDashboardRoles(user: DashboardUser) {
    const res = await request<DashboardUserModel>({
        method: 'DELETE',
        url: `/api/users/roles`,
        data: user,
    });
    return res.result;
}

export async function updateDashboardUserRole(user: DashboardUserUpdated) {
    const res = await request<DashboardUserModel>({
        method: 'PUT',
        url: `/api/users/roles`,
        data: user,
    });
    return res.result;
}
