import * as React from 'react';
import NavMenu from './NavMenu';
import Breadcrumb from 'reactstrap/lib/Breadcrumb';
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem';
import { Link } from 'react-router-dom';
import SandboxBanner from './SandboxBanner';

export interface LayoutBreadcrumbItem {
    href: string;
    title: string | JSX.Element;
    isId?: boolean;
}

interface Props {
    navbar?: boolean;
    className?: string;
    children: JSX.Element | JSX.Element[];
    breadcrumbItems?: LayoutBreadcrumbItem[];
    isId?: boolean;
    hideSandboxBanner?: boolean;
    fromRestricted?: boolean;
}

export function LayoutBreadcrumbs(props: { items?: LayoutBreadcrumbItem[] }) {
    const { items = [] } = props;

    if (items.length === 0) return null;

    const isLastItem = (index: number) => index + 1 === items.length;

    return (
        <div className="container bg-white border-bottom-gray">
            <Breadcrumb listClassName="mb-0">
                <div className="d-flex ml-auto">
                    {items.map((value, key) => (
                        <BreadcrumbItem
                            key={key}
                            active={isLastItem(key)}
                            className={value.isId ? '' : 'text-capitalize'}
                        >
                            {isLastItem(key) ? (
                                value.title
                            ) : (
                                <Link to={value.href} className="text-secondary font-weight-bold">
                                    {value.title}
                                </Link>
                            )}
                        </BreadcrumbItem>
                    ))}
                </div>
            </Breadcrumb>
        </div>
    );
}

export default function Layout(props: Props) {
    const { hideSandboxBanner, children, navbar = true, className = '', breadcrumbItems = [], fromRestricted } = props;
    return (
        <>
            {navbar && <NavMenu fromRestricted={fromRestricted} />}
            {!hideSandboxBanner && <SandboxBanner />}
            <LayoutBreadcrumbs items={breadcrumbItems} />
            <div className={['mt-4', className || 'container mx-auto'].join(' ')}>{children}</div>
        </>
    );
}
