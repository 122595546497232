import { SettlementState } from '../types';
import { ADD_SETTLEMENT, SET_SETTLEMENTS, SWITCH_CONTEXT } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { segmentListLimit, SettlementModel } from '../../apis';
import { unionBy } from 'lodash';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SettlementState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
};

export const settlementReducer: Reducer<SettlementState, ApplicationAction> = (
    state = unloadedState,
    action,
): SettlementState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_SETTLEMENTS:
            return {
                ...state,
                list: action.payload.list,
            };
        case ADD_SETTLEMENT:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: unionBy<SettlementModel>(state.list.items, [action.payload.settlement], (el) => el.id),
                },
            };
        default:
            return state;
    }
};
