import { UsersState } from '../types';
import { ADD_USER, SET_USERS, SWITCH_CONTEXT, UPDATE_USER_ROLES, REMOVE_USER } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { unionBy } from 'lodash';
import { DashboardUserModel } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UsersState = {
    arr: [],
};

export const usersReducer: Reducer<UsersState, ApplicationAction> = (state = unloadedState, action): UsersState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_USERS:
            return {
                ...state,
                arr: action.payload.arr,
            };
        case ADD_USER:
            return {
                ...state,
                arr: unionBy<DashboardUserModel>(state.arr, [action.payload.user], (el) => el.id),
            };
        case REMOVE_USER:
            return {
                ...state,
                arr: state.arr.filter((item) => item.email != action.payload.email),
            };
        case UPDATE_USER_ROLES:
            return {
                ...state,
                arr: state.arr.map((el) => {
                    if (el.id == action.payload.user.id) {
                        return action.payload.user;
                    }
                    return el;
                }),
            };
        default:
            return state;
    }
};
