import * as api from '../../apis';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { BalanceTransferModel, BalanceTransferSummary } from '../../apis';
import { ResponseError } from '../../apis';
import { ListSegment } from '../../apis';
import { SET_BALANCE_TRANSFERS, SET_BALANCE_TRANSFER_SUMMARY } from '../constants';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetBalanceTransfersAction {
    type: SET_BALANCE_TRANSFERS;
    payload: {
        list: ListSegment<BalanceTransferModel>;
    };
}

export const setBalanceTransfersAction = (list: ListSegment<BalanceTransferModel>): SetBalanceTransfersAction => ({
    type: SET_BALANCE_TRANSFERS,
    payload: {
        list,
    },
});

export interface SetBalanceTransferSummaryAction {
    type: SET_BALANCE_TRANSFER_SUMMARY;
    payload: {
        data: BalanceTransferSummary;
    };
}

export const SetBalanceTransferSummaryAction = (data: BalanceTransferSummary): SetBalanceTransferSummaryAction => ({
    type: SET_BALANCE_TRANSFER_SUMMARY,
    payload: {
        data,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type BalanceTransferAction = SetBalanceTransfersAction | SetBalanceTransferSummaryAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchBalanceTransfersAction(options: {
    settlementId: string;
    skip?: number;
    limit?: number;
}): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_balance_transfers', true));
        try {
            const body = await api.listBalanceTransfers(options);
            await dispatch(setBalanceTransfersAction(body));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_balance_transfers', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('fetch_balance_transfers', false));
        }
    };
}

export function fetchBalanceTransferSummaryAction(options: {
    settlementId: string;
}): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_balance_transfers_summary', true));
        try {
            const body = await api.getBalanceTransferSummary(options);
            await dispatch(SetBalanceTransferSummaryAction(body));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_balance_transfers_summary', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('fetch_balance_transfers_summary', false));
        }
    };
}
