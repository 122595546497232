import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MerchantModel } from '../../apis';
import * as api from '../../apis';
import Loader from '../Loader';

type Props = {
    showModal: boolean;
    toggle: () => void;
    onSubmit: (merchant: MerchantModel) => void;
    merchants: MerchantModel[];
    selectedMerchant: MerchantModel;
};

type State = {
    selectedMerchant: MerchantModel;
    isLoading: boolean;
};

export default class SelectMerchantModal extends React.Component<Props, State> {
    state: State = {
        selectedMerchant: this.props.selectedMerchant,
        isLoading: false,
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.selectedMerchant !== prevProps.selectedMerchant) {
            this.setState({ selectedMerchant: this.props.selectedMerchant });
        }
    }

    onSelectMerchant = async (merchant: MerchantModel) => {
        this.setState({ isLoading: true });
        const selectedMerchant = await api.getMerchant(merchant.id);
        this.setState({ selectedMerchant: selectedMerchant });
        this.setState({ isLoading: false });
    };

    render() {
        const { showModal, merchants } = this.props;
        const { selectedMerchant, isLoading } = this.state;

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader className="text-capitalize card-header" toggle={this.props.toggle}>
                    Select Merchant
                </ModalHeader>
                <ModalBody className="overflow-auto" style={{ minHeight: '30vh', maxHeight: '80vh' }}>
                    <div className="list-group">
                        {merchants.map((el) => {
                            const style = ['my-1 btn', selectedMerchant?.id === el.id && 'active'].join(' ');
                            return (
                                <li
                                    className={`${style} list-group-item list-group-item-action`}
                                    key={el.id}
                                    onClick={() => this.onSelectMerchant(el)}
                                >
                                    {el.display_name}
                                </li>
                            );
                        })}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        block={true}
                        color="primary"
                        disabled={isLoading}
                        onClick={() => this.props.onSubmit(selectedMerchant)}
                    >
                        {isLoading ? <Loader color={'blue'} size="sm" /> : `Switch Merchant`}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
