import { request, segmentListLimit } from './utils';
import { stringify } from 'querystring';
import { ListSegment } from './response';
import { uniqueId } from 'lodash';
import { CustomerModel, IndividualModel } from './customers';
import { MerchantModel } from './merchant';
import { LinkType } from '../pages/Paylink/CreateStep2';

export enum PAY_LINK_STATUS {
    CREATED = 'created',
    USED = 'used',
    CANCELED = 'canceled',
}

export interface PayLinkModel {
    id: string;
    created_at: string;
    expires_at: string;
    has_expired: boolean;
    charge_id: string;
    external_charge_id: string;
    merchant_id: string;
    customer_id: string;
    status: PAY_LINK_STATUS;
    url: string;
    environment: string;
    order: PayLinkOrderModel;
}

export interface PayLinkOrderModel {
    total_amount: number;
    tax_amount?: number;
    sub_amount?: number;
    convenience_fee?: number;
    tip_amount?: number;
    documents?: PayLinkDocumentModel[];
}

export interface PayLinkDocumentModel {
    url: string;
    file_name: string;
    file_size: number;
}

export interface PayLinkTipModel {
    beneficiary_name: string;
}

export interface StrongholdDirectRequestModel {
    customer?: CustomerModel;
    successScreenType?: LinkType;
    fileList?: FileList;
    externalId?: string;
    amount?: number;
}

export interface CreatePayLinkRequest {
    type: string;
    customer_id: string;
    merchant: Partial<MerchantModel>;
    customer: Partial<IndividualModel>;
    tip: PayLinkTipModel;
    order: PayLinkOrderModel;
    stand_alone: boolean;
    charge?: {
        external_id?: string;
    };
}

export interface CreatePayLinkEmailModel {
    pay_link_id: string;
    pay_link_url: string;
    customer: Partial<IndividualModel>;
    merchant_name?: string;
}

const listPayLinksCancelTokenId = uniqueId();
export async function listPayLinks(
    options: {
        skip?: number;
        limit?: number;
        search?: string;
        status?: string[];
        from?: string;
        to?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<PayLinkModel>>({
        method: 'GET',
        url: `/api/pay-links?${query}`,
        cancelTokenId: listPayLinksCancelTokenId,
    });
    return res.result;
}

export async function createPayLink(data: CreatePayLinkRequest) {
    const res = await request<PayLinkModel>({
        method: 'POST',
        url: `/api/pay-links`,
        data,
    });
    return res.result;
}

export async function uploadPayLinkDocument(data: FormData) {
    const res = await request<PayLinkDocumentModel[]>({
        method: 'POST',
        url: `/api/pay-links/upload-documents`,
        data,
    });
    return res.result;
}

export async function getPayLink(payLinkId: string) {
    const res = await request<PayLinkModel>({
        method: 'GET',
        url: `/api/pay-links/${payLinkId}`,
    });
    return res.result;
}

export async function sendPayLinkSms(payLinkId: string) {
    const res = await request<PayLinkModel>({
        method: 'POST',
        url: `/api/pay-links/${payLinkId}/send-sms`,
    });
    return res.result;
}

export async function sendPayLinkEmail(createPayLinkEmail: CreatePayLinkEmailModel) {
    const res = await request<PayLinkModel>({
        method: 'POST',
        data: createPayLinkEmail,
        url: `/api/pay-links/send-email`,
    });
    return res.result;
}

export async function cancelPayLink(payLinkId: string) {
    const res = await request<PayLinkModel>({
        method: 'POST',
        url: `/api/pay-links/${payLinkId}/cancel`,
    });
    return res.result;
}
