import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchCustomersAction, FetchCustomersActionOptions } from '../store/actions';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { debounce } from 'lodash';
import { CustomerModel, CustomizationType } from '../apis';
import selectors from '../store/selectors';
import Input from 'reactstrap/lib/Input';
import CustomersTable from '../components/customer/CustomersTable';
import { history } from '../index';
import querystring from 'querystring';
import InputMask from 'react-input-mask';

const searchIcon = '/Search-Icon.svg';

interface StateProps {
    customers: CustomerModel[];
    isLoading: boolean;
    customerName: string | null;
}

interface DispatchProps {
    fetchCustomersAction: typeof fetchCustomersAction;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

type Field = 'firstName' | 'lastName' | 'email' | 'mobile';

interface State {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    open: boolean;
}

class CustomersDashboard extends React.Component<Props, State> {
    state: State = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        open: false,
    };

    componentDidMount = async () => {
        const search = querystring.parse(this.props.history.location.search.replace('?', ''));

        if (search.firstName) {
            await this.setState({
                firstName: (search.firstName as string) || '',
            });
        }

        this.fetchCustomers();
    };

    updateUrl = () => {
        const { firstName, lastName, email, mobile } = this.state;

        const query = querystring.stringify({
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            email: email || undefined,
            mobile: selectors.format.formatMobile(mobile, true) || undefined,
        });

        if (firstName || lastName || email || mobile) {
            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: `?${query}`,
            });
        }
    };

    onRowClicked = (customer: CustomerModel) => {
        history.push(selectors.global.customerRoute(customer.id));
    };

    fetchCustomers = (skip?: number) => {
        const { firstName, lastName, mobile, email } = this.state;
        this.updateUrl();

        let options: FetchCustomersActionOptions = {
            skip,
        };
        options = {
            ...options,
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            mobile: selectors.format.formatMobile(mobile, true) || undefined,
            email: email || undefined,
        };

        this.props.fetchCustomersAction(options);
    };

    fetchCustomersDebounced = debounce(this.fetchCustomers, 1000);

    onSearchChange = async (field: string, label: Field) => {
        await this.setState({ [label]: field } as Pick<State, Field>);
        if (field.length > 2 || !field) {
            this.fetchCustomersDebounced();
        }
    };

    render() {
        // Used for customization
        const { customerName } = this.props;
        const customerNamePlural = selectors.customizations.pluralize(customerName);

        const { firstName, lastName, mobile, email } = this.state;

        return (
            <Layout>
                <div className="mb-4">
                    <h1 className="text-capitalize">{customerNamePlural}</h1>
                    <div>
                        <div className="font-weight-semi-bold mb-4">
                            Search customers by name, mobile number or email
                        </div>
                        <Row>
                            <Col md={6} xs={12} className="mb-2">
                                <InputGroup>
                                    <Input
                                        value={firstName.toString() || ''}
                                        onChange={(e) => this.onSearchChange(e.target.value, 'firstName')}
                                        placeholder="First Name"
                                        bsSize={'lg'}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <img src={searchIcon} width={'21px'} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12} className="mb-2">
                                <InputGroup>
                                    <Input
                                        value={lastName.toString() || ''}
                                        onChange={(e) => this.onSearchChange(e.target.value, 'lastName')}
                                        placeholder="Last Name"
                                        bsSize={'lg'}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <img src={searchIcon} width={'21px'} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={6} xs={12} className="mb-2">
                                <InputGroup className="h-100">
                                    <InputMask
                                        placeholder="Mobile"
                                        className="form-control form-control-lg h-100"
                                        mask="(999) 999-9999"
                                        value={mobile.toString() || ''}
                                        onChange={(e) => this.onSearchChange(e.target.value, 'mobile')}
                                        data-sh="mobile-input"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <img src={searchIcon} width={'21px'} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12} className="mb-2">
                                <InputGroup>
                                    <Input
                                        value={email.toString() || ''}
                                        onChange={(e) => this.onSearchChange(e.target.value, 'email')}
                                        placeholder="Email"
                                        bsSize={'lg'}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <img src={searchIcon} width={'21px'} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>

                    <CustomersTable
                        onRowClick={this.onRowClicked}
                        onGoTo={(page, skip) => this.fetchCustomers(skip)}
                        isLoading={this.props.isLoading}
                    />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    customers: state.customer.list.items,
    isLoading: selectors.global.isActionRequesting(state.global.actions, 'fetch_customers'),
    customerName: selectors.customizations.getCustomization(state, CustomizationType.CustomerName),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchCustomersAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomersDashboard));
