import { AuthenticationState } from '../types';
import { Action, Reducer } from 'redux';
import { LOGIN, SET_ROLES, READY } from '../constants';
import { AuthenticationAction } from '../actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthenticationState = {
    isInitialized: false,
    accessToken: null,
    tokenType: null,
    user: {
        email: null,
        roles: null,
    },
    isReady: false,
};

export const authenticationReducer: Reducer<AuthenticationState> = (
    state: AuthenticationState = unloadedState,
    incomingAction: Action,
): AuthenticationState => {
    const action = incomingAction as AuthenticationAction;
    switch (action.type) {
        case LOGIN:
            return {
                ...unloadedState,
                ...action.payload,
                isInitialized: true,
            };
        case SET_ROLES:
            return {
                ...state,
                user: {
                    ...state.user,
                    roles: action.payload.user.roles,
                },
            };
        case READY:
            return {
                ...state,
                ...action.payload,
            };
    }

    return state;
};
