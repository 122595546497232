import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { PromotionModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import Pagination from '../../components/Pagination';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
import selectors from '../../store/selectors';
const chevronRight = '/Text-Link-Arrow.svg';
interface StateProps {
    promotions: PromotionModel[];
    promotionsTotal: number;
    promotionsSkipped: number;
    promotionsLimit: number;
    isLoading: boolean;
    timezone: string | null;
}

interface OwnerProps {
    isLoading?: boolean;
    onRowClick: (promotion: PromotionModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
}

type Props = StateProps & OwnerProps;

class PromotionsTable extends React.Component<Props> {
    hasNoRows = () => this.props.promotions.length === 0 && !this.props.isLoading;

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no promotions to display'}
            </Alert>
        );
    };

    render() {
        const { timezone } = this.props;

        return (
            <>
                <Table hover responsive className={['table-lg', this.hasNoRows() ? 'mb-0' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th style={{ minWidth: 140 }}></th>
                        </tr>
                    </thead>
                    <TableBody
                        isLoading={this.props.isLoading}
                        rows={this.props.promotionsLimit}
                        columns={4}
                        loaderWidth="100%"
                    >
                        {this.props.promotions.map((promotion, index) => (
                            <tr onClick={() => this.props.onRowClick(promotion)} key={index}>
                                <td>{promotion.name}</td>
                                <td>
                                    {selectors.format.formatDateTimeSecondsWithYear(promotion.start_date, timezone)}
                                </td>
                                <td>
                                    {(promotion.end_date &&
                                        selectors.format.formatDateTimeSecondsWithYear(promotion.end_date, timezone)) ||
                                        '-'}
                                </td>
                                <td className="text-primary">
                                    <span className="mr-2">{'View'}</span>
                                    <span>
                                        <img width={'8px'} src={chevronRight} className="link-icon" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.promotionsTotal}
                    limit={this.props.promotionsLimit}
                    skipped={this.props.promotionsSkipped}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.promotionsLimit + 1, skip)}
                    disabled={this.props.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    promotions: state.promotions.list.items,
    promotionsSkipped: state.promotions.list.skipped,
    promotionsTotal: state.promotions.list.total,
    promotionsLimit: state.promotions.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_promotions') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
});

export default connect(mapStateToProps)(PromotionsTable);
