import * as React from 'react';
import { BalanceTransferSummary, GrossNetFeeAmount } from '../../apis';
import selectors from '../../store/selectors';
import Table from 'reactstrap/lib/Table';
import TableBody from '../TableBody';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchBalanceTransferSummaryAction } from '../../store/actions';
import Alert from 'reactstrap/lib/Alert';

interface OwnerProps {
    settlementId: string;
    isLoading?: boolean;
}

interface StateProps {
    balanceTransferSummary: BalanceTransferSummary | null;
    isRequesting: boolean;
}

interface DispatchProps {
    fetchBalanceTransferSummaryAction: typeof fetchBalanceTransferSummaryAction;
}

type Props = StateProps & DispatchProps & OwnerProps;

class BalanceTransfersSummary extends React.Component<Props> {
    componentDidMount = async () => {
        if (!this.props.isLoading) {
            this.props.fetchBalanceTransferSummaryAction({ settlementId: this.props.settlementId });
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.props.fetchBalanceTransferSummaryAction({ settlementId: this.props.settlementId });
        }
    }

    renderRow = (data: GrossNetFeeAmount, label: string) => {
        return (
            <tr>
                <td>
                    <span className="text-capitalize id-badge py-2 text-small text-dark">{label}</span>
                </td>
                <td>
                    <div className={`${data.gross < 0 ? 'text-danger' : 'text-success'} h5 text-right`}>
                        {(data && selectors.format.formatAmount(data.gross)) || '-'}
                    </div>
                </td>
                <td>
                    <div className={`${data.net < 0 ? 'text-danger' : 'text-success'} h5 text-right`}>
                        {(data && selectors.format.formatAmount(data.net)) || '—'}
                    </div>
                </td>
                <td>
                    <div className={`${data.fee < 0 ? 'text-danger' : 'text-success'} h5 text-right`}>
                        {(data && selectors.format.formatAmount(data.fee)) || '—'}
                    </div>
                </td>
            </tr>
        );
    };

    renderNoRows = () => {
        if (this.props.balanceTransferSummary !== null || this.props.isRequesting) return null;
        return <Alert color="info-light">There is no Summary to to display</Alert>;
    };

    render() {
        const { balanceTransferSummary } = this.props;
        const isLoading = this.props.isRequesting;

        return (
            <div>
                <div className="mb-2">
                    <h2>Balance Transfer Summary</h2>
                </div>
                <Table responsive className="table-lg">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th className="text-right">Gross</th>
                            <th className="text-right">Net</th>
                            <th className="text-right">Fee</th>
                        </tr>
                    </thead>
                    <TableBody isLoading={isLoading} rows={5} columns={4} loaderWidth="100%">
                        {balanceTransferSummary && (
                            <>
                                {balanceTransferSummary.charge_settlement_amount &&
                                    this.renderRow(
                                        balanceTransferSummary.charge_settlement_amount,
                                        'Charge Settlement',
                                    )}
                                {balanceTransferSummary.tip_settlement_amount &&
                                    this.renderRow(balanceTransferSummary.tip_settlement_amount, 'Tip Settlement')}
                                {balanceTransferSummary.charge_collection_settlement_amount &&
                                    this.renderRow(
                                        balanceTransferSummary.charge_collection_settlement_amount,
                                        'Charge Collection',
                                    )}
                                {balanceTransferSummary.tip_collection_settlement_amount &&
                                    this.renderRow(
                                        balanceTransferSummary.tip_collection_settlement_amount,
                                        'Tip Collection',
                                    )}
                                {balanceTransferSummary.promotional_credit_reversal_amount &&
                                    this.renderRow(
                                        balanceTransferSummary.promotional_credit_reversal_amount,
                                        'Promotional Credit Reversal',
                                    )}
                                {balanceTransferSummary.charge_reversal_amount &&
                                    this.renderRow(balanceTransferSummary.charge_reversal_amount, 'Charge Reversal')}
                                {balanceTransferSummary.tip_reversal_amount &&
                                    this.renderRow(balanceTransferSummary.tip_reversal_amount, 'Tip Reversal')}
                                {balanceTransferSummary.adjustment_amount &&
                                    this.renderRow(balanceTransferSummary.adjustment_amount, 'Adjustment')}
                                {balanceTransferSummary.promotional_credit_settlement_amount &&
                                    this.renderRow(
                                        balanceTransferSummary.promotional_credit_settlement_amount,
                                        'Promotional Credit Redeemed',
                                    )}
                            </>
                        )}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    balanceTransferSummary: state.balanceTransfer.summary,
    isRequesting: isActionRequesting(state.global.actions, 'fetch_balance_transfers_summary') || isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchBalanceTransferSummaryAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(BalanceTransfersSummary);
