import * as api from '../../apis';
import { AppThunkAction } from '../index';
import { ApplicationAction, setActionRequestingAction, setActionResponseErrorAction } from './index';
import { DashboardUserModel, DashboardUserWithId } from '../../apis';
import { ADD_USER, SET_USERS, UPDATE_USER_ROLES, REMOVE_USER } from '../constants';
import { DashboardUser, DashboardUserUpdated, ResponseError } from '../../apis';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetUsersAction {
    type: SET_USERS;
    payload: {
        arr: DashboardUserModel[];
    };
}

export const setUsersAction = (arr: DashboardUserModel[]): SetUsersAction => ({
    type: SET_USERS,
    payload: {
        arr,
    },
});

export interface AddUserAction {
    type: ADD_USER;
    payload: {
        user: DashboardUserModel;
    };
}

export const addUserAction = (user: DashboardUserModel): AddUserAction => ({
    type: ADD_USER,
    payload: {
        user,
    },
});

export interface RemoveUserAction {
    type: REMOVE_USER;
    payload: {
        email: string;
    };
}

export const removeUserAction = (email: string): RemoveUserAction => ({
    type: REMOVE_USER,
    payload: {
        email,
    },
});

export interface UpdateUserRoleAction {
    type: UPDATE_USER_ROLES;
    payload: {
        user: DashboardUserModel;
    };
}

export const updateUserRoleAction = (user: DashboardUserModel): UpdateUserRoleAction => ({
    type: UPDATE_USER_ROLES,
    payload: {
        user,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type UsersAction = SetUsersAction | AddUserAction | RemoveUserAction | UpdateUserRoleAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchDashboardUsersAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_dashboard_users', true));

        try {
            const body = await api.getDashboardUsers();
            await dispatch(setUsersAction(body));
            await dispatch(setActionRequestingAction('fetch_dashboard_users', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_dashboard_users', e));
            }
            await dispatch(setActionRequestingAction('fetch_dashboard_users', false));
            throw e;
        }
    };
}

export function addDashboardUserAction(user: DashboardUser): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('add_dashboard_user', true));

        try {
            const result = await api.addDashboardUser(user);
            await dispatch(addUserAction(result));
            await dispatch(setActionRequestingAction('add_dashboard_user', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('add_dashboard_user', e));
            }
            await dispatch(setActionRequestingAction('add_dashboard_user', false));
            throw e;
        }
    };
}

export function removeDashboardUserAction(user: DashboardUserWithId): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('remove_dashboard_user', true));
        try {
            await api.removeDashboardRoles(user);
            await dispatch(removeUserAction(user.email));
            await dispatch(setActionRequestingAction('remove_dashboard_user', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('remove_dashboard_user', e));
            }
            await dispatch(setActionRequestingAction('remove_dashboard_user', false));
            throw e;
        }
    };
}

export function updateDashboardUserRoleAction(user: DashboardUserUpdated): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('update_dashboard_user_roles', true));

        try {
            const result = await api.updateDashboardUserRole(user);
            await dispatch(updateUserRoleAction(result));
            await dispatch(setActionRequestingAction('update_dashboard_user_roles', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('update_dashboard_user_roles', e));
            }
            await dispatch(setActionRequestingAction('update_dashboard_user_roles', false));
            throw e;
        }
    };
}
