import * as api from '../../apis';
import { CreateCustomerRequest, CustomerModel, ListSegment, ResponseError } from '../../apis';
import { ADD_CUSTOMER, CLEAN_CUSTOMERS, SET_CUSTOMERS, UPDATE_CUSTOMER } from '../constants';
import { AppThunkAction } from '../index';
import { ApplicationAction, call, setActionRequestingAction, setActionResponseErrorAction } from './index';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetCustomersAction {
    type: SET_CUSTOMERS;
    payload: {
        list: ListSegment<CustomerModel>;
    };
}

export const setCustomersAction = (list: ListSegment<CustomerModel>): SetCustomersAction => ({
    type: SET_CUSTOMERS,
    payload: {
        list,
    },
});

export interface AddCustomerAction {
    type: ADD_CUSTOMER;
    payload: {
        customer: CustomerModel;
    };
}

export const addCustomerAction = (customer: CustomerModel): AddCustomerAction => ({
    type: ADD_CUSTOMER,
    payload: {
        customer,
    },
});

export interface CleanCustomersAction {
    type: CLEAN_CUSTOMERS;
}

export const cleanCustomersAction = (): CleanCustomersAction => ({
    type: CLEAN_CUSTOMERS,
});

export interface UpdateCustomerAction {
    type: UPDATE_CUSTOMER;
    payload: {
        data: CustomerModel;
    };
}

export const updateCustomersAction = (data: CustomerModel): UpdateCustomerAction => ({
    type: UPDATE_CUSTOMER,
    payload: {
        data,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type CustomerAction = SetCustomersAction | AddCustomerAction | CleanCustomersAction | UpdateCustomerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchCustomersActionOptions {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    skip?: number;
    limit?: number;
}

export function fetchCustomersAction(options: FetchCustomersActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        if (getState().merchant.selected === null) return;

        await call({
            actionName: 'fetch_customers',
            call: () => api.listCustomers(options),
            dispatch,
            onSuccess: async (body) => {
                await dispatch(setCustomersAction(body));
            },
        });
    };
}

export function createCustomerAction(data: CreateCustomerRequest): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('create_customer', true));

        try {
            const result = await api.createCustomer(data);
            await dispatch(addCustomerAction(result));
            await dispatch(setActionRequestingAction('create_customer', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('create_customer', e));
            }
            await dispatch(setActionRequestingAction('create_customer', false));
        }
    };
}

export function fetchCustomerAction(customerId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If customer already in the list, return directly
        if (getState().customer.list.items.find((value) => value.id === customerId)) {
            return;
        }

        await call({
            actionName: 'fetch_customer',
            call: () => api.getCustomer(customerId),
            dispatch,
            onSuccess: (result) => {
                dispatch(addCustomerAction(result));
            },
        });
    };
}

export function patchCustomersAction(
    customerId: string,
    data: CreateCustomerRequest,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('update_customer', true));
        try {
            const result = await api.updateCustomer(data, customerId);
            await dispatch(updateCustomersAction(result));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('update_customer', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('update_customer', false));
        }
    };
}
