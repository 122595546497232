import { SET_PROMOTION, SET_PROMOTIONS } from '../constants';
import { ListSegment, PromotionModel } from '../../apis';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';
import * as api from '../../apis';

export interface SetPromotionAction {
    type: SET_PROMOTION;
    payload: {
        selectedPromotion: PromotionModel;
    };
}

export const setPromotionAction = (selectedPromotion: PromotionModel): SetPromotionAction => ({
    type: SET_PROMOTION,
    payload: {
        selectedPromotion,
    },
});

export interface SetPromotionsAction {
    type: SET_PROMOTIONS;
    payload: {
        list: ListSegment<PromotionModel>;
    };
}

export const setPromotionsAction = (list: ListSegment<PromotionModel>): SetPromotionsAction => ({
    type: SET_PROMOTIONS,
    payload: {
        list,
    },
});

export function fetchPromotionAction(promotionId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_promotion',
            call: () => api.getPromotion(promotionId),
            dispatch,
            onSuccess: async (body) => {
                await dispatch(setPromotionAction(body));
            },
        });
    };
}

export function fetchPromotionsAction(skip?: number): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_promotions',
            call: () => api.listPromotions(skip),
            dispatch,
            onSuccess: async (body) => {
                await dispatch(setPromotionsAction(body));
            },
        });
    };
}

export type PromotionsAction = SetPromotionAction | SetPromotionsAction;
