import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchChargesAction, fetchSettlementAction } from '../store/actions';
import Layout from '../components/Layout';
import { Row } from 'reactstrap';
import selectors from '../store/selectors';
import { downloadSettlementDetailsReportById, SettlementModel } from '../apis';
import { ROUTE } from '../config';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { isActionRequesting } from '../store/selectors/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Col from 'reactstrap/lib/Col';
import PlaceholderLoader from '../components/PlaceholderLoader';
import BalanceTransfersTable from '../components/balanceTransfer/BalanceTransfersTable';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Dropdown from 'reactstrap/lib/Dropdown';
import BalanceTransfersSummary from '../components/balanceTransfer/BalanceTransfersSummary';
const reportIcon = '/report.svg';

interface StateProps {
    settlement: SettlementModel | null;
    isLoading: boolean;
    isRequestingCharges: boolean;
    timezone: string | null;
}

type OwnProps = RouteComponentProps<{
    settlementId: string;
}>;

interface DispatchProps {
    fetchSettlementAction: typeof fetchSettlementAction;
    fetchChargesAction: typeof fetchChargesAction;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
    open: boolean;
}

export class Settlement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    componentDidMount = async () => {
        try {
            await this.props.fetchSettlementAction(this.props.match.params.settlementId);
        } catch {
            // Error happen, redirect
            this.props.history.push(ROUTE.SETTLEMENTS);
            return;
        }
    };

    render() {
        const { settlement, isLoading } = this.props;
        return (
            <Layout
                breadcrumbItems={[
                    {
                        href: ROUTE.SETTLEMENTS,
                        title: 'Settlements',
                    },
                    {
                        href: selectors.global.settlementRoute(settlement ? settlement.id : ''),
                        title: (settlement && selectors.format.formatDate(settlement.date, this.props.timezone)) || '',
                    },
                ]}
            >
                <div className="mb-4 border-bottom-gray">
                    <div className="mb-4">
                        <div className="d-flex mb-2">
                            <div className="col-8 px-0">
                                <PlaceholderLoader width="100%" isLoading={isLoading}>
                                    <h1>{settlement && `${settlement.id}`}</h1>
                                </PlaceholderLoader>
                            </div>
                            <div className="ml-auto d-flex flex-column justify-content-center">
                                <Dropdown
                                    isOpen={this.state.open}
                                    toggle={() => this.setState({ open: !this.state.open })}
                                    direction="down"
                                >
                                    <DropdownToggle
                                        size="sm"
                                        color="secondary"
                                        outline
                                        caret={false}
                                        className="report-button"
                                    >
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            disabled={!settlement}
                                            onClick={() =>
                                                downloadSettlementDetailsReportById((settlement && settlement.id) || '')
                                            }
                                            className="d-flex justify-content-between align-items-center"
                                        >
                                            <div className="mr-2">Download report</div>
                                            <div>
                                                <img src={reportIcon} width="19px" height="19px" />
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="border-bottom-gray mb-4">
                            <h2>Settlement details</h2>
                        </div>
                    </div>
                    <Row className="mb-3">
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Net amount</h3>
                                <h2 className="text-success">
                                    <PlaceholderLoader isLoading={isLoading} width="100%">
                                        {settlement && selectors.format.formatAmount(settlement.net_amount)}
                                    </PlaceholderLoader>
                                </h2>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Gross Amount</h3>
                                <div className="m-0">
                                    <PlaceholderLoader isLoading={isLoading} width="100%">
                                        {settlement && settlement.gross_amount > 0
                                            ? selectors.format.formatAmount(settlement.gross_amount)
                                            : '—'}
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Date</h3>
                                <div className="m-0">
                                    <PlaceholderLoader isLoading={isLoading} width="100%">
                                        {(settlement &&
                                            selectors.format.formatDate(settlement.date, this.props.timezone)) ||
                                            '—'}
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={12}>
                            <div className="detail-card">
                                <div className={`${settlement && !settlement.description && 'opacity-1'}`}>
                                    <h3>Description</h3>
                                    <PlaceholderLoader isLoading={isLoading} width="100%">
                                        {settlement && (
                                            <div className="text-primary-medium">{settlement.description || '—'}</div>
                                        )}
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="mb-4 border-bottom-gray">
                    <BalanceTransfersSummary
                        settlementId={(settlement && settlement.id) || ''}
                        isLoading={!settlement}
                    />
                </div>

                <BalanceTransfersTable settlementId={(settlement && settlement.id) || ''} isLoading={!settlement} />
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => {
    const settlement = state.settlement.list.items.find((el) => el.id === ownProps.match.params.settlementId) || null;
    return {
        settlement,
        isLoading: isActionRequesting(state.global.actions, 'fetch_settlement'),
        isRequestingCharges: isActionRequesting(state.global.actions, 'fetch_charges'),
        timezone: state.merchant.selected?.timezone ?? null,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchSettlementAction,
            fetchChargesAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Settlement);
