import {
    AuthenticationState,
    ChargeState,
    CustomerState,
    GlobalState,
    SettlementState,
    BalanceTransferState,
    PaymentSourcesState,
    MerchantState,
    ReportsState,
    SplitIoState,
    UsersState,
    PayLinksState,
    ReturnsState,
    RewardsState,
    PromotionsState,
    CreditsState,
    ProcessingFeeState,
} from './types';
import {
    authenticationReducer,
    globalReducer,
    chargeReducer,
    settlementReducer,
    customerReducer,
    balanceTransferReducer,
    paymentSourcesReducer,
    merchantReducer,
    reportsReducer,
    usersReducer,
    payLinksReducer,
    returnsReducer,
    rewardsReducer,
    promotionsReducer,
    creditsReducer,
    processingFeeReducer,
} from './reducers';
import { splitReducer } from '@splitsoftware/splitio-redux';

// The top-level state object
export interface ApplicationState {
    authentication: AuthenticationState;
    balanceTransfer: BalanceTransferState;
    global: GlobalState;
    charge: ChargeState;
    settlement: SettlementState;
    customer: CustomerState;
    paymentSources: PaymentSourcesState;
    merchant: MerchantState;
    reports: ReportsState;
    splitio: SplitIoState;
    users: UsersState;
    payLinks: PayLinksState;
    returns: ReturnsState;
    rewards: RewardsState;
    promotions: PromotionsState;
    credits: CreditsState;
    processingFees: ProcessingFeeState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: authenticationReducer,
    balanceTransfer: balanceTransferReducer,
    global: globalReducer,
    charge: chargeReducer,
    settlement: settlementReducer,
    customer: customerReducer,
    paymentSources: paymentSourcesReducer,
    merchant: merchantReducer,
    reports: reportsReducer,
    splitio: splitReducer,
    users: usersReducer,
    payLinks: payLinksReducer,
    returns: returnsReducer,
    rewards: rewardsReducer,
    promotions: promotionsReducer,
    credits: creditsReducer,
    processingFees: processingFeeReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
