import { ActionName } from '../types';
import {
    RESET_ACTION_RESPONSE_ERROR,
    RESET_ACTION_STATUS,
    SET_ACTION_REQUEST_STATUS,
    SET_ACTION_RESPONSE_ERROR,
    SET_CONFIGURATION,
    SET_ENVIRONMENT_DATA,
    SET_ENVIRONMENTS,
    SHOW_REQUEST_LIVE_DATA_MESSAGE,
    SHOW_SWITCHED_SANDBOX_MESSAGE,
    SWITCH_CONTEXT,
} from '../constants';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { setApiEnvironment, setConfiguration } from '../../config';
import {
    ApiEnvironment,
    ConfigurationModel,
    EnvironmentsModel,
    getConfiguration,
    ResponseError,
    ResponseErrorCode,
    ResponseErrorType,
} from '../../apis';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetActionRequestingAction {
    type: SET_ACTION_REQUEST_STATUS;
    payload: {
        name: ActionName;
        isRequesting: boolean;
    };
}

export function setActionRequestingAction(name: ActionName, isRequesting: boolean): SetActionRequestingAction {
    return {
        type: SET_ACTION_REQUEST_STATUS,
        payload: {
            name,
            isRequesting,
        },
    };
}

export interface SetActionResponseErrorAction {
    type: SET_ACTION_RESPONSE_ERROR;
    payload: {
        name: ActionName;
        error: ResponseError;
    };
}

export function setActionResponseErrorAction(name: ActionName, error?: ResponseError): SetActionResponseErrorAction {
    error = error || new ResponseError(ResponseErrorType.Unknown, ResponseErrorCode.Unknown);
    return {
        type: SET_ACTION_RESPONSE_ERROR,
        payload: {
            name,
            error,
        },
    };
}

export interface ResetActionResponseErrorAction {
    type: RESET_ACTION_RESPONSE_ERROR;
    payload: {
        name: ActionName;
    };
}

export const resetActionResponseErrorAction = (name: ActionName): ResetActionResponseErrorAction => ({
    type: RESET_ACTION_RESPONSE_ERROR,
    payload: {
        name,
    },
});

export interface ResetActionStatusAction {
    type: RESET_ACTION_STATUS;
    payload: {
        name: ActionName;
    };
}

export function resetActionStatusAction(name: ActionName): ResetActionStatusAction {
    return {
        type: RESET_ACTION_STATUS,
        payload: {
            name,
        },
    };
}

export interface SetConfigurationAction {
    type: SET_CONFIGURATION;
    payload: {
        configuration: ConfigurationModel;
    };
}

export function setConfigurationAction(configuration: ConfigurationModel): SetConfigurationAction {
    return {
        type: SET_CONFIGURATION,
        payload: {
            configuration,
        },
    };
}

interface SetEnvironmentsAction {
    type: SET_ENVIRONMENTS;
    payload: {
        environments: EnvironmentsModel;
    };
}

export function setEnvironmentsAction(environments: EnvironmentsModel): SetEnvironmentsAction {
    return {
        type: SET_ENVIRONMENTS,
        payload: { environments },
    };
}

export interface SetEnvironmentDataAction {
    type: SET_ENVIRONMENT_DATA;
    payload: {
        environmentData: ApiEnvironment;
    };
}

export function setEnvironmentDataAction(environmentData: ApiEnvironment): SetEnvironmentDataAction {
    setApiEnvironment(environmentData);
    return {
        type: SET_ENVIRONMENT_DATA,
        payload: {
            environmentData,
        },
    };
}

export interface ShowRequestLiveDataMessageAction {
    type: SHOW_REQUEST_LIVE_DATA_MESSAGE;
    payload: {
        show: boolean;
    };
}

export function showRequestLiveDataMessageAction(show: boolean): ShowRequestLiveDataMessageAction {
    return {
        type: SHOW_REQUEST_LIVE_DATA_MESSAGE,
        payload: {
            show,
        },
    };
}

export interface ShowSwitchedSandboxDataMessageAction {
    type: SHOW_SWITCHED_SANDBOX_MESSAGE;
    payload: {
        show: boolean;
    };
}

export function showSwitchedSandboxDataMessageAction(show: boolean): ShowSwitchedSandboxDataMessageAction {
    return {
        type: SHOW_SWITCHED_SANDBOX_MESSAGE,
        payload: {
            show,
        },
    };
}

export interface SwitchContextAction {
    type: SWITCH_CONTEXT;
    payload: {
        switching: boolean;
    };
}

export function switchContextAction(switching: boolean): SwitchContextAction {
    return {
        type: SWITCH_CONTEXT,
        payload: { switching },
    };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type GlobalAction =
    | SetActionRequestingAction
    | ResetActionStatusAction
    | SetActionResponseErrorAction
    | ResetActionResponseErrorAction
    | SetConfigurationAction
    | SetEnvironmentsAction
    | SetEnvironmentDataAction
    | ShowRequestLiveDataMessageAction
    | ShowSwitchedSandboxDataMessageAction
    | SwitchContextAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchConfiguration(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(setActionRequestingAction('fetch_configuration', true));
        try {
            const result = await getConfiguration();

            setConfiguration(result);
            dispatch(setConfigurationAction(result));

            dispatch(setActionRequestingAction('fetch_configuration', false));
        } catch (e) {
            // Error
        }
    };
}

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
export function toggleLiveDataAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        const hasLiveData = getState().merchant.selected ? getState().merchant.selected!.is_approved_live : false;
        const environmentData = getState().global.environmentData;
        dispatch(setActionRequestingAction('set_data', true));
        if (!hasLiveData) {
            dispatch(showRequestLiveDataMessageAction(true));
            dispatch(setActionRequestingAction('set_data', false));
        } else {
            const promise = switchingAction()(dispatch, getState);

            switch (environmentData) {
                case 'live':
                    dispatch(setEnvironmentDataAction('sandbox'));
                    break;
                case 'sandbox':
                    dispatch(setEnvironmentDataAction('live'));
                    break;
                default:
                    throw new Error('Not implemented environment data');
            }

            await promise;
            dispatch(setActionRequestingAction('set_data', false));
        }
    };
}

export function switchingAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(switchContextAction(true));

        // Timer to make the UX nice
        const wait = () => new Promise((resolve) => setTimeout(resolve, 500));
        await wait();

        dispatch(switchContextAction(false));
    };
}
