import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LinkContact from '../LinkContact';

type Props = {
    switchedToSandbox?: boolean;
    showModal: boolean;
    toggle: () => void;
};

export default class RequestLiveModal extends React.Component<Props> {
    render() {
        const { showModal, switchedToSandbox } = this.props;

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Request live access</ModalHeader>
                <ModalBody>
                    {switchedToSandbox && (
                        <p className="font-weight-bold text-warning">
                            Sandbox data is being show as this merchant is not live.
                        </p>
                    )}
                    <p className="font-weight-bold">
                        Please contact <LinkContact type="developers" /> to request live environment access and to
                        generate live API keys.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="outline-secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
