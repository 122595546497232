import * as api from '../../apis';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { SettlementModel } from '../../apis';
import { ResponseError } from '../../apis';
import { ListSegment } from '../../apis';
import { ADD_SETTLEMENT, SET_SETTLEMENTS } from '../constants';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetSettlementsAction {
    type: SET_SETTLEMENTS;
    payload: {
        list: ListSegment<SettlementModel>;
    };
}

export const setSettlementsAction = (list: ListSegment<SettlementModel>): SetSettlementsAction => ({
    type: SET_SETTLEMENTS,
    payload: {
        list,
    },
});

export interface AddSettlementAction {
    type: ADD_SETTLEMENT;
    payload: {
        settlement: SettlementModel;
    };
}

export const addSettlementAction = (settlement: SettlementModel): AddSettlementAction => ({
    type: ADD_SETTLEMENT,
    payload: {
        settlement: settlement,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type SettlementAction = SetSettlementsAction | AddSettlementAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchSettlementsActionOptions {
    skip?: number;
    limit?: number;
    from?: string;
    to?: string;
}

export function fetchSettlementsAction(options: FetchSettlementsActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        if (getState().merchant.selected === null) return;

        await dispatch(setActionRequestingAction('fetch_settlements', true));
        try {
            const body = await api.listSettlements(options);
            await dispatch(setSettlementsAction(body));
            await dispatch(setActionRequestingAction('fetch_settlements', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_settlements', e));
            }
            await dispatch(setActionRequestingAction('fetch_settlements', false));
            throw e;
        }
    };
}

export function fetchSettlementAction(settlementId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If settlement already in the list, return directly
        if (getState().settlement.list.items.find((value) => value.id === settlementId)) {
            return;
        }

        await dispatch(setActionRequestingAction('fetch_settlement', true));
        try {
            const result = await api.getSettlement(settlementId);
            await dispatch(addSettlementAction(result));
            await dispatch(setActionRequestingAction('fetch_settlement', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_settlement', e));
            }
            await dispatch(setActionRequestingAction('fetch_settlement', false));
            throw e;
        }
    };
}
