import * as React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { withOktaAuth } from '@okta/okta-react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { OktaStateProps } from '../config';
import { bindActionCreators, Dispatch } from 'redux';
import { loginUser } from '../store/actions';
import { ApplicationState } from '../store';
import { RouteProps } from 'react-router-dom';
import selectors from '../store/selectors';
const strongholdLogo = '/Stronghold-Logo-Mark-Blue.png';

interface StateProps {
    isInitialized: boolean;
    isAuthenticating: boolean;
}

interface DispatchProps {
    loginUser: typeof loginUser;
}

type OwnProps = RouteProps;

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isInitialized: state.authentication.isInitialized,
    isAuthenticating: selectors.global.isActionRequesting(state.global.actions, 'login'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            loginUser,
        },
        dispatch,
    );

type Props = StateProps & DispatchProps & OktaStateProps;

const AppLoaderWrapped = withOktaAuth(
    class AppLoader extends React.Component<Props> {
        async componentDidMount() {
            const { isInitialized, isAuthenticating } = this.props;
            const isAuthenticated = this.props.authState?.isAuthenticated ?? false;
            if (isAuthenticated && !isInitialized && !isAuthenticating) {
                await this.props.loginUser(this.props.oktaAuth, this.props.authState);
            }
        }

        render() {
            return (
                <div>
                    <div style={{ height: '100vh' }} className="bg-dark d-flex justify-content-center">
                        <div style={{ height: '85vh' }} className="d-flex flex-column justify-content-center">
                            <div>
                                <div className="mb-5 d-flex justify-content-center">
                                    <img
                                        style={{ width: '55px' }}
                                        className="img-fluid"
                                        src={strongholdLogo}
                                        alt="loader"
                                    />
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <BarLoader
                                        css={'background-color: rgba(255, 255, 255);'}
                                        color="#0b2993"
                                        height={'5px'}
                                        width={'200px'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(AppLoaderWrapped);
