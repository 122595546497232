import * as api from '../../apis';
import { ReturnModel } from '../../apis';
import { CLEAN_RETURNS, SET_RETURN } from '../constants';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetReturnAction {
    type: SET_RETURN;
    payload: {
        return: ReturnModel;
    };
}

export const setReturnAction = (data: ReturnModel): SetReturnAction => ({
    type: SET_RETURN,
    payload: {
        return: data,
    },
});

export interface CleanReturnsAction {
    type: CLEAN_RETURNS;
}

export const cleanReturnsAction = (): CleanReturnsAction => ({
    type: CLEAN_RETURNS,
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ReturnsAction = SetReturnAction | CleanReturnsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchReturnAction(chargeId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If return already in the list, return directly
        const chargeReturn = getState().returns.arr.find((value) => value.charge_id === chargeId);
        if (chargeReturn) {
            return chargeReturn;
        }

        await call({
            actionName: 'fetch_return',
            call: () => api.getReturn(chargeId),
            dispatch,
            onSuccess: (result) => {
                dispatch(setReturnAction(result));
            },
        });
    };
}
