import * as React from 'react';
import { ButtonProps, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { CustomerModel, MerchantModel, PAY_LINK_STATUS, PayLinkModel, ResponseError } from '../../apis';
import selectors from '../../store/selectors';
import { sendEmailAction, sendSmsAction, cancelPayLinkAction } from '../../store/actions';
import { LinkType } from '../../pages/Paylink/CreateStep2';
import Loader from '../Loader';
import SimpleConfirmationModal from '../modals/SimpleConfirmationModal';
import PayLinkSendErrorModal from '../modals/PayLinkSendErrorModal';

interface StateProps {
    smsErrorMessage: ResponseError | null;
    emailErrorMessage: ResponseError | null;
    merchant: MerchantModel | null;
    timezone: string | null;
    userEmail: string | null;
    isLoading: boolean;
}

interface OwnProps extends ButtonProps {
    customer: CustomerModel | null;
    payLink: PayLinkModel | null;
    setLink: (link: LinkType) => void;
    toggleModal: () => void;
}

interface DispatchProps {
    sendEmailAction: typeof sendEmailAction;
    sendSmsAction: typeof sendSmsAction;
    cancelPayLinkAction: typeof cancelPayLinkAction;
}

type Props = StateProps & OwnProps & DispatchProps;
export type PayLinkActionType = 'resend-sms' | 'resend-email' | 'cancel';

interface State {
    ready: boolean;
    showModal: boolean;
    action: PayLinkActionType | null;
    smsDisabled: boolean;
    emailDisabled: boolean;
    showCancelModal: boolean;
    showSmsSendErrorModal: boolean;
    showEmailSendErrorModal: boolean;
}

class PayLinkActions extends React.Component<Props, State> {
    private DELAY_ACTION_DURATION = 10000;
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
        smsDisabled: false,
        emailDisabled: false,
        showCancelModal: false,
        showSmsSendErrorModal: false,
        showEmailSendErrorModal: false,
    };

    onSmsResend = () => {
        this.setState({ smsDisabled: true });
        setTimeout(() => this.setState({ smsDisabled: false }), this.DELAY_ACTION_DURATION);
    };

    onEmailResend = () => {
        this.setState({ emailDisabled: true });
        setTimeout(() => this.setState({ emailDisabled: false }), this.DELAY_ACTION_DURATION);
    };

    onCancel = async () => {
        if (this.props.payLink) {
            await this.props.cancelPayLinkAction(this.props.payLink.id);
            this.toggleCancelModal();
        }
    };

    toggleCancelModal = () => this.setState({ showCancelModal: !this.state.showCancelModal });
    togglePayLinkSmsSendErrorModal = () => this.setState({ showSmsSendErrorModal: !this.state.showSmsSendErrorModal });
    togglePayLinkEmailSendErrorModal = () =>
        this.setState({ showEmailSendErrorModal: !this.state.showEmailSendErrorModal });

    onActionClick = async (action: PayLinkActionType) => {
        const { payLink, customer, merchant } = this.props;
        if (action === null || !payLink || !customer) return;
        this.setState({ ready: false });
        switch (action) {
            case 'resend-sms':
                if (!customer.sms_block_expires_at) {
                    try {
                        await this.props.sendSmsAction(payLink.id);
                        await this.props.setLink('sms');
                        this.onSmsResend();
                        this.props.toggleModal();
                    } catch {
                        this.togglePayLinkSmsSendErrorModal();
                    }
                }
                break;
            case 'resend-email':
                if (merchant && !customer.email_block_expires_at) {
                    const createEmailModel = {
                        pay_link_id: payLink.id,
                        pay_link_url: payLink.url,
                        customer: {
                            email: customer.individual.email,
                            first_name: customer.individual.first_name,
                            last_name: customer.individual.last_name,
                        },
                        merchant_name: merchant.communication_safe_name,
                    };

                    try {
                        await this.props.sendEmailAction(createEmailModel);
                        await this.props.setLink('email');
                        this.onEmailResend();
                        this.props.toggleModal();
                    } catch {
                        this.togglePayLinkEmailSendErrorModal();
                    }
                }
                break;
            case 'cancel':
                this.toggleCancelModal();
                break;
            default:
                break;
        }
        this.setState({ ready: true });
    };

    renderDropdownItem = (
        action: PayLinkActionType,
        isDisabled?: boolean,
        isBlocked?: boolean,
        blockedReason?: string,
    ) => {
        return (
            <div id={`${action}`}>
                <DropdownItem onClick={() => this.onActionClick(action)} disabled={isDisabled || isBlocked}>
                    <span className="text-capitalize font-size-normal">
                        {selectors.format.formatHypenated(action)} paylink
                    </span>
                    {blockedReason && <div className="small text-muted text-wrap">{blockedReason}</div>}
                    {isDisabled && <Loader className="ml-1" size="sm" />}
                </DropdownItem>
            </div>
        );
    };

    render() {
        const spinner = <Spinner type="grow" color="black" size="sm" width={'100%'} />;
        const { smsDisabled, emailDisabled, ready } = this.state;
        const isLoading = !ready || this.props.isLoading;
        const isDisabled = !this.props.payLink || isLoading || this.props.payLink.status === PAY_LINK_STATUS.CANCELED;
        const { showCancelModal, showSmsSendErrorModal, showEmailSendErrorModal } = this.state;
        const { smsErrorMessage, emailErrorMessage, customer, timezone } = this.props;
        const smsBlocked = customer?.sms_block_expires_at !== undefined;
        const smsBlockedReason =
            'SMS send is blocked' +
            (customer?.sms_block_expires_at
                ? ' until ' + selectors.format.formatDateTimeSecondsWithYear(customer?.sms_block_expires_at, timezone)
                : '') +
            ". Updating the customer's mobile will remove the block.";
        const emailBlocked = customer?.email_block_expires_at !== undefined;
        const emailBlockedReason =
            'Email send is blocked' +
            (customer?.email_block_expires_at
                ? ' until ' + selectors.format.formatDateTimeSecondsWithYear(customer?.email_block_expires_at, timezone)
                : '') +
            ". Updating the customer's email will remove the block.";
        return (
            <>
                <UncontrolledDropdown disabled={isDisabled}>
                    <DropdownToggle
                        caret={!isDisabled && !isLoading}
                        disabled={isLoading}
                        color={isDisabled ? 'outline-secondary' : 'outline-primary'}
                        size="sm"
                    >
                        Select action {isLoading && spinner}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.renderDropdownItem(
                            'resend-sms',
                            smsDisabled,
                            smsBlocked,
                            smsBlocked ? smsBlockedReason : undefined,
                        )}
                        {this.renderDropdownItem(
                            'resend-email',
                            emailDisabled,
                            emailBlocked,
                            emailBlocked ? emailBlockedReason : undefined,
                        )}
                        {this.renderDropdownItem('cancel')}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <SimpleConfirmationModal
                    isLoading={isLoading}
                    type={'Cancel'}
                    title={'Paylink'}
                    toggle={this.toggleCancelModal}
                    showModal={showCancelModal}
                    onSubmit={() => this.onCancel()}
                />
                <PayLinkSendErrorModal
                    toggle={this.togglePayLinkSmsSendErrorModal}
                    showModal={showSmsSendErrorModal}
                    error={smsErrorMessage}
                />
                <PayLinkSendErrorModal
                    toggle={this.togglePayLinkEmailSendErrorModal}
                    showModal={showEmailSendErrorModal}
                    error={emailErrorMessage}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isLoading: selectors.global.isActionRequesting(state.global.actions, 'cancel_pay_link'),
    smsErrorMessage: selectors.global.getResponseError(state.global.actions, 'send_sms'),
    emailErrorMessage: selectors.global.getResponseError(state.global.actions, 'send_email'),
    merchant: state.merchant.selected,
    timezone: state.merchant.selected?.timezone || null,
    userEmail: state.authentication.user.email,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            sendSmsAction,
            sendEmailAction,
            cancelPayLinkAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PayLinkActions);
