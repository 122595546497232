import * as api from '../../apis';
import { AppThunkAction } from '../index';
import {
    ApplicationAction,
    call,
    setActionRequestingAction,
    setActionResponseErrorAction,
    resetActionResponseErrorAction,
} from './index';
import { ADD_PAYLINK, CLEAN_PAYLINK, SET_PAYLINK, SET_PAYLINKS, UPSERT_PAYLINK } from '../constants';
import { CreatePayLinkEmailModel, CreatePayLinkRequest, ResponseError } from '../../apis';
import { PayLinkModel } from '../../apis/payLinks';
import { ListSegment } from '../../apis';
import { LinkType } from '../../pages/Paylink/CreateStep2';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetPayLinksAction {
    type: SET_PAYLINKS;
    payload: {
        list: ListSegment<PayLinkModel>;
    };
}

export const setPayLinksAction = (list: ListSegment<PayLinkModel>): SetPayLinksAction => ({
    type: SET_PAYLINKS,
    payload: {
        list,
    },
});

export interface AddPayLinkAction {
    type: ADD_PAYLINK;
    payload: {
        payLink: PayLinkModel;
    };
}

export const addPayLinkAction = (payLink: PayLinkModel): AddPayLinkAction => ({
    type: ADD_PAYLINK,
    payload: {
        payLink,
    },
});

export interface SetPayLinkAction {
    type: SET_PAYLINK;
    payload: {
        payLink: PayLinkModel;
    };
}

export const setPayLinkAction = (payLink: PayLinkModel): SetPayLinkAction => ({
    type: SET_PAYLINK,
    payload: {
        payLink,
    },
});

export interface UpsertPayLinkAction {
    type: UPSERT_PAYLINK;
    payload: {
        payLink: PayLinkModel;
    };
}

export const upsertPayLinkAction = (payLink: PayLinkModel): UpsertPayLinkAction => ({
    type: UPSERT_PAYLINK,
    payload: {
        payLink,
    },
});

export interface CleanPayLinkAction {
    type: CLEAN_PAYLINK;
}

export const cleanPayLinkAction = (): CleanPayLinkAction => ({
    type: CLEAN_PAYLINK,
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type PayLinksAction =
    | SetPayLinksAction
    | AddPayLinkAction
    | SetPayLinkAction
    | CleanPayLinkAction
    | UpsertPayLinkAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchPayLinkActionOptions {
    skip?: number;
    limit?: number;
    status?: string[];
    from?: string;
    to?: string;
    email?: string;
    mobile?: string;
    search?: string;
}

export function fetchPayLinksAction(options: FetchPayLinkActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        if (getState().merchant.selected === null) return;

        await call({
            actionName: 'fetch_pay_links',
            call: () => api.listPayLinks(options),
            dispatch,
            onSuccess: (body) => {
                dispatch(setPayLinksAction(body));
            },
        });
    };
}

export function fetchPayLinkAction(payLinkId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If pay link already in the list, return directly
        if (getState().payLinks.list.items.find((value) => value.id === payLinkId)) {
            return;
        }

        await call({
            actionName: 'fetch_pay_link',
            call: () => api.getPayLink(payLinkId),
            dispatch,
            onSuccess: (body) => {
                dispatch(addPayLinkAction(body));
            },
        });
    };
}

export function createPayLinkAction(
    data: CreatePayLinkRequest,
    type: LinkType,
    formData: FormData | null,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('create_pay_link', true));
        await dispatch(resetActionResponseErrorAction('send_email'));
        await dispatch(resetActionResponseErrorAction('send_sms'));
        let result: PayLinkModel | undefined;
        try {
            if (formData) {
                const documents = await api.uploadPayLinkDocument(formData);
                data.order.documents = documents;
            }
            result = await api.createPayLink(data);
            await dispatch(setPayLinkAction(result));
            await dispatch(setActionRequestingAction('create_pay_link', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('create_pay_link', e));
                throw e;
            }
            await dispatch(setActionRequestingAction('create_pay_link', false));
        }

        if (result) {
            try {
                switch (type) {
                    case 'email':
                        await dispatch(setActionRequestingAction('send_email', true));
                        const createEmailModel = {
                            customer: {
                                email: data.customer.email,
                                first_name: data.customer.first_name,
                                last_name: data.customer.last_name,
                            },
                            merchant_name: data.merchant.communication_safe_name,
                            pay_link_id: result.id,
                            pay_link_url: result.url,
                        };
                        await api.sendPayLinkEmail(createEmailModel);
                        await dispatch(setActionRequestingAction('send_email', false));
                        break;
                    case 'sms':
                        await dispatch(setActionRequestingAction('send_sms', true));
                        await api.sendPayLinkSms(result.id);
                        await dispatch(setActionRequestingAction('send_sms', false));
                        break;
                }
            } catch (e) {
                switch (type) {
                    case 'email':
                        if (e instanceof ResponseError) {
                            dispatch(setActionResponseErrorAction('send_email', e));
                        } else {
                            await dispatch(setActionRequestingAction('send_email', false));
                        }
                        break;
                    case 'sms':
                        if (e instanceof ResponseError) {
                            dispatch(setActionResponseErrorAction('send_sms', e));
                        } else {
                            await dispatch(setActionRequestingAction('send_sms', false));
                        }
                        break;
                }
            }
        }
    };
}

export function sendSmsAction(id: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('send_sms', true));

        try {
            await api.sendPayLinkSms(id);
            await dispatch(setActionRequestingAction('send_sms', false));
            await dispatch(resetActionResponseErrorAction('send_sms'));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('send_sms', e));
                throw e;
            }
            await dispatch(setActionRequestingAction('send_sms', false));
        }
    };
}

export function sendEmailAction(createEmailModel: CreatePayLinkEmailModel): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('send_email', true));
        try {
            await api.sendPayLinkEmail(createEmailModel);
            await dispatch(setActionRequestingAction('send_email', false));
            await dispatch(resetActionResponseErrorAction('send_email'));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('send_email', e));
                throw e;
            }
            await dispatch(setActionRequestingAction('send_email', false));
        }
    };
}

export function cancelPayLinkAction(payLinkId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'cancel_pay_link',
            call: () => api.cancelPayLink(payLinkId),
            dispatch,
            onSuccess: (body) => {
                dispatch(upsertPayLinkAction(body));
            },
        });
    };
}
