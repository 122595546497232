import * as React from 'react';
import { history } from '../index';
import Layout from '../components/Layout';
import { RouteComponentProps } from 'react-router';
import { SettlementModel } from '../apis';
import SettlementsTable from '../components/settlement/SettlementsTable';
import selectors from '../store/selectors';
import ListFilters from '../components/ListFilters';
import { fetchSettlementsAction, FetchSettlementsActionOptions } from '../store/actions';
import { debounce } from 'lodash';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { DateTime } from 'luxon';

interface StateProps {
    settlements: SettlementModel[];
}

interface DispatchProps {
    fetchSettlementsAction: typeof fetchSettlementsAction;
}

type OwnProps = RouteComponentProps<{
    settlementId: string;
}>;

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    from?: DateTime;
    to?: DateTime;
}

class Settlements extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            from: undefined,
            to: undefined,
        };
    }

    componentDidMount = async () => {
        const search = querystring.parse(this.props.history.location.search.replace('?', ''));

        if (search.from) {
            await this.setState({
                from: DateTime.fromISO(search.from as string),
            });
        }
        if (search.to) {
            await this.setState({
                to: DateTime.fromISO(search.to as string),
            });
        }

        this.fetchSettlements();
    };

    updateUrl = () => {
        const { from, to } = this.state;

        const query = querystring.stringify({
            from: from ? selectors.format.formatDateIso(from) : undefined,
            to: to ? selectors.format.formatDateIso(to) : undefined,
        });

        if (from || to) {
            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: `?${query}`,
            });
        }
    };

    fetchSettlements = (skip?: number) => {
        const { from, to } = this.state;
        this.updateUrl();

        let options: FetchSettlementsActionOptions = {
            skip,
        };

        options = {
            ...options,
            from: selectors.format.formatDateIso(from) || undefined,
            to: selectors.format.formatDateIso(to) || undefined,
        };

        this.props.fetchSettlementsAction(options);
    };

    onRowClicked = (data: SettlementModel) => {
        history.push(selectors.global.settlementRoute(data.id));
    };

    handleDateFromChange = async (date: DateTime | null) => {
        await this.setState({ from: date || undefined });
        this.fetchSettlementsDebounced();
    };

    handleDateToChange = (date: DateTime | null) => {
        this.setState({ to: date || undefined });
        this.fetchSettlementsDebounced();
    };

    fetchSettlementsDebounced = debounce(this.fetchSettlements, 500);

    renderAdvancedSearch = () => {
        return (
            <ListFilters
                to={this.state.to}
                from={this.state.from}
                onDateFromChange={this.handleDateFromChange}
                onDateToChange={this.handleDateToChange}
            />
        );
    };

    render() {
        return (
            <Layout>
                <div className="mb-4">
                    <h1>Settlements</h1>
                    <div>
                        <div className="font-weight-semi-bold mb-4">Pick a date range to filter settlements</div>
                        {this.renderAdvancedSearch()}
                    </div>
                    <SettlementsTable
                        onRowClick={this.onRowClicked}
                        onGoTo={(page, skip) => this.fetchSettlements(skip)}
                    />
                </div>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchSettlementsAction,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(Settlements);
