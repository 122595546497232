import {
    setActionRequestingAction,
    setEnvironmentDataAction,
    setEnvironmentsAction,
    showSwitchedSandboxDataMessageAction,
    switchingAction,
} from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction, setUserRolesAction } from './index';
import { SET_MERCHANT, SET_MERCHANTS } from '../constants';
import * as api from '../../apis';
import { MerchantModel } from '../../apis';
import { setMerchantId } from '../../config';
import { fetchMerchantTreatmentsAction } from './splitio';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface SetMerchantsAction {
    type: SET_MERCHANTS;
    payload: {
        arr: MerchantModel[];
    };
}

export function setMerchantsAction(arr: MerchantModel[]): SetMerchantsAction {
    return {
        type: SET_MERCHANTS,
        payload: { arr },
    };
}

interface SetMerchantAction {
    type: SET_MERCHANT;
    payload: {
        merchant: MerchantModel;
    };
}

export function setMerchantAction(merchant: MerchantModel): SetMerchantAction {
    setMerchantId(merchant.id);
    return {
        type: SET_MERCHANT,
        payload: { merchant },
    };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type MerchantAction = SetMerchantsAction | SetMerchantAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setSelectedMerchantAction(merchant: MerchantModel): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        await dispatch(setActionRequestingAction('set_merchant', true));
        const promise = switchingAction()(dispatch, getState);

        dispatch(setUserRolesAction(merchant.roles));
        if (!merchant.is_approved_live) {
            dispatch(setEnvironmentDataAction('sandbox'));
            dispatch(showSwitchedSandboxDataMessageAction(true));
        } else {
            dispatch(setEnvironmentDataAction('live'));
            dispatch(showSwitchedSandboxDataMessageAction(false));
        }

        dispatch(setMerchantAction(merchant));
        dispatch(fetchMerchantTreatmentsAction(merchant) as unknown as ApplicationAction);
        await dispatch(setActionRequestingAction('set_merchant', false));

        const environments = await api.getEnvironments();
        dispatch(setEnvironmentsAction(environments));

        await promise;
    };
}
