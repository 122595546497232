import { request } from './utils';
import { stringify } from 'querystring';
import { ListSegment } from './response';

export interface PaymentSourceModel {
    id: string;
    type: 'bank';
    label: string;
    active: boolean;
    unique_hash: string;
}

export async function listPaymentSources(options: { customerId?: string } = {}) {
    const query = stringify({
        ...options,
    });
    const res = await request<ListSegment<PaymentSourceModel>>({
        method: 'GET',
        url: `/api/payment-sources?${query}`,
    });
    return res.result;
}

export async function getPaymentSource(sourceId: string) {
    const res = await request<PaymentSourceModel>({
        method: 'GET',
        url: `/api/payment-sources/${sourceId}`,
    });
    return res.result;
}

export async function deactivatePaymentSource(sourceId: string) {
    const res = await request<PaymentSourceModel>({
        method: 'DELETE',
        url: `/api/payment-sources/${sourceId}`,
    });
    return res.result;
}

export async function deactivateAllPaymentSources(customerId: string) {
    const res = await request<PaymentSourceModel[]>({
        method: 'DELETE',
        url: `/api/payment-sources/all/${customerId}`,
    });
    return res.result;
}
