import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { isActionRequesting } from '../store/selectors/global';
import { RouteComponentProps } from 'react-router';
import { withOktaAuth } from '@okta/okta-react';
import { OktaStateProps } from '../config';

interface StateProps {
    isSandbox: boolean;
    isRequesting: boolean;
}

type Props = OktaStateProps & StateProps & RouteComponentProps;

interface State {
    hasSwitched: boolean;
}

const SandboxBannerWrapped = withOktaAuth(
    class SandboxBanner extends React.PureComponent<Props, State> {
        public state: State = {
            hasSwitched: false,
        };

        componentDidUpdate(prevProps: Readonly<Props>) {
            if (this.props.location === prevProps.location) {
                this.setState({ hasSwitched: true });
            }
        }

        public render() {
            const { isSandbox, isRequesting } = this.props;
            const { hasSwitched } = this.state;

            const isAuthenticated = this.props.authState?.isAuthenticated ?? false;
            const showBanner = !isRequesting && isSandbox && isAuthenticated;
            if (showBanner) {
                return (
                    <div className={`border-top border-warning sticky-top text-center ${hasSwitched ? 'fade-in' : ''}`}>
                        <div
                            className="text-light bg-warning px-3 d-inline-block"
                            style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                        >
                            Test data
                        </div>
                    </div>
                );
            }
            return null;
        }
    },
);

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    isSandbox: state.global.environmentData === 'sandbox',
    isRequesting: isActionRequesting(state.global.actions, 'set_data'),
});

export default connect(mapStateToProps, {})(SandboxBannerWrapped);
