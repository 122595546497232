import * as React from 'react';
import Paginator, { PaginationInformation } from 'paginator';
import PaginationBS from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

export interface Props {
    total: number;
    skipped: number;
    limit: number;
    length?: number;
    onClick: (skip: number) => void;
    disabled?: boolean;
}

const bigStep = 10;

export function getPaginationInfo(total: number, limit: number, length: number, currentPage: number) {
    const paginator = new Paginator(limit, length);
    return paginator.build(total, currentPage);
}

export const getCurrentPage = (skipped: number, limit: number) => Math.floor(skipped / limit) + 1;
export const getPageSkip = (page: number, limit: number) => (page - 1) * limit;
export const getPreviousBigStep = (paginationInfo: PaginationInformation, limit: number) => {
    const page = paginationInfo.current_page - bigStep;
    const has = page > 1;
    const hasPagesLeft = paginationInfo.first_page > 2;
    const skip = getPageSkip(page, limit);
    return { page, has, hasPagesLeft, skip };
};
export const getNextBigStep = (paginationInfo: PaginationInformation, limit: number) => {
    const page = paginationInfo.current_page + bigStep;
    const has = page < paginationInfo.total_pages;
    const hasPagesLeft = paginationInfo.last_page < paginationInfo.total_pages - 1;
    const skip = getPageSkip(page, limit);
    return { page, has, hasPagesLeft, skip };
};

export default function Pagination(props: Props) {
    const { limit, length = 7, skipped, total, onClick, disabled = false } = props;

    if (total === 0) {
        return null;
    }

    const currentPage = getCurrentPage(skipped, limit);
    const paginationInfo = getPaginationInfo(total, limit, length, currentPage);

    const firstPageSkip = 0;
    const lastPageSkip = getPageSkip(paginationInfo.total_pages, limit);

    const previousBigStep = getPreviousBigStep(paginationInfo, limit);
    const nextBigStep = getNextBigStep(paginationInfo, limit);

    const pages: JSX.Element[] = [];
    for (let i = paginationInfo.first_page; i < paginationInfo.last_page + 1; i++) {
        pages.push(
            <PaginationItem
                className="mr-3"
                key={`page-${i}`}
                active={i === paginationInfo.current_page}
                disabled={disabled}
            >
                <PaginationLink
                    onClick={i !== paginationInfo.current_page ? () => onClick(getPageSkip(i, limit)) : undefined}
                >
                    <strong>{i}</strong>
                </PaginationLink>
            </PaginationItem>,
        );
    }

    return (
        <PaginationBS listClassName="justify-content-center mt-2">
            {paginationInfo.first_page > 1 ? (
                <PaginationItem disabled={disabled}>
                    <PaginationLink onClick={() => onClick(firstPageSkip)}>1</PaginationLink>
                </PaginationItem>
            ) : null}
            {previousBigStep.has ? (
                <PaginationItem disabled={disabled}>
                    <PaginationLink first onClick={() => onClick(previousBigStep.skip)} />
                </PaginationItem>
            ) : null}
            {!previousBigStep.has && previousBigStep.hasPagesLeft ? (
                <PaginationItem disabled={true}>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            ) : null}
            {pages}
            {!nextBigStep.has && nextBigStep.hasPagesLeft ? (
                <PaginationItem disabled={true}>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            ) : null}
            {nextBigStep.has ? (
                <PaginationItem disabled={disabled}>
                    <PaginationLink last onClick={() => onClick(nextBigStep.skip)} />
                </PaginationItem>
            ) : null}
            {paginationInfo.last_page < paginationInfo.total_pages ? (
                <PaginationItem disabled={!paginationInfo.has_next_page || disabled}>
                    <PaginationLink onClick={() => onClick(lastPageSkip)}>{paginationInfo.total_pages}</PaginationLink>
                </PaginationItem>
            ) : null}
        </PaginationBS>
    );
}
