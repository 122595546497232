import * as React from 'react';
import { CustomerModel } from '../../apis';
import PlaceholderLoader from '../PlaceholderLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import selectors from '../../store/selectors';
import Tooltip from '../Tooltip';
import CustomersProfileEditModal from '../modals/CustomersProfileEditModal';
import Table from 'reactstrap/lib/Table';
const pencilIcon = '/edit.svg';
const chevronRight = '/Text-Link-Arrow.svg';
export type LinkType = 'qr' | 'sms' | 'email' | 'edit';

interface OwnProps {
    customer: CustomerModel | null;
    customCustomerName: string | null;
    canEditDetails?: boolean;
    customerDetailsPage?: boolean;
    timezone: string | null;
}

interface State {
    customer: CustomerModel | null;
    showEditForm: boolean;
    successScreenType: LinkType;
    dob: string;
}

type Props = OwnProps;

export default class CustomerDetails extends React.Component<Props, State> {
    toggleEditForm = () => this.setState({ showEditForm: !this.state.showEditForm });
    state: State = {
        customer: null,
        showEditForm: false,
        successScreenType: 'edit',
        dob: '',
    };

    deliveryBlocked = (dateString: string | null): boolean => {
        if (!dateString) {
            return false;
        }

        const expiration = new Date(dateString);
        const timeNow = new Date();

        return timeNow < expiration;
    };

    render() {
        const { customer, customCustomerName, canEditDetails, customerDetailsPage, timezone } = this.props;
        const { showEditForm } = this.state;
        const isLoading = !customer;

        const emailBlocked = this.deliveryBlocked(customer && customer.email_block_expires_at);
        const smsBlocked = this.deliveryBlocked(customer && customer.sms_block_expires_at);

        return (
            <div className="border-bottom-gray mb-4">
                {customerDetailsPage && (
                    <div className="d-flex mb-2">
                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                            <h1 className="mb-0">
                                {customer && `${customer.individual.first_name} ${customer.individual.last_name}`}
                            </h1>
                        </PlaceholderLoader>
                        {customer && customer.external_id ? (
                            <div className="ml-auto mt-auto">
                                <Tooltip className="id-badge text-small bg-dark-grey-3" tooltip="External ID">
                                    <div>{customer.external_id}</div>
                                </Tooltip>
                            </div>
                        ) : null}
                    </div>
                )}

                <div className="d-flex mb-3">
                    {customer && customer.is_blocked && (
                        <Tooltip
                            className="d-flex"
                            tooltip="Customer is blocked and is unable to complete a transaction."
                        >
                            <FontAwesomeIcon icon={faUserSlash} className="text-danger mr-2" />
                        </Tooltip>
                    )}
                    {!canEditDetails ? (
                        <Link to={selectors.global.customerRoute((customer && customer.id) || '')}>
                            <h2>
                                <span className="text-capitalize">{customCustomerName}</span> details
                            </h2>
                        </Link>
                    ) : (
                        <h2>
                            <span className="text-capitalize">{customCustomerName}</span> details
                        </h2>
                    )}

                    {canEditDetails && !isLoading && (
                        <Tooltip className="d-flex align-items-center" tooltip="Edit customer details">
                            <div
                                onClick={() => this.toggleEditForm()}
                                data-sh="customer-profile-edit"
                                className="edit-circle-link ml-2"
                            >
                                <img src={pencilIcon} alt={'Edit'} />
                            </div>
                        </Tooltip>
                    )}
                    <div>{customer && customer.is_blocked && <span className="ml-1 text-muted">[BLOCKED]</span>}</div>
                </div>

                <div className="mb-5">
                    <div className="mb-4">
                        <Table responsive className="table-sm mb-0">
                            <thead>
                                <tr>
                                    <th className="hover-text-cursor border-bottom-gray-sm">Name</th>
                                    <th className="hover-text-cursor border-bottom-gray-sm">Email</th>
                                    {emailBlocked && (
                                        <Tooltip
                                            className="d-flex align-items-center"
                                            tooltip="A temporary block from receiving paylinks via email due to a large number of failed delivery attempts to the provided address in a short period. Updating the address will remove this block immediately."
                                        >
                                            <th className="hover-text-cursor border-bottom-gray-sm">
                                                Email Blocked Until
                                            </th>
                                        </Tooltip>
                                    )}
                                    <th className="hover-text-cursor border-bottom-gray-sm">Mobile</th>
                                    {smsBlocked && (
                                        <Tooltip
                                            className="d-flex align-items-center"
                                            tooltip="A temporary block from receiving paylinks via text message due to a large number of failed delivery attempts to the provided number in a short period. Updating the number will remove this block immediately."
                                        >
                                            <th className="hover-text-cursor border-bottom-gray-sm">
                                                Mobile Blocked Until
                                            </th>
                                        </Tooltip>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {customer &&
                                                `${customer.individual.first_name} ${customer.individual.last_name}`}
                                        </PlaceholderLoader>
                                    </td>
                                    <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {customer && customer.individual.email}{' '}
                                        </PlaceholderLoader>
                                    </td>
                                    {emailBlocked && (
                                        <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {customer?.email_block_expires_at &&
                                                    selectors.format.formatDateTimeSecondsWithYear(
                                                        customer?.email_block_expires_at,
                                                        timezone,
                                                    )}
                                            </PlaceholderLoader>
                                        </td>
                                    )}
                                    <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {customer && customer.individual.mobile ? customer.individual.mobile : '—'}
                                        </PlaceholderLoader>
                                    </td>
                                    {smsBlocked && (
                                        <td className="hover-text-cursor py-3 border-bottom-gray-sm">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {customer?.sms_block_expires_at &&
                                                    selectors.format.formatDateTimeSecondsWithYear(
                                                        customer?.sms_block_expires_at,
                                                        timezone,
                                                    )}
                                            </PlaceholderLoader>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <Tooltip tooltip="Customer Id">
                        <PlaceholderLoader isLoading={isLoading}>
                            <span className="id-badge text-small bg-dark-grey-3">{customer && customer.id}</span>
                        </PlaceholderLoader>
                    </Tooltip>

                    {customerDetailsPage && (
                        <div className="d-flex">
                            <div className="ml-auto text-right">
                                <h5>Need help with this customer?</h5>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://stronghold.co/happiness-support-contact"
                                    className="text-primary view-link"
                                >
                                    <span className="mr-2 font-weight-bolder">Contact support</span>
                                    <span>
                                        <img width={'8px'} className="link-icon" src={chevronRight} />
                                    </span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                {customer && (
                    <CustomersProfileEditModal
                        customer={customer}
                        showModal={showEditForm}
                        toggle={this.toggleEditForm}
                        isLoading={isLoading}
                    />
                )}
            </div>
        );
    }
}
