import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import Layout from '../../components/Layout';
import { Button, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText, TabContent, TabPane } from 'reactstrap';
import querystring from 'querystring';
import { DateTime } from 'luxon';
import { ListSegment, PAY_LINK_STATUS, PayLinkModel } from '../../apis';
import selectors from '../../store/selectors';
import { history } from '../../index';
import { FetchPayLinkActionOptions, fetchPayLinksAction } from '../../store/actions';
import { debounce } from 'lodash';
import ListPayLinkFilters from '../../components/payLinks/PayLinkListFilters';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import PayLinksTable from '../../components/payLinks/PayLinksTable';
import { ROUTE } from '../../config';
const searchIcon = '/Search-Icon.svg';
const plusIcon = '/plus-svg.svg';

interface StateProps {
    payLinks: ListSegment<PayLinkModel>;
    isRequestingPayLinks: boolean;
}
type OwnProps = RouteComponentProps<{
    payLinkId: string;
}>;

interface DispatchProps {
    fetchPayLinksAction: typeof fetchPayLinksAction;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    search?: string;
    mobile?: string;
    email?: string;
    from?: DateTime;
    to?: DateTime;
    status: PAY_LINK_STATUS[];
    open: boolean;
    activeTab: number;
}

export class Paylinks extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            search: undefined,
            mobile: undefined,
            email: undefined,
            from: undefined,
            to: undefined,
            status: [],
            open: false,
            activeTab: 1,
        };
    }
    componentDidMount = async () => {
        const search = querystring.parse(this.props.history.location.search.replace('?', ''));

        if (search.from) {
            await this.setState({
                activeTab: 2,
                from: DateTime.fromISO(search.from as string),
            });
        }
        if (search.to) {
            await this.setState({
                activeTab: 2,
                to: DateTime.fromISO(search.to as string),
            });
        }
        if (search.status) {
            await this.setState({
                activeTab: 2,
                status:
                    typeof search.status === 'string'
                        ? (search.status.split(',') as PAY_LINK_STATUS[])
                        : (search.status as PAY_LINK_STATUS[]),
            });
        }

        if (search.mobile) {
            await this.setState({
                activeTab: 2,
                mobile: search.mobile as string,
            });
        }

        if (search.email) {
            await this.setState({
                activeTab: 2,
                email: search.email as string,
            });
        }

        if (search.search) {
            await this.setState({
                activeTab: 1,
                search: search.search as string,
            });
        }

        this.fetchPayLinks();
    };

    updateUrl = () => {
        const { from, to, status, search, mobile, email } = this.state;

        const query = querystring.stringify({
            from: from ? selectors.format.formatDateIso(from) : undefined,
            to: to ? selectors.format.formatDateIso(to) : undefined,
            mobile: mobile || undefined,
            email: email || undefined,
            status: status || undefined,
            search: search || undefined,
        });

        if (from || to || status || search || mobile || email) {
            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: `?${query}`,
            });
        }
    };

    onRowClicked = (data: PayLinkModel) => {
        history.push(selectors.global.payLinkRoute(data.id));
    };

    fetchPayLinks = (skip?: number) => {
        const { status, from, to, activeTab, search, mobile, email } = this.state;
        this.updateUrl();

        let options: FetchPayLinkActionOptions = {
            skip,
        };
        if (activeTab === 1) {
            options = {
                ...options,
                search,
            };
        } else {
            options = {
                ...options,
                status: status,
                from: selectors.format.formatDateIso(from) || undefined,
                to: selectors.format.formatDateIso(to) || undefined,
                email: email,
                mobile: mobile,
            };
        }

        try {
            this.props.fetchPayLinksAction(options);
        } catch {
            // show error.
        }
    };

    fetchPayLinksDebounced = debounce(this.fetchPayLinks, 1000);

    toggleTab = (activeTab: number) => {
        this.setState({ activeTab }, () => this.fetchPayLinks());
    };

    onEmailChange = async (email: string) => {
        await this.setState({ email });
        this.fetchPayLinksDebounced();
    };

    onMobileChange = async (mobile: string) => {
        await this.setState({ mobile });
        this.fetchPayLinksDebounced();
    };

    onSearchChange = async (search: string) => {
        await this.setState({ search });
        this.fetchPayLinksDebounced();
    };

    handleDateFromChange = async (date: DateTime | null) => {
        await this.setState({ from: date || undefined });
        this.fetchPayLinksDebounced();
    };

    handleDateToChange = (date: DateTime | null) => {
        this.setState({ to: date || undefined });
        this.fetchPayLinksDebounced();
    };

    handleStatusesChange = (statuses: PAY_LINK_STATUS[]) => {
        this.setState({ status: statuses });
        this.fetchPayLinksDebounced();
    };

    renderSearch = () => {
        return (
            <div className="col-md-6 col-12">
                <InputGroup>
                    <Input
                        bsSize={'lg'}
                        value={this.state.search}
                        onChange={(e) => this.onSearchChange(e.target.value)}
                        placeholder={'Search by Order Id, Email or Mobile.'}
                    />
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <img src={searchIcon} width={'21px'} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    };

    renderAdvancedSearch = () => {
        return (
            <CardBody>
                <ListPayLinkFilters
                    payLinkStatuses={this.state.status}
                    to={this.state.to}
                    from={this.state.from}
                    search={this.state.search}
                    onSearchChange={this.onSearchChange}
                    onPayLinkStatusChange={this.handleStatusesChange}
                    onDateFromChange={this.handleDateFromChange}
                    onDateToChange={this.handleDateToChange}
                />
            </CardBody>
        );
    };
    render() {
        const isLoading = this.props.isRequestingPayLinks;
        const { activeTab } = this.state;

        return (
            <Layout>
                <div>
                    <div className="row mb-4 border-bottom-gray-sm">
                        <div className="col-xs-12 col-md-8">
                            <h1 className="mt-0">Stronghold Direct</h1>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <div className="d-flex justify-content-end">
                                <Button size="lg" color="primary" onClick={() => history.push(ROUTE.PAYLINK_CREATE)}>
                                    <div className="d-flex">
                                        <img src={plusIcon} width="17px" className="mr-3" />
                                        <span>Create a paylink</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>

                    <h2>Find a paylink</h2>

                    <div className="border-bottom-gray-sm mb-4">
                        <div className="d-flex mt-3 col-12 col-md-6">
                            <div>
                                <Button
                                    color="none"
                                    className={`mr-4 ${activeTab === 1 ? 'btn-primary-2' : 'text-primary-2'}`}
                                    onClick={() => this.toggleTab(1)}
                                >
                                    Search
                                </Button>
                            </div>
                            <div>
                                <Button
                                    color="none"
                                    className={`mr-4 ${activeTab === 2 ? 'btn-primary-2' : 'text-primary-2'}`}
                                    onClick={() => this.toggleTab(2)}
                                >
                                    Advanced - Filter
                                </Button>
                            </div>
                        </div>
                    </div>

                    <TabContent className="mb-4" activeTab={activeTab}>
                        <TabPane className="fade-in" tabId={1}>
                            {this.renderSearch()}
                        </TabPane>
                        <TabPane className="fade-in" tabId={2}>
                            {this.renderAdvancedSearch()}
                        </TabPane>
                    </TabContent>

                    <PayLinksTable
                        onRowClick={this.onRowClicked}
                        onGoTo={(page, skip) => this.fetchPayLinks(skip)}
                        isLoading={isLoading}
                    />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        payLinks: state.payLinks.list,
        isRequestingPayLinks: isActionRequesting(state.global.actions, 'fetch_pay_links'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPayLinksAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Paylinks);
