import { request } from './utils';
import { ApiEnvironment } from './types';

export interface ApiKeyModel {
    key: string;
    publishable: boolean;
    environment: ApiEnvironment;
    created_at: string;
    last_used_at: string;
}

export interface EnvironmentsModel {
    has_live_data: boolean;
    sandbox_keys: ApiKeyModel[];
    live_keys: ApiKeyModel[];
}

export async function getEnvironments() {
    const res = await request<EnvironmentsModel>({
        method: 'GET',
        url: '/api/environments',
    });
    return res.result;
}
