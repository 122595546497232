import { SET_CREDIT, SET_CREDITS } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { segmentListLimit } from '../../apis';
import { CreditsState } from '../types';

const unloadedState: CreditsState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
    selectedCredit: null,
};

export const creditsReducer: Reducer<CreditsState, ApplicationAction> = (
    state = unloadedState,
    action,
): CreditsState => {
    switch (action.type) {
        case SET_CREDIT:
            return {
                ...state,
                selectedCredit: action.payload.selectedCredit,
            };
        case SET_CREDITS:
            return {
                ...state,
                list: action.payload.list,
            };
        default:
            return state;
    }
};
