import { request, segmentListLimit } from './utils';
import { ListSegment } from './response';
import { stringify } from 'querystring';

export enum PROMOTION_TYPE {
    CREDIT = 'credit',
}

export enum BENEFIT_TYPE {
    FIXED = 'fixed',
}

export enum TRIGGER_TYPE {
    PURCHASE = 'purchase',
    SOURCE_LINK = 'source_link',
}

export interface PromotionModel {
    benefit_type: BENEFIT_TYPE;
    created_at: string;
    first_purchase_only?: boolean;
    fixed_amount: number;
    id: string;
    immediate_use?: boolean;
    merchant_id: string;
    name: string;
    promotion_type: PROMOTION_TYPE;
    single_use?: boolean;
    start_date: string;
    end_date?: string;
    external_id?: string;
    merchant_split?: number;
    min_charge_amount?: number;
    disabled_on?: string;
    trigger_type: TRIGGER_TYPE;
    relink_allowed?: boolean;
}

export async function getPromotion(promotionId: string) {
    const res = await request<PromotionModel>({
        method: 'GET',
        url: `/api/promotions/${promotionId}`,
    });
    return res.result;
}

export async function listPromotions(skip?: number) {
    const query = stringify({
        limit: segmentListLimit,
        skip,
    });

    const res = await request<ListSegment<PromotionModel>>({
        method: 'GET',
        url: `/api/promotions?${query}`,
    });
    return res.result;
}
