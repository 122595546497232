export type TipType = 'percentage' | 'fixed';

export enum CustomizationType {
    ProviderName = 'dashboard::management:provider_name/v1',
    CustomerName = 'dashboard::management:customer_name/v1',
}

export interface TipCustomizationModel {
    type: TipType;
    value1: number;
    value2: number;
    value3: number;
}

export interface Customization {
    data: string;
    type: CustomizationType;
}

export interface CustomizationModel {
    tip: TipCustomizationModel;
    items: Customization[];
}
