import * as React from 'react';
import { Table } from 'reactstrap';
import { PayLinkDocumentModel } from '../../apis';
import TableBody from '../TableBody';
import selectors from '../../store/selectors';

interface Props {
    documents: PayLinkDocumentModel[] | undefined;
}

export default function PayLinkDocuments(props: Props) {
    const { documents } = props;
    const isLoading = !documents;

    if (!documents || documents.length === 0) {
        return null;
    }

    return (
        <div className="border-bottom-gray mb-4">
            <div className="mb-3">
                <h2>Documents</h2>
            </div>
            <div>
                <Table hover responsive className="table-lg">
                    <thead>
                        <tr>
                            <th style={{ minWidth: 140 }}>File name/type</th>
                            <th>Size</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <TableBody isLoading={isLoading} rows={documents.length} columns={3}>
                        {documents.map((document, index) => (
                            <tr key={index}>
                                <td>{document.file_name}</td>
                                <td>{`${selectors.format.formatKB(document.file_size)} KB`}</td>
                                <td>
                                    <a href={document.url} download={document.url} target="_blank" rel="noreferrer">
                                        Download
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
