import { AuthenticationAction } from './authentication';
import { BalanceTransferAction } from './balanceTransfer';
import { GlobalAction, setActionRequestingAction, setActionResponseErrorAction } from './global';
import { ChargeAction } from './charge';
import { SettlementAction } from './settlement';
import { CustomerAction } from './customer';
import { PaymentSourcesAction } from './paymentSources';
import { MerchantAction } from './merchant';
import { ReportsAction } from './reports';
import { ActionName } from '../types';
import { UsersAction } from './users';
import axios from 'axios';
import { ResponseError } from '../../apis';
import { PayLinksAction } from './payLinks';
import { ReturnsAction } from './returns';
import { RewardsAction } from './rewards';
import { PromotionsAction } from './promotions';
import { CreditsAction } from './credits';
import { ProcessingFeeAction } from './processingFees';

export * from './authentication';
export * from './balanceTransfer';
export * from './global';
export * from './charge';
export * from './settlement';
export * from './customer';
export * from './paymentSources';
export * from './merchant';
export * from './reports';
export * from './splitio';
export * from './users';
export * from './payLinks';
export * from './returns';
export * from './rewards';
export * from './promotions';
export * from './credits';
export * from './processingFees';

export type ApplicationAction =
    | AuthenticationAction
    | BalanceTransferAction
    | GlobalAction
    | ChargeAction
    | SettlementAction
    | CustomerAction
    | PaymentSourcesAction
    | MerchantAction
    | ReportsAction
    | UsersAction
    | PayLinksAction
    | ReturnsAction
    | RewardsAction
    | PromotionsAction
    | CreditsAction
    | ProcessingFeeAction;

/**
 * Helper method allowing to do an asynchronous API call.
 * It allows to generically handle cancelation, errors and redux dispatch.
 */
export async function call<T>(options: {
    actionName: ActionName;
    dispatch: (action: ApplicationAction) => void;
    call: () => Promise<T>;
    onSuccess: (data: T) => void;
    onCancel?: (err: Error) => void;
    onError?: (err: Error) => void;
}) {
    const { actionName, call, dispatch, onSuccess, onCancel, onError } = options;
    await dispatch(setActionRequestingAction(actionName, true));

    try {
        const body = await call();
        onSuccess(body);
        await dispatch(setActionRequestingAction(actionName, false));
    } catch (e) {
        // If request canceled, we return directly
        if (axios.isCancel(e)) {
            if (onCancel) {
                onCancel(e);
            }
            return;
        }

        // If an error happened, we try to dispatch it to the store
        if (e instanceof ResponseError) {
            dispatch(setActionResponseErrorAction(actionName, e));
        }
        await dispatch(setActionRequestingAction(actionName, false));

        // If the error call back is provided, we call the callback with the error
        if (onError) {
            onError(e);
        }
    }
}
