import * as global from './global';
import * as format from './format';
import * as charge from './charge';
import * as customizations from './customizations';
import * as customer from './customer';
import * as paylink from './paylink';
import * as user from './user';
import * as merchant from './merchant';

const selectors = {
    global,
    format,
    charge,
    customer,
    customizations,
    paylink,
    user,
    merchant,
};

export default selectors;
