import { ActionStatus, GlobalState } from '../types';
import { Reducer } from 'redux';
import {
    RESET_ACTION_STATUS,
    SET_ACTION_REQUEST_STATUS,
    SET_ACTION_RESPONSE_ERROR,
    RESET_ACTION_RESPONSE_ERROR,
    SET_CONFIGURATION,
    SET_ENVIRONMENT_DATA,
    SET_ENVIRONMENTS,
    SHOW_REQUEST_LIVE_DATA_MESSAGE,
    SWITCH_CONTEXT,
    SHOW_SWITCHED_SANDBOX_MESSAGE,
} from '../constants';
import { ApplicationAction } from '../actions';
import { clone, unionBy } from 'lodash';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: GlobalState = {
    actions: [],
    configuration: {
        environment: '',
        sentry_dsn: '',
        data_dog: { client_token: '', site: '', environment: '' },
        oidc: { authority: '', client_id: '', post_logout_redirect_uri: '', redirect_uri: '' },
        launch_darkly: { client_id: '' },
        split: { api_key: '' },
        admin_uri: '',
        portal_url: '',
    },
    environments: {
        has_live_data: false,
        live_keys: [],
        sandbox_keys: [],
    },
    environmentData: 'live',
    showRequestLiveDataMessage: false,
    showSwitchedToSandboxMessage: false,
    switchingContext: false,
};
const unloadedActionStatusState: ActionStatus = { name: 'login', isRequesting: false };

export const globalReducer: Reducer<GlobalState, ApplicationAction> = (state = unloadedState, action): GlobalState => {
    switch (action.type) {
        case SET_ACTION_REQUEST_STATUS:
            let tmp = state.actions.find((el) => el.name === action.payload.name);
            tmp = tmp
                ? clone(tmp)
                : ({
                      ...unloadedActionStatusState,
                      name: action.payload.name,
                  } as ActionStatus);
            tmp.isRequesting = action.payload.isRequesting;
            return {
                ...state,
                actions: unionBy([tmp], state.actions, (el) => el.name),
            };
        case SET_ACTION_RESPONSE_ERROR:
            return {
                ...state,
                actions: state.actions.map((el) => {
                    if (el.name !== action.payload.name) return el;
                    return {
                        ...el,
                        responseError: action.payload.error,
                        isRequesting: false,
                    };
                }),
            };
        case RESET_ACTION_RESPONSE_ERROR:
            return {
                ...state,
                actions: state.actions.filter((item) => item.name != action.payload.name),
            };
        case RESET_ACTION_STATUS:
            return {
                ...state,
                actions: unionBy(
                    [
                        {
                            ...unloadedActionStatusState,
                            name: action.payload.name,
                        },
                    ],
                    state.actions,
                    (el) => el.name,
                ),
            };
        case SET_CONFIGURATION:
            return {
                ...state,
                configuration: action.payload.configuration,
            };
        case SET_ENVIRONMENTS:
            return {
                ...state,
                environments: action.payload.environments,
            };
        case SET_ENVIRONMENT_DATA:
            return {
                ...state,
                environmentData: action.payload.environmentData,
            };
        case SHOW_REQUEST_LIVE_DATA_MESSAGE:
            return {
                ...state,
                showRequestLiveDataMessage: action.payload.show,
            };
        case SHOW_SWITCHED_SANDBOX_MESSAGE:
            return {
                ...state,
                showSwitchedToSandboxMessage: action.payload.show,
            };
        case SWITCH_CONTEXT:
            return {
                ...state,
                switchingContext: action.payload.switching,
            };
    }

    return state;
};
