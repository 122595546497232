import * as React from 'react';
import { SPLIT_FLAG } from '../../store/types';
import { PropsWithChildren, ReactNode } from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchSplitTreatmentAction } from '../../store/actions';

type OwnProps = PropsWithChildren<{
    flag: SPLIT_FLAG;
    unactiveComponent?: ReactNode;
}>;

interface StateProps {
    treatment: string | null;
}

interface DispatchProps {
    fetchSplitTreatmentAction: typeof fetchSplitTreatmentAction;
}

type Props = OwnProps & StateProps & DispatchProps;

class ToggleSplit extends React.Component<Props> {
    componentDidMount = async () => {
        const { flag } = this.props;
        await this.props.fetchSplitTreatmentAction(flag);
    };

    render() {
        const { treatment, unactiveComponent, children } = this.props;

        if (treatment === 'on') {
            return children;
        }

        return unactiveComponent || null;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, props: OwnProps) => ({
    treatment: selectTreatmentValue(
        state.splitio,
        props.flag,
        state.merchant.selected ? state.merchant.selected.id : '',
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchSplitTreatmentAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ToggleSplit);
