import { ReportsState } from '../types';
import {
    ADD_AUTOMATED_MAILING_REPORT,
    REMOVE_AUTOMATED_MAILING_REPORT,
    SET_AUTOMATED_MAILING_REPORTS,
    SET_PROMOTION_REPORT,
    SET_TRANSACTIONS_REPORT,
    SWITCH_CONTEXT,
} from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ReportsState = {
    transactions: null,
    automatedMailing: {
        arr: [],
    },
    promotionReport: null,
};

export const reportsReducer: Reducer<ReportsState, ApplicationAction> = (
    state = unloadedState,
    action,
): ReportsState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_TRANSACTIONS_REPORT:
            return {
                ...state,
                transactions: action.payload.transactions,
            };
        case REMOVE_AUTOMATED_MAILING_REPORT:
            const filtered = state.automatedMailing.arr.filter(
                (el) => el.id !== action.payload.automatedMailingReportId,
            );
            return {
                ...state,
                automatedMailing: {
                    arr: filtered,
                },
            };
        case ADD_AUTOMATED_MAILING_REPORT:
            const updatedArr = [...state.automatedMailing.arr, action.payload.automatedMailingReport];
            return {
                ...state,
                automatedMailing: {
                    arr: updatedArr,
                },
            };
        case SET_AUTOMATED_MAILING_REPORTS:
            return {
                ...state,
                automatedMailing: {
                    arr: action.payload.automatedMailingReports,
                },
            };
        case SET_PROMOTION_REPORT:
            return {
                ...state,
                promotionReport: action.payload.promotionReport,
            };
        default:
            return state;
    }
};
