import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

type Props = {
    showModal: boolean;
    type: string;
    title: string;
    isLoading: boolean;
    toggle: () => void;
    onSubmit: () => void;
};

export default class SimpleConfirmationModal extends React.Component<Props> {
    render() {
        const { showModal, isLoading, type, title } = this.props;
        const spinner = <Spinner type="grow" color="black" size="sm" width={'100%'} />;

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader className="text-capitalize" toggle={this.props.toggle}>{`${type} ${title}`}</ModalHeader>
                <ModalBody>
                    <div>
                        Are you sure you want to <strong>{type}</strong> the {title}?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={'primary'} disabled={isLoading} onClick={this.props.onSubmit}>
                        {!isLoading ? 'Confirm' : spinner}
                    </Button>
                    <Button color="secondary" disabled={isLoading} onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
