import * as React from 'react';
import { Row } from 'reactstrap';
import { ReturnModel } from '../../apis';
import Col from 'reactstrap/lib/Col';
import selectors from '../../store/selectors';
import Table from 'reactstrap/lib/Table';
import TableBody from '../TableBody';

interface Props {
    returnModel: ReturnModel;
    timezone: string | null;
}

export default function ReturnDetails(props: Props) {
    const { returnModel, timezone } = props;

    if (!returnModel) return null;

    return (
        <div className="mb-5 border-bottom-gray">
            <hr />
            <div className="d-flex mb-3">
                <h5 className="mb-0 text-capitalize">Return Details</h5>
            </div>
            <Row className="mb-4">
                <Col md={6}>
                    <div className="detail-card">
                        <div className="h5">Charge Status</div>
                        <div className="text-capitalize">{returnModel.charge_status.replace(/_/g, ' ')}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="detail-card">
                        <div className="h5">Reason</div>
                        <div className="text-capitalize">{returnModel.return_reason.replace(/_/g, ' ')}</div>
                    </div>
                </Col>
            </Row>
            {returnModel.events.length > 0 && (
                <Table hover responsive className="table-lg mb-5">
                    <thead>
                        <tr>
                            <th style={{ minWidth: 140 }}>Date</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <TableBody isLoading={!returnModel} rows={returnModel.events.length} columns={2}>
                        {returnModel.events.map((event, index) => (
                            <tr key={index}>
                                <td className="py-3 border-bottom-gray-sm">
                                    {selectors.format.formatDateTimeMinutesWithYear(event.created_at, timezone)}
                                </td>
                                <td className="py-3 border-bottom-gray-sm">{event.description}</td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
}
