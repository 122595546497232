import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { isActionRequesting } from '../store/selectors/global';
import { ApplicationState } from '../store';
import { ROUTE } from '../config';
import RequestLiveModal from '../components/developers/RequestLiveModal';
import { bindActionCreators, Dispatch } from 'redux';
import {
    setSelectedMerchantAction,
    showRequestLiveDataMessageAction,
    toggleLiveDataAction,
    showSwitchedSandboxDataMessageAction,
} from '../store/actions';
import {
    Collapse,
    Container,
    CustomInput,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Label,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from 'reactstrap';
import { CustomizationType, MerchantModel, ApplicationRoleModel } from '../apis';
import SelectMerchantModal from './modals/SelectMerchantModal';
import selectors from '../store/selectors';
import Loader from './Loader';
const cogs = '/settings-2.svg';
const internalAdmin = '/Admin-3.svg';
const logout = '/log-out.svg';
const lightProfile = '/Profile-Simple---White.svg';
const profile = '/Profile-Simple.svg';
const shLogo = '/Stronghold-Logo-Type-White_1.svg';
import { withOktaAuth } from '@okta/okta-react';
import { SignoutOptions } from '@okta/okta-auth-js';
import { OktaStateProps } from '../config';

interface StateProps {
    email: string | null;
    showLiveData: boolean;
    showRequestLiveDataMessage: boolean;
    showSwitchedSandboxDataMessage: boolean;
    selectedMerchant: MerchantModel | null;
    merchants: MerchantModel[];
    customCustomerName: string | null;
    manageAccountUri: string;
    postLogoutRedirectUri: string;
    adminUri: string;
    roles: ApplicationRoleModel[] | null;
    isRequesting: boolean;
    switching: boolean;
}

interface DispatchProps {
    toggleLiveDataAction: typeof toggleLiveDataAction;
    setSelectedMerchantAction: typeof setSelectedMerchantAction;
    showRequestLiveDataMessageAction: typeof showRequestLiveDataMessageAction;
    showSwitchedSandboxDataMessageAction: typeof showSwitchedSandboxDataMessageAction;
}

type OwnProps = RouteComponentProps & {
    fromRestricted?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps & OktaStateProps;

type State = {
    isOpen: boolean;
    showModal: boolean;
    dropdownOpen: boolean;
};

const NavMenuWrapped = withOktaAuth(
    class NavMenu extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
        }

        public state: State = {
            isOpen: false,
            showModal: false,
            dropdownOpen: false,
        };

        toggleModal = () => this.setState({ showModal: !this.state.showModal });

        onMouseEnter = () => {
            this.setState({ dropdownOpen: true });
        };

        onMouseLeave = () => {
            this.setState({ dropdownOpen: false });
        };

        onSubmit = async (merchant: MerchantModel) => {
            this.toggleModal();
            try {
                // Switch Stores
                if (merchant) {
                    // Ensuring merchant was actually passed in
                    this.props.setSelectedMerchantAction(merchant);
                }
            } catch {
                // Error
            }
        };

        signOut = async () => {
            const { postLogoutRedirectUri } = this.props;
            const signOutOptions: SignoutOptions = {
                postLogoutRedirectUri: postLogoutRedirectUri,
            };
            await this.props.oktaAuth.signOut(signOutOptions);
        };

        renderMerchantSelector = () => {
            const { merchants, selectedMerchant, isRequesting, fromRestricted } = this.props;

            if (merchants.length === 0 || (!fromRestricted && !selectedMerchant)) return null;

            if (fromRestricted) {
                // Returning altered NavItem for fromRestricted flow
                return (
                    <NavItem onClick={() => (merchants.length >= 1 ? this.toggleModal() : null)}>
                        <div className={`${merchants.length >= 1 && 'hover-pointer'} my-0 py-0`}>
                            {'Select Merchant'}
                        </div>
                    </NavItem>
                );
            }
            const currentMerchant = (
                <>
                    {isRequesting ? (
                        <Loader color={'white'} size="sm" />
                    ) : (
                        <>
                            <img
                                className="rounded-circle img-fluid bg-white mr-2"
                                style={{ height: 40, width: 40 }}
                                src={selectedMerchant.image_url}
                                alt={`${selectedMerchant.image_url} logo`}
                            />
                            {selectedMerchant.display_name}
                        </>
                    )}
                </>
            );

            return (
                <NavItem onClick={() => (merchants.length > 1 && !isRequesting ? this.toggleModal() : null)}>
                    <div className={`${merchants.length > 1 && 'hover-pointer'} my-0 py-0`}>{currentMerchant}</div>
                </NavItem>
            );
        };

        canCreateReports = () => {
            const { selectedMerchant, roles } = this.props;
            if (!selectedMerchant || !roles) {
                return false;
            }
            return selectors.user.canCreateReports(roles);
        };

        canCreatePaylinks = () => {
            const { selectedMerchant, roles } = this.props;
            if (!selectedMerchant || !roles) {
                return false;
            }
            return selectors.user.canCreatePayLinkSD(roles, selectedMerchant);
        };

        isAdmin = () => {
            const { selectedMerchant, roles } = this.props;
            if (!selectedMerchant || !roles) {
                return false;
            }
            return selectors.user.isAdmin(roles);
        };

        isRegularUser = () => {
            const { selectedMerchant, roles } = this.props;
            if (!selectedMerchant || !roles) {
                return;
            }
            return selectors.user.isRegularUser(roles);
        };

        toggleRequestLiveModal = () => {
            this.props.showSwitchedSandboxDataMessageAction(false);
            this.props.showRequestLiveDataMessageAction(false);
        };

        renderUserProfile = (color?: string) => {
            // todo manage, and change below
            const { authState, manageAccountUri, email, adminUri } = this.props;
            const isInternalUser = email && email.includes('@stronghold.co');
            const isLight = color === 'light';
            const isAuthenticated = authState?.isAuthenticated ?? false;

            if (isAuthenticated) {
                return (
                    <Dropdown
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                        isOpen={this.state.dropdownOpen}
                    >
                        <DropdownToggle nav className="py-0" drop="up">
                            <img className="float-right" src={isLight ? lightProfile : profile} width={'17px'} />
                        </DropdownToggle>
                        <DropdownMenu className="py-0">
                            <DropdownItem className="d-flex justify-content-start" href={manageAccountUri}>
                                <img src={cogs} className="nav-icon" />
                                <span className="text-small">Account</span>
                            </DropdownItem>
                            {isInternalUser && (
                                <DropdownItem className="d-flex justify-content-start" href={adminUri}>
                                    <img src={internalAdmin} className="nav-icon" />
                                    <span className="text-small">Internal Admin</span>
                                </DropdownItem>
                            )}
                            <DropdownItem onClick={this.signOut} className="d-flex justify-content-start">
                                <img src={logout} className="nav-icon" />
                                <span className="text-small">Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                );
            }
        };

        renderNavBar = () => {
            const { authState, merchants, customCustomerName, showLiveData, fromRestricted } = this.props;
            const customCustomerNamePlural = selectors.customizations.pluralize(customCustomerName);
            const isAuthenticated = (authState?.isAuthenticated && !fromRestricted) ?? false;

            return (
                <>
                    <Nav className="mr-auto" navbar>
                        {isAuthenticated && merchants.length > 0 ? (
                            <>
                                <DropdownItem divider className="d-md-none" />
                                <NavItem>
                                    <NavLink to={ROUTE.HOME} className="text-capitalize nav-link">
                                        <span>Home</span>
                                    </NavLink>
                                </NavItem>
                                <DropdownItem divider className="d-md-none" />
                                <NavItem>
                                    <NavLink to={ROUTE.CUSTOMERS} className="text-capitalize nav-link">
                                        <span>{customCustomerNamePlural}</span>
                                    </NavLink>
                                </NavItem>
                                {(this.isRegularUser() || this.isAdmin()) && (
                                    <>
                                        <DropdownItem divider className="d-md-none" />
                                        <NavItem>
                                            <NavLink to={ROUTE.TRANSACTIONS} className="nav-link">
                                                <span>Transactions</span>
                                            </NavLink>
                                        </NavItem>
                                        <DropdownItem divider className="d-md-none" />
                                        <NavItem>
                                            <NavLink to={ROUTE.SETTLEMENTS} className="nav-link">
                                                <span>Settlements</span>
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                {this.canCreateReports() && (
                                    <>
                                        <DropdownItem divider className="d-md-none" />
                                        <NavItem>
                                            <NavLink to={ROUTE.REPORTS} className="nav-link">
                                                <span>Reports</span>
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                {this.canCreatePaylinks() && (
                                    <>
                                        <DropdownItem divider className="d-md-none" />
                                        <NavItem>
                                            <NavLink to={ROUTE.PAYLINKS} className="nav-link">
                                                <span>Stronghold Direct</span>
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                <DropdownItem divider className="d-md-none" />

                                <NavItem>
                                    <NavLink to={ROUTE.PROMOTIONS} className="text-capitalize nav-link">
                                        <span>Promotions</span>
                                    </NavLink>
                                </NavItem>
                                <DropdownItem divider className="d-md-none" />

                                <NavItem>
                                    <NavLink to={ROUTE.IN_STORE} className="text-capitalize nav-link">
                                        <span>In Store</span>
                                    </NavLink>
                                </NavItem>
                                <DropdownItem divider className="d-md-none" />

                                {/*{(this.isRegularUser() || this.isAdmin()) && (*/}
                                {/*    <>*/}
                                {/*        <DropdownItem divider className="d-md-none" />*/}
                                {/*        <NavItem>*/}
                                {/*            <NavLink to={ROUTE.REWARDS} className="nav-link">*/}
                                {/*                <span>Rewards</span>*/}
                                {/*            </NavLink>*/}
                                {/*        </NavItem>*/}
                                {/*    </>*/}
                                {/*)}*/}
                                {this.isAdmin() && (
                                    <>
                                        <DropdownItem divider className="d-md-none" />
                                        <NavItem>
                                            <NavLink to={ROUTE.ADMIN} className="nav-link">
                                                <span>Admin</span>
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                <DropdownItem divider className="d-md-none" />
                            </>
                        ) : null}
                    </Nav>

                    <Nav navbar>
                        {isAuthenticated && (
                            <>
                                <DropdownItem divider className="d-md-none" />
                                <NavItem>
                                    <NavLink to={ROUTE.DEVELOPERS} className="nav-link">
                                        <span>Developers</span>
                                    </NavLink>
                                </NavItem>
                                <DropdownItem divider className="d-md-none" />
                                {merchants.length > 0 && (
                                    <Form inline className="mx-3 my-2 my-lg-0">
                                        <Label className={['mr-3'].join(' ')}>{'Test Mode'}</Label>
                                        <CustomInput
                                            className="pl-3"
                                            type="switch"
                                            id="toggle_data"
                                            name="toggle_data_switch"
                                            onClick={this.props.toggleLiveDataAction}
                                            checked={!showLiveData}
                                            readOnly={true}
                                        />
                                    </Form>
                                )}
                            </>
                        )}
                    </Nav>
                </>
            );
        };

        public render() {
            const { showModal } = this.state;
            const {
                showRequestLiveDataMessage,
                merchants,
                selectedMerchant,
                showSwitchedSandboxDataMessage,
                switching,
                fromRestricted,
            } = this.props;

            return (
                <header>
                    <Navbar color="dark" className="text-white d-none d-md-flex justify-content-center">
                        <Container className="md-nav-bar" fluid={true}>
                            <div className="col-4 pl-0 d-flex justify-content-start">
                                {this.renderMerchantSelector()}
                                {(selectedMerchant || fromRestricted) && (
                                    <SelectMerchantModal
                                        selectedMerchant={selectedMerchant}
                                        merchants={merchants}
                                        showModal={showModal}
                                        toggle={this.toggleModal}
                                        onSubmit={this.onSubmit}
                                    />
                                )}
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                                <a href="https://stronghold.co" target="_blank" rel="noreferrer">
                                    <img className="img-fluid" width="110px" src={shLogo} alt="loader" />
                                </a>
                            </div>
                            <div className="col-4 pr-0 d-flex justify-content-end">
                                {this.renderUserProfile('light')} {/*Light*/}
                            </div>
                        </Container>
                    </Navbar>
                    <Navbar color="dark" dark className="d-md-none">
                        <Container fluid={true}>
                            <NavbarBrand to="/" className="mr-0 h5">
                                Stronghold
                            </NavbarBrand>
                            <NavbarToggler
                                className="mx-0"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                            />
                        </Container>
                    </Navbar>
                    <Navbar dark expand="sm" className="d-none d-md-flex">
                        <Container className="md-nav-bar bottom-shadow-box  py-0" fluid={true}>
                            {this.renderNavBar()}
                        </Container>
                    </Navbar>
                    <Navbar dark className="d-md-none bottom-shadow-box">
                        <Container fluid={true}>
                            <NavItem className="py-1">
                                {this.renderMerchantSelector()}
                                {(selectedMerchant || fromRestricted) && (
                                    <SelectMerchantModal
                                        selectedMerchant={selectedMerchant}
                                        merchants={merchants}
                                        showModal={showModal}
                                        toggle={this.toggleModal}
                                        onSubmit={this.onSubmit}
                                    />
                                )}
                            </NavItem>
                            <NavItem>{this.renderUserProfile('dark')}</NavItem> {/*Dark*/}{' '}
                            {/*todo Nesting this here fires an error notification in console.*/}
                            <Collapse isOpen={this.state.isOpen} navbar>
                                {this.renderNavBar()}
                            </Collapse>
                        </Container>
                    </Navbar>
                    <RequestLiveModal
                        switchedToSandbox={showSwitchedSandboxDataMessage}
                        showModal={!switching && (showRequestLiveDataMessage || showSwitchedSandboxDataMessage)}
                        toggle={() => this.toggleRequestLiveModal()}
                    />
                </header>
            );
        }
    },
);

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    roles: state.authentication.user.roles || null,
    manageAccountUri: getManageActionUri(state.global.configuration.oidc.authority),
    postLogoutRedirectUri: state.global.configuration.oidc.post_logout_redirect_uri,
    email: state.authentication.user.email,
    merchants: state.merchant.arr,
    selectedMerchant: state.merchant.selected,
    showLiveData: state.global.environmentData === 'live',
    showRequestLiveDataMessage: state.global.showRequestLiveDataMessage,
    showSwitchedSandboxDataMessage: state.global.showSwitchedToSandboxMessage,
    customCustomerName: selectors.customizations.getCustomization(state, CustomizationType.CustomerName),
    adminUri: state.global.configuration.admin_uri,
    switching: state.global.switchingContext,
    isRequesting: isActionRequesting(state.global.actions, 'set_merchant'),
});

function getManageActionUri(authority: string): string {
    if (authority.includes('.com')) {
        return `${authority.split('.com')[0]}.com/enduser/settings`;
    }
    return `${authority.split('.co')[0]}.co/enduser/settings`;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            showRequestLiveDataMessageAction,
            showSwitchedSandboxDataMessageAction,
            setSelectedMerchantAction,
            toggleLiveDataAction,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenuWrapped));
