export enum ResponseErrorType {
    Unknown = 'unknown',
    ValidationError = 'validation_error',
}

export enum ResponseErrorCode {
    Unknown = 'unknown',
    FieldRequired = 'field_required',
    InvalidValue = 'invalid_value',
    ValueTaken = 'value_taken',
    InvalidCredentials = 'invalid_credentials',
}

export interface DashboardResponseError {
    type: string;
    code: string;
    property: string | null;
    message: string;
}

export class ResponseError extends Error implements DashboardResponseError {
    type: ResponseErrorType;
    code: ResponseErrorCode;
    property: string | null;
    message: string;

    public constructor(
        type: ResponseErrorType,
        code: ResponseErrorCode,
        message: string | null = null,
        property: string | null = null,
    ) {
        super(`${type}: ${code}, ${property}`);
        this.type = type;
        this.code = code;
        this.property = property;
        this.message = message || '';
    }
}

export interface DashboardResponse<T> {
    result: T;
}

export interface ListSegment<T> {
    total: number;
    limit: number;
    skipped: number;
    items: T[];
}

export interface DashboardResponseFailed {
    error: DashboardResponseError | null;
}
