import * as React from 'react';
import { Button } from 'reactstrap';

type Props = React.PropsWithChildren<{
    show: boolean;
    onShow: () => void;
}>;

export default class Hidden extends React.Component<Props> {
    render() {
        const { children, show } = this.props;

        if (show) {
            return children;
        }

        return (
            <div className="position-relative d-inline-block">
                <div
                    className="position-absolute"
                    style={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={this.props.onShow} className="bg-grey-150 border-0" color="light" size="sm">
                        Reveal
                    </Button>
                </div>
                <div className="text-blurry">{children}</div>
            </div>
        );
    }
}
