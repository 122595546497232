import { MerchantState } from '../types';
import { SET_MERCHANT, SET_MERCHANTS } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { orderBy } from 'lodash';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MerchantState = {
    arr: [],
    selected: null,
};

export const merchantReducer: Reducer<MerchantState, ApplicationAction> = (
    state = unloadedState,
    action,
): MerchantState => {
    switch (action.type) {
        case SET_MERCHANT:
            return {
                ...state,
                selected: action.payload.merchant,
            };
        case SET_MERCHANTS:
            return {
                ...state,
                arr: orderBy(action.payload.arr, 'display_name', 'asc'),
            };
        default:
            return state;
    }
};
