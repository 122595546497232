import * as React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Input,
    Label,
    InputGroupText,
    InputGroupAddon,
    InputGroup,
    Row,
    Col,
} from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';
import { ChargeActionType } from './../charge/ChargeActions';
import { PaymentSourceActionType } from './../paymentSource/PaymentSourceActions';
import selectors from '../../store/selectors';
import { UsersActionType } from './../users/UsersActions';
import { DashboardUserPosted, UpdateAmountPosted } from '../../apis';
import { AutomatedReportsActionType } from './../reports/AutomatedReportsActions';

type Props = {
    showModal: boolean;
    dashBoardUser?: DashboardUserPosted;
    amount: number;
    toggle: () => void;
    onSubmit: () => void;
    onFormSubmit?: (data: UpdateAmountPosted) => void;
    type: ChargeActionType | PaymentSourceActionType | UsersActionType | AutomatedReportsActionType | string;
    errorCode: string | null;
    isLoading: boolean;
    onAmountChange?: (input: string) => void;
    updatedAmount?: string;
};

interface State {
    captcha: string;
}

type Field = 'captcha';

export default class ConfirmationModal extends React.Component<Props, State> {
    public state: State = {
        captcha: '',
    };

    onInputChange = async (field: string, label: Field) => {
        await this.setState({ [label]: field } as Pick<State, Field>);
    };

    formatErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case 'insufficient_balance':
                return 'There is insufficient funds to capture this charge.';
            case 'charge_blocked_outstanding_payment':
                return 'Unable to capture this charge due to an outstanding payment.';
            case 'charge_blocked_exceeds_limit':
                return 'Unable to capture this charge due to Stronghold-enforced spending limits.';
            case 'customer_blocked':
                return 'Unable to capture this charge due to a customer block.';
            case 'payment_source_blocked':
                return 'Unable to capture this charge due to a payment source block.';
            case 'role_removal_error':
                return 'Some of the roles failed to be removed. Please contact Stronghold support for assistance.';
            case 'card_charge_capture_error':
            case 'card_charge_retrieval_error':
            case 'card_charge_not_found':
                return 'Unable to capture this card charge. Please contact Stronghold support for assistance.';
            case 'payment_source_has_pending_charge':
                return 'Unable to unlink this payment source due to a recent change on an associated charge.';
            default:
                return 'An error has occured during the action.';
        }
    };

    formatModalTitle = (type: string | null) => {
        switch (type) {
            case 'refund':
            case 'cancel':
            case 'capture':
            case 'update-amount':
                return 'transaction';
            case 'add':
                return 'user';
            case 'Remove User':
                return '';
            case 'remove':
                return 'report';
            case 'unlink all':
                return 'payment sources';
            default:
                return 'payment source';
        }
    };

    renderUserDetails = () => {
        const { dashBoardUser } = this.props;
        if (dashBoardUser == null) return null;
        return (
            <>
                <div className="mx-2 my-1">
                    <Row className="mb-5">
                        <Col>
                            <div className="h5 font-weight-bold mb-0">First name</div>
                            <div>{dashBoardUser.first_name}</div>
                        </Col>
                        <Col>
                            <div className="h5 font-weight-bold mb-0">Last name</div>
                            <div>{dashBoardUser.last_name}</div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className="h5 font-weight-bold mb-0">Email</div>
                            <div>{dashBoardUser.email}</div>
                        </Col>
                        <Col>
                            <div className="h5 font-weight-bold mb-0">Type</div>
                            <div>{dashBoardUser.user_type}</div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    };

    renderCaptureCaptcha = () => {
        return (
            <>
                <Label className="text-info" for="captcha">
                    Confirm the total <strong>amount</strong> for the transaction to continue.
                </Label>
                <InputGroup className="mb-2">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                        name="captcha"
                        onChange={(e) => this.onInputChange(e.target.value, 'captcha')}
                        value={this.state.captcha}
                    />
                </InputGroup>
            </>
        );
    };

    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    renderUpdateAmount = () => {
        return (
            this.props.onAmountChange && (
                <>
                    <Label className="text-info" for="captcha">
                        Enter the new <strong>amount</strong> for the transaction.
                    </Label>
                    <InputGroup className="mb-2">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            name="amount"
                            onChange={(e) => this.props.onAmountChange!(e.target.value)}
                            value={this.props.updatedAmount}
                        />
                    </InputGroup>
                </>
            )
        );
    };

    render() {
        const { showModal, type, errorCode, isLoading, amount } = this.props;
        const spinner = <Spinner type="grow" color="black" size="sm" width={'100%'} />;
        const title = this.formatModalTitle(type);

        const typeCapture = type === 'capture';
        const typeUpdateAmount = type === 'update-amount';
        const typeUser = type === 'add' || 'delete';
        const typeRemoveUser = type === 'Remove User';
        const formattedAmount = selectors.format.formatAmount(amount);
        const typeElement = <span className="text-capitalize">{type}</span>;
        const header =
            type !== 'update-amount' ? (
                <>
                    {typeElement} {title}
                </>
            ) : (
                'Update Transaction Amount'
            );
        let message;
        switch (type) {
            case 'capture':
                message = (
                    <span>
                        This will <strong>{type}</strong> the transaction for the amount of{' '}
                        <strong>{formattedAmount}</strong>. Funds will be debited from the customer.
                    </span>
                );
                break;
            case 'update-amount':
                message = (
                    <span>
                        This will update the <strong>amount</strong> for the transaction.
                    </span>
                );
                break;
            case 'refund':
                message = (
                    <span>
                        Are you sure you want to <strong>{type}</strong> the transaction?
                        <br />
                        <br />
                        <strong>Note</strong>: There may be a small delay for the update to reflect on the
                        customer&apos;s account.
                    </span>
                );
                break;
            case 'Remove User':
                message = (
                    <span>
                        Are you sure you want to <strong>remove</strong> the admin user?
                    </span>
                );
                break;
            case 'unlink all':
                message = (
                    <span>
                        Are you sure you want to <strong>unlink</strong> all payment sources?
                    </span>
                );
                break;
            default:
                message = (
                    <span>
                        {' '}
                        Are you sure you want to <strong>{type}</strong> the {title.toLowerCase()}?
                    </span>
                );
        }

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <div className="p-3">
                    <ModalHeader className="font-weight-bold" toggle={this.props.toggle}>
                        {header}
                    </ModalHeader>
                    <ModalBody>
                        <div className="mt-3 mb-5 text-center">{message}</div>
                        {(typeUser || typeRemoveUser) && this.renderUserDetails()}
                        {typeCapture && this.renderCaptureCaptcha()}
                        {typeUpdateAmount && this.renderUpdateAmount()}
                        {errorCode && <Alert color="danger-light">{this.formatErrorMessage(errorCode)}</Alert>}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            disabled={
                                isLoading ||
                                errorCode === 'role_removal_error' ||
                                (typeCapture && this.state.captcha !== selectors.format.formatAmount(amount, true))
                            }
                            onClick={() => this.props.onSubmit()}
                        >
                            {!isLoading ? 'Confirm' : spinner}
                        </Button>
                        <Button
                            color="outline-secondary"
                            disabled={
                                isLoading ||
                                (typeCapture && this.state.captcha !== selectors.format.formatAmount(amount, true))
                            }
                            onClick={this.props.toggle}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }
}
