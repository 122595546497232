import { request, segmentListLimit } from './utils';
import { ListSegment } from './response';
import { stringify } from 'querystring';
import { uniqueId } from 'lodash';

export enum CHARGE_STATUS {
    CREATED = 'created',
    AUTHORIZED = 'authorized',
    CAPTURED = 'captured',
    CANCELED = 'canceled',
    ATTEMPTING_COLLECTION = 'attempting_collection',
    STOPPED_COLLECTION = 'stopped_collection',
    CAPTURE_FAILED = 'capture_failed',
    DISPUTED = 'disputed',
    REFUND_PENDING = 'refund_pending',
    REFUNDED = 'refunded',
}

export interface EventModel {
    created_at: string;
    description: string;
}

export enum ReturnReason {
    InsufficientFunds = 'insufficient_funds',
    Stopped = 'stopped',
    Unauthorized = 'unauthorized',
}

export interface TipModel {
    id: string;
    charge_id: string;
    amount: number;
    fee: number;
    status: CHARGE_STATUS;
    created_at: string;
    authorized_at: string | null;
    captured_at: string | null;
    beneficiary_name: string;
    details: {
        drawer_id: string;
        terminal_id: string;
    };
}

export interface ReturnModel {
    charge_id: string;
    return_reason: ReturnReason;
    charge_status: string;
    events: EventModel[];
}

export interface ChargeModel {
    id: string;
    type: 'bank_debit' | 'bank_debit_cnp';
    status: CHARGE_STATUS;
    merchant_convenience_fee: number;
    fee: number;
    amount: number;
    currency: string;
    customer_id: string;
    payment_source_id: string;
    external_id: string | null;
    created_at: string;
    authorized_at: string | null;
    captured_at: string | null;
    tip_model: TipModel | null;
    statement_reference: string;
    payment_processing_fee: number | null;
}

export interface UpdateAmountPosted {
    amount: string;
}

export interface AggregatedChargesModel {
    gross: string;
    net: string;
    new_customers: string;
    succesful_payments: string;
    failed_payments: string;
    customer_average: string;
    disputes: string;
    captured_charges: string;
    captured_fees: string;
}

const listChargesCancelTokenId = uniqueId();
export async function listCharges(
    options: {
        skip?: number;
        limit?: number;
        customerId?: string;
        status?: string[];
        from?: string;
        to?: string;
        search?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<ChargeModel>>({
        method: 'GET',
        url: `/api/charges?${query}`,
        cancelTokenId: listChargesCancelTokenId,
    });
    return res.result;
}

export async function getCharge(chargeId: string) {
    const res = await request<ChargeModel>({
        method: 'GET',
        url: `/api/charges/${chargeId}`,
    });
    return res.result;
}

export async function getReturn(chargeId: string) {
    const res = await request<ReturnModel>({
        method: 'GET',
        url: `/api/charges/${chargeId}/returns`,
    });
    return res.result;
}

export async function getTipForCharge(chargeId: string) {
    const res = await request<TipModel>({
        method: 'GET',
        url: `/api/charges/${chargeId}/tip`,
    });
    return res.result;
}

export async function getTip(tipId: string) {
    const res = await request<TipModel>({
        method: 'GET',
        url: `/api/tips/${tipId}`,
    });
    return res.result;
}

export async function refundCharge(chargeId: string) {
    const res = await request<ChargeModel>({
        method: 'POST',
        url: `/api/charges/${chargeId}/refund`,
    });
    return res.result;
}

export async function captureCharge(chargeId: string) {
    const res = await request<ChargeModel>({
        method: 'POST',
        url: `/api/charges/${chargeId}/capture`,
    });
    return res.result;
}

export async function cancelCharge(chargeId: string) {
    const res = await request<ChargeModel>({
        method: 'POST',
        url: `/api/charges/${chargeId}/cancel`,
    });
    return res.result;
}

export async function updateChargeAmount(chargeId: string, data: UpdateAmountPosted) {
    const res = await request<ChargeModel>({
        method: 'PATCH',
        url: `/api/charges/${chargeId}`,
        data,
    });
    return res.result;
}
