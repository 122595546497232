import * as React from 'react';
import { Modal, ModalBody, Row } from 'reactstrap';
import SHQRCode from '../SHQRCode';

type Props = {
    showModal: boolean;
    toggle: () => void;
    url: string | null;
};

export default class PaylinkQRModal extends React.Component<Props> {
    renderSuccess = () => {
        const { url } = this.props;
        return (
            <>
                {url && (
                    <>
                        <Row className="justify-content-center mb-2">
                            <SHQRCode url={url} />
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <div>
                                Link: <a href={url}>{url}</a>
                            </div>
                        </Row>
                    </>
                )}
            </>
        );
    };

    render() {
        const { showModal, url } = this.props;

        if (!url) {
            return null;
        }

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalBody>
                    <div className="my-3 text-center">{this.renderSuccess()}</div>
                </ModalBody>
            </Modal>
        );
    }
}
