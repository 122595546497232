import { ChargeState } from '../types';
import { CLEAN_CHARGES, UPSERT_CHARGE, SET_CHARGES, SET_CHARGE_TIP, SWITCH_CONTEXT } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { unionBy } from 'lodash';
import { ChargeModel, segmentListLimit } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ChargeState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
    aggregated: {
        captured_charges: '',
        captured_fees: '',
        customer_average: '',
        disputes: '',
        failed_payments: '',
        gross: '',
        net: '',
        new_customers: '',
        succesful_payments: '',
    },
};

export const chargeReducer: Reducer<ChargeState, ApplicationAction> = (state = unloadedState, action): ChargeState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_CHARGES:
            return {
                ...state,
                list: action.payload.list,
            };
        case UPSERT_CHARGE:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: unionBy<ChargeModel>([action.payload.charge], state.list.items, (el) => el.id),
                },
            };
        case CLEAN_CHARGES:
            return {
                ...state,
                list: unloadedState.list,
            };
        case SET_CHARGE_TIP:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: state.list.items.map((el) => {
                        if (el.id === action.payload.tip_model.charge_id) {
                            el.tip_model = action.payload.tip_model;
                        }
                        return el;
                    }),
                },
            };
        default:
            return state;
    }
};
