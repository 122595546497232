import * as api from '../../apis';
import { CLEAN_PAYMENT_SOURCE, SET_PAYMENT_SOURCE, SET_PAYMENT_SOURCES, UPDATE_PAYMENT_SOURCE } from '../constants';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';
import { PaymentSourceModel, ResponseError } from '../../apis';
import { ListSegment } from '../../apis';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetPaymentSourceAction {
    type: SET_PAYMENT_SOURCE;
    payload: {
        data: PaymentSourceModel;
    };
}

export const setPaymentSourceAction = (data: PaymentSourceModel): SetPaymentSourceAction => ({
    type: SET_PAYMENT_SOURCE,
    payload: {
        data,
    },
});

export interface SetPaymentSourcesAction {
    type: SET_PAYMENT_SOURCES;
    payload: {
        list: ListSegment<PaymentSourceModel>;
    };
}

export const setPaymentSourcesAction = (list: ListSegment<PaymentSourceModel>): SetPaymentSourcesAction => ({
    type: SET_PAYMENT_SOURCES,
    payload: {
        list,
    },
});

export interface CleanPaymentSourceAction {
    type: CLEAN_PAYMENT_SOURCE;
}

export const cleanPaymentSourceAction = (): CleanPaymentSourceAction => ({
    type: CLEAN_PAYMENT_SOURCE,
});

export interface UpdatePaymentSourceAction {
    type: UPDATE_PAYMENT_SOURCE;
    payload: {
        data: PaymentSourceModel;
    };
}

export const updatePaymentSourceAction = (data: PaymentSourceModel): UpdatePaymentSourceAction => ({
    type: UPDATE_PAYMENT_SOURCE,
    payload: {
        data,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type PaymentSourcesAction =
    | SetPaymentSourceAction
    | CleanPaymentSourceAction
    | UpdatePaymentSourceAction
    | SetPaymentSourcesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface FetchPaymentSourcesActionOptions {
    customerId?: string;
}

export function fetchPaymentSourceAction(paymentSourceId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_payment_source',
            call: () => api.getPaymentSource(paymentSourceId),
            dispatch,
            onSuccess: (result) => {
                dispatch(setPaymentSourceAction(result));
            },
        });
    };
}

export function fetchPaymentSourcesAction(
    options: FetchPaymentSourcesActionOptions,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_payment_sources', true));
        try {
            const result = await api.listPaymentSources(options);
            await dispatch(setPaymentSourcesAction(result));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_payment_sources', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('fetch_payment_sources', false));
        }
    };
}

export function deactivatePaymentSourceAction(paymentSource: PaymentSourceModel): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        await dispatch(setActionRequestingAction('update_payment_source', true));
        try {
            const paymentSources = getState().paymentSources.list.items.filter(
                (el) => el.unique_hash === paymentSource.unique_hash,
            );

            for (const el of paymentSources) {
                const result = await api.deactivatePaymentSource(el.id);
                await dispatch(updatePaymentSourceAction(result));
            }
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('update_payment_source', e));
            }
            throw e;
        } finally {
            await dispatch(setActionRequestingAction('update_payment_source', false));
        }
    };
}

export function deactivateAllPaymentSourcesAction(customerId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('deactivate_all_payment_sources', true));
        try {
            const result = await api.deactivateAllPaymentSources(customerId);
            for (const source of result) {
                source.active = false;
                await dispatch(updatePaymentSourceAction(source));
            }
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('deactivate_all_payment_sources', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('deactivate_all_payment_sources', false));
        }
    };
}
