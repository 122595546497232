import * as React from 'react';
import {
    CreatePayLinkRequest,
    MerchantModel,
    PayLinkDocumentModel,
    PayLinkModel,
    StrongholdDirectRequestModel,
} from '../../apis';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import {
    cleanPayLinkAction,
    createPayLinkAction,
    fetchProcessingFeeAmountAction,
    resetActionStatusAction,
} from '../../store/actions';
import { Button, Col, Row } from 'reactstrap';
import Loader from '../../components/Loader';
import { isActionRequesting } from '../../store/selectors/global';
import PlaceholderLoader from '../../components/PlaceholderLoader';

interface OwnProps {
    strongholdDirectRequestModel: StrongholdDirectRequestModel;
    setStep: (stepNumber: 1 | 2 | 3 | 4) => void;
}

interface StateProps {
    payLink: PayLinkModel | null;
    isRequesting: boolean;
    merchant: MerchantModel | null;
    processingFee: number;
    isLoading: boolean;
}

interface DispatchProps {
    createPayLinkAction: typeof createPayLinkAction;
    cleanPayLinkAction: typeof cleanPayLinkAction;
    resetActionStatusAction: typeof resetActionStatusAction;
    fetchProcessingFeeAmountAction: typeof fetchProcessingFeeAmountAction;
}

type Props = OwnProps & StateProps & DispatchProps;

class CreatePaylink3 extends React.Component<Props> {
    async componentDidMount() {
        await this.props.fetchProcessingFeeAmountAction(this.props.strongholdDirectRequestModel.amount || 0);
    }

    getLinkMethod = (method: string | null) => {
        let linkMethod = '';
        switch (method) {
            case 'qr':
                linkMethod = 'QR Code';
                break;
            case 'sms':
                linkMethod = 'SMS Message';
                break;
            case 'email':
                linkMethod = 'Email';
                break;
        }
        return linkMethod;
    };

    onSubmit = async () => {
        const { merchant, strongholdDirectRequestModel } = this.props;
        const { amount, externalId, fileList, successScreenType } = strongholdDirectRequestModel;
        await this.props.resetActionStatusAction('create_pay_link');
        const totalAmount = amount ? amount : 0;

        if (merchant && strongholdDirectRequestModel.customer && successScreenType) {
            const customer = strongholdDirectRequestModel.customer;
            const createPayLinkRequest: CreatePayLinkRequest = {
                type: 'checkout',
                customer_id: customer.id,
                customer: {
                    email: customer.individual.email,
                    first_name: customer.individual.first_name,
                    last_name: customer.individual.last_name,
                },
                merchant: {
                    display_name: merchant.display_name,
                    communication_safe_name: merchant.communication_safe_name,
                },
                tip: {
                    beneficiary_name: merchant.display_name,
                },
                order: {
                    total_amount: totalAmount,
                },
                stand_alone: true,
            };

            if (externalId) {
                createPayLinkRequest.charge = {
                    external_id: externalId,
                };
            }

            let formData = null;
            if (fileList) {
                formData = new FormData();
                formData.append('customerId', customer.id);
                for (let x = 0; x < fileList.length; x++) {
                    formData.append('files', fileList[x]);
                }
            }

            await this.props.createPayLinkAction(createPayLinkRequest, successScreenType, formData);
            this.props.setStep(4);
        }
    };

    renderFileList = () => {
        const { strongholdDirectRequestModel } = this.props;
        const { fileList } = strongholdDirectRequestModel;
        if (fileList) {
            const documents: PayLinkDocumentModel[] = [];
            Array.from(fileList).forEach((file) => {
                const document = {
                    url: '',
                    file_size: file.size,
                    file_name: file.name,
                } as PayLinkDocumentModel;
                documents.push(document);
            });
            return (
                <div className="mb-5">
                    <div className="font-weight-bold h5">Documents</div>
                    {documents.map((document, index) => (
                        <Row key={index}>
                            <Col xs={6}>{document.file_name}</Col>
                            <Col className="d-flex justify-content-end">{`${selectors.format.formatKB(
                                document.file_size,
                            )} KB`}</Col>
                        </Row>
                    ))}
                </div>
            );
        }
    };

    render() {
        const { strongholdDirectRequestModel, merchant, setStep, isRequesting, processingFee, isLoading } = this.props;
        const { amount, externalId, fileList, successScreenType, customer } = strongholdDirectRequestModel;
        const convenienceFee = merchant ? parseInt(merchant.convenience_fee) : 0;
        return (
            <div>
                {customer && successScreenType && amount && (
                    <div className="container  mb-5">
                        <Row>
                            <Col>
                                <div className="h5 font-weight-bold small">Customer</div>
                                <div>{`${customer.individual.first_name} ${customer.individual.last_name}`}</div>
                                <div>
                                    {selectors.format.formatMobilePhoneNumberReadable(customer.individual.mobile)}
                                </div>
                                <div>{customer.individual.email}</div>
                            </Col>
                        </Row>
                        <hr />
                        <div className="h5 font-weight-bold small">Paylink</div>
                        <Row>
                            <Col xs={6}>Order ID</Col>
                            <Col className="d-flex justify-content-end">{externalId}</Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Paylink type</Col>
                            <Col className="d-flex justify-content-end">{this.getLinkMethod(successScreenType)}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={6}>Amount</Col>
                            <Col className="text-primary d-flex justify-content-end">
                                {selectors.format.formatAmount(amount)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Merchant Convenience Fee</Col>
                            <Col className="text-primary d-flex justify-content-end">
                                {selectors.format.formatAmount(convenienceFee)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Payment Processing Fee</Col>
                            <Col className="text-primary d-flex justify-content-end">
                                <PlaceholderLoader width="10%" isLoading={isLoading}>
                                    {selectors.format.formatAmount(processingFee)}
                                </PlaceholderLoader>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Total</Col>
                            <Col className="text-primary d-flex justify-content-end">
                                <PlaceholderLoader width="10%" isLoading={isLoading}>
                                    {selectors.format.formatAmount(convenienceFee + amount + processingFee)}
                                </PlaceholderLoader>
                            </Col>
                        </Row>
                        {fileList && (
                            <div className="pb-5">
                                <hr />
                                <Row>
                                    <Col>{this.renderFileList()}</Col>
                                </Row>
                            </div>
                        )}
                    </div>
                )}
                <div className="pt-5">
                    <div className="py-3 mt-5 border-top-gray fixed-bottom bg-light">
                        <Row>
                            <Col xs={{ offset: 2, size: 4 }} md={{ offset: 4, size: 2 }}>
                                <Button
                                    color="primary"
                                    className="mr-2  w-100"
                                    data-sh="confirm-paylink-button"
                                    size="lg"
                                    disabled={isRequesting}
                                    onClick={() => this.onSubmit()}
                                >
                                    {isRequesting ? <Loader className="px-3" color={'white'} size="sm" /> : `Confirm`}
                                </Button>
                            </Col>
                            <Col xs={{ size: 4 }} md={{ size: 2 }}>
                                <Button
                                    color="outline-secondary"
                                    className=" w-100"
                                    data-sh="back-paylink-button"
                                    size="lg"
                                    disabled={isRequesting}
                                    onClick={() => setStep(2)}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        payLink: state.payLinks.selected,
        merchant: state.merchant.selected,
        isRequesting: selectors.global.isActionRequesting(state.global.actions, 'create_pay_link'),
        processingFee: state.processingFees.amount || 0,
        isLoading: isActionRequesting(state.global.actions, 'fetch_processing_fee_amount'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createPayLinkAction,
            cleanPayLinkAction,
            resetActionStatusAction,
            fetchProcessingFeeAmountAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaylink3);
