import * as React from 'react';
import Layout from '../components/Layout';
import { Alert } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import LinkContact from '../components/LinkContact';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationState } from '../store';

interface StateProps {
    hasMerchants: boolean;
}

type Props = StateProps;

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    hasMerchants: state.merchant.arr && state.merchant.arr.length > 0,
});

class RestrictedAccess extends React.Component<Props> {
    render() {
        const hasMerchants = this.props.hasMerchants ?? false;
        return (
            <Layout hideSandboxBanner={true} fromRestricted={true}>
                <div className="mw-lg mt-5">
                    <Alert color="warning-light">
                        <h4 className="alert-heading">
                            <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} /> No Access
                        </h4>
                        <hr />
                        <p className="mb-0">
                            {hasMerchants
                                ? `Your account does not currently have access to this merchant. Click on the Select Merchant text to switch merchants.`
                                : `Your account does not currently have access to the dashboard.`}
                        </p>
                        <p className="mb-0">
                            Please contact your administrator for assistance, or email us at{' '}
                            <LinkContact type="happiness" />.
                        </p>
                    </Alert>
                </div>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedAccess);
