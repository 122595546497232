import * as React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { deactivatePaymentSourceAction } from '../../store/actions';
import { PaymentSourceModel, ResponseError } from '../../apis';
import selectors from '../../store/selectors';
import ConfirmationModal from '../modals/ConfirmationModal';

interface StateProps {
    errorMessage: ResponseError | null;
}

interface OwnProps extends ButtonProps {
    paymentSource: PaymentSourceModel;
}

interface DispatchProps {
    deactivatePaymentSourceAction: typeof deactivatePaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;
export type PaymentSourceActionType = 'unlink' | 'link' | null;

interface State {
    ready: boolean;
    showModal: boolean;
    action: PaymentSourceActionType;
}

class PaymentSourceActions extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
    };

    onActionClick = async (action: PaymentSourceActionType) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    onSubmit = async () => {
        const { paymentSource } = this.props;
        this.setState({ ready: false });
        if (this.state.action === null) return;
        try {
            await this.props.deactivatePaymentSourceAction(paymentSource);
            this.toggleModal();
        } catch {
            this.setState({ ready: true });
            // Error
        }
        this.setState({ ready: true });
    };

    render() {
        const { paymentSource, errorMessage } = this.props;

        const { showModal, action } = this.state;
        const isLoading = !this.state.ready;

        return (
            <>
                <ConfirmationModal
                    amount={0}
                    isLoading={isLoading}
                    errorCode={errorMessage && errorMessage.code}
                    type={action}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    onSubmit={this.onSubmit}
                />
                {paymentSource.active ? (
                    <Button
                        className="text-capitalize"
                        color="primary w-75"
                        size="sm"
                        onClick={() => this.onActionClick('unlink')}
                    >
                        Unlink
                    </Button>
                ) : (
                    <Button size="sm" className="text-capitalize w-75" color="outline-secondary" disabled={true}>
                        Unlinked
                    </Button>
                )}
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorMessage: selectors.global.getResponseError(state.global.actions, 'update_payment_source'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivatePaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSourceActions);
