import * as React from 'react';
import { Button, ButtonProps, Spinner } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { DashboardUserPosted, DashboardUserUpdated, DashboardUserWithId, ResponseError } from '../../apis';
import selectors from '../../store/selectors';
import ConfirmationModal from '../modals/ConfirmationModal';
import { addDashboardUserAction, updateDashboardUserRoleAction, removeDashboardUserAction } from '../../store/actions';
import EditUserRolesModal from '../modals/EditUserRolesModal';
import { RolesType } from '../../store/types';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type UsersActionType = 'add' | 'Remove User' | 'update-roles' | null;

interface StateProps {
    errorMessage: ResponseError | null;
}

interface OwnProps extends ButtonProps {
    dashBoardUser?: DashboardUserPosted;
    dashBoardUserId?: string;
    disabled?: boolean;
    action: UsersActionType;
    size?: string;
    onSubmitCallback?: () => void;
    userType?: string;
}

interface DispatchProps {
    addDashboardUserAction: typeof addDashboardUserAction;
    updateDashboardUserRoleAction: typeof updateDashboardUserRoleAction;
    removeDashboardUserAction: typeof removeDashboardUserAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    ready: boolean;
    showModal: boolean;
    action: UsersActionType;
    roles: RolesType | null;
}

class UsersActions extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
        roles: null,
    };

    onActionClick = async (action: UsersActionType) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    componentDidMount = () => {
        const { dashBoardUser } = this.props;
        dashBoardUser &&
            dashBoardUser.user_type &&
            this.setState({
                roles: selectors.user.formatRoleTypeFromString(
                    selectors.format.formatUnderscored(dashBoardUser.user_type),
                ),
            });
    };

    onSubmit = async () => {
        const { dashBoardUser, dashBoardUserId } = this.props;
        this.setState({ ready: false });
        if (this.state.action === null) return;
        try {
            switch (this.state.action) {
                case 'add':
                    if (dashBoardUser) {
                        await this.props.addDashboardUserAction(dashBoardUser);
                        this.toggleModal();
                        if (this.props.onSubmitCallback) {
                            this.props.onSubmitCallback();
                        }
                    }
                    break;
                case 'Remove User':
                    if (dashBoardUser) {
                        const user = {
                            email: dashBoardUser.email,
                            first_name: dashBoardUser.first_name,
                            last_name: dashBoardUser.last_name,
                            owner_id: dashBoardUserId,
                        } as DashboardUserWithId;
                        await this.props.removeDashboardUserAction(user);
                        this.toggleModal();
                    }
                    break;
                default:
                    break;
            }
        } catch {
            this.setState({ ready: true });
            // Error
        }
        this.setState({ ready: true });
    };

    formatAction = (action: UsersActionType) => {
        switch (action) {
            case 'add':
                return 'Add user';
            default:
                return action;
        }
    };

    onEditRoleSubmit = async () => {
        const { dashBoardUser, dashBoardUserId } = this.props;
        const { roles } = this.state;
        if (dashBoardUser) {
            this.setState({ ready: false });
            const user = {
                email: dashBoardUser.email,
                first_name: dashBoardUser.first_name,
                last_name: dashBoardUser.last_name,
                roles: roles,
                owner_id: dashBoardUserId,
            } as DashboardUserUpdated;
            await this.props.updateDashboardUserRoleAction(user);
            this.toggleModal();
            this.setState({ ready: true });
        }
    };

    onRoleChange = (roles: RolesType) => {
        this.setState({ roles });
    };

    render() {
        const { errorMessage, action, disabled, dashBoardUser, size, userType, roleRemovalErrorMessage } = this.props;
        const { showModal, ready } = this.state;
        const spinner = <Spinner className="mr-2" type="grow" color="black" size="sm" width={'100%'} />;
        const isLoading = !ready;

        return (
            <>
                {this.props.action === 'update-roles' && dashBoardUser ? (
                    <EditUserRolesModal
                        error={errorMessage}
                        showModal={showModal}
                        toggle={this.toggleModal}
                        onSubmit={this.onEditRoleSubmit}
                        onRoleChange={this.onRoleChange}
                        isLoading={isLoading}
                        roleType={this.state.roles}
                    />
                ) : (
                    <ConfirmationModal
                        amount={0}
                        isLoading={isLoading}
                        errorCode={
                            (errorMessage && errorMessage.code) ||
                            (roleRemovalErrorMessage && roleRemovalErrorMessage.code)
                        }
                        dashBoardUser={dashBoardUser}
                        type={action}
                        showModal={showModal}
                        toggle={this.toggleModal}
                        onSubmit={this.onSubmit}
                    />
                )}
                <div>
                    {userType && (
                        <span className="text-capitalize badge-gray-small py-2 text-dark">
                            {selectors.format.formatUnderscored(userType)}
                        </span>
                    )}
                    <Button
                        className="font-size-lg"
                        color={`${action === 'add' ? 'primary' : 'link'}`}
                        disabled={disabled}
                        size={size}
                        onClick={() => this.onActionClick(action)}
                    >
                        {isLoading && spinner}
                        {action === 'update-roles' && userType ? (
                            <FontAwesomeIcon icon={faPencilAlt} fixedWidth className="mr-2" />
                        ) : (
                            this.formatAction(action)
                        )}
                    </Button>
                </div>
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorMessage: selectors.global.getResponseError(state.global.actions, 'add_dashboard_user'),
    roleRemovalErrorMessage: selectors.global.getResponseError(state.global.actions, 'remove_dashboard_user'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addDashboardUserAction,
            updateDashboardUserRoleAction,
            removeDashboardUserAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UsersActions);
