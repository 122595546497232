import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

export interface Props extends ReactDatePickerProps {
    label: string | JSX.Element;
}

// Need to be in a proper class, it throw a ref exception if not
class CustomInput extends React.Component<{
    label: string | JSX.Element;
}> {
    render() {
        const { label, ...props } = this.props;
        return (
            <InputGroup>
                <Input {...props} />
                <InputGroupAddon addonType="append">
                    <InputGroupText>{label}</InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        );
    }
}

export default class DatePicker extends React.Component<Props> {
    render() {
        const { onChange, selected, label, ...p } = this.props;

        return (
            <ReactDatePicker
                selected={selected}
                onChange={(date, e) => onChange(date, e)}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                customInput={<CustomInput label={label} />}
                wrapperClassName="w-100"
                {...p}
            />
        );
    }
}
