import * as React from 'react';
import { PAY_LINK_STATUS } from '../../apis';

export interface Props {
    status: PAY_LINK_STATUS;
    size: 'sm' | 'lg';
}

export default function PayLinkStatusBadge(props: Props) {
    const classes = ['text-capitalize', 'id-badge', 'py-1', 'text-dark'];
    if (props.size === 'sm') {
        classes.push('text-small');
    }
    let formattedStatus;
    formattedStatus = props.status;
    if (props.status === PAY_LINK_STATUS.USED) {
        formattedStatus = 'paid';
    }

    return <span className={classes.join(' ')}>{formattedStatus.replace('_', ' ')}</span>;
}
