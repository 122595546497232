import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchPromotionsAction } from '../store/actions';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import { RouteComponentProps, withRouter } from 'react-router';
import selectors from '../store/selectors';
import { history } from '../index';
import { PromotionModel } from '../apis';
import PromotionsTable from '../components/promotions/PromotionsTable';

interface StateProps {
    promotions: PromotionModel[];
    isLoading: boolean;
}

interface DispatchProps {
    fetchPromotionsAction: typeof fetchPromotionsAction;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class PromotionsDashboard extends React.Component<Props> {
    componentDidMount = async () => {
        this.fetchPromotions();
    };

    onRowClicked = (promotion: PromotionModel) => {
        history.push(selectors.global.promotionRoute(promotion.id));
    };

    fetchPromotions = (skip?: number) => {
        this.props.fetchPromotionsAction(skip);
    };

    render() {
        return (
            <Layout>
                <div className="mb-4">
                    <h1 className="text-capitalize">Promotions</h1>

                    <PromotionsTable
                        onRowClick={this.onRowClicked}
                        onGoTo={(page, skip) => this.fetchPromotions(skip)}
                        isLoading={this.props.isLoading}
                    />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    promotions: state.promotions.list.items,
    isLoading: selectors.global.isActionRequesting(state.global.actions, 'fetch_promotions'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPromotionsAction: fetchPromotionsAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromotionsDashboard));
