import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    fetchChargeAction,
    fetchTipAction,
    fetchReturnAction,
    fetchCustomerAction,
    fetchPaymentSourceAction,
    cleanPaymentSourceAction,
    cleanCustomersAction,
    fetchCreditsAction,
    fetchPromotionsAction,
} from '../store/actions';
import { Row } from 'reactstrap';
import Layout, { LayoutBreadcrumbItem } from '../components/Layout';
import {
    CHARGE_STATUS,
    ChargeModel,
    CreditModel,
    CustomerModel,
    CustomizationType,
    PaymentSourceModel,
    PromotionModel,
    ReturnModel,
} from '../apis';
import { ApplicationState } from '../store';
import selectors from '../store/selectors';
import { RouteComponentProps } from 'react-router';
import { ROUTE } from '../config';
import { isActionRequesting } from '../store/selectors/global';
import ChargeStatusBadge from '../components/charge/ChargeStatusBadge';
import ChargeActions from '../components/charge/ChargeActions';
import Col from 'reactstrap/lib/Col';
import CustomerDetails from '../components/customer/CustomerDetails';
import PlaceholderLoader from '../components/PlaceholderLoader';
import Tooltip from '../components/Tooltip';
import PaymentSourceDetails from '../components/paymentSource/PaymentSourceDetails';
import ReturnDetails from '../components/charge/ReturnDetails';
import TipDetails from '../components/charge/TipDetails';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    returns: ReturnModel | null;
    charge: ChargeModel | null;
    customer: CustomerModel | null;
    paymentSource: PaymentSourceModel | null;
    isLoading: boolean;
    customCustomerName: string | null;
    timezone: string | null;
    credit: CreditModel | null;
    promotion: PromotionModel | null;
}

type OwnProps = RouteComponentProps<{
    transactionId: string;
}>;

interface DispatchProps {
    fetchChargeAction: typeof fetchChargeAction;
    fetchReturnAction: typeof fetchReturnAction;
    fetchTipAction: typeof fetchTipAction;
    fetchCustomerAction: typeof fetchCustomerAction;
    fetchPaymentSourceAction: typeof fetchPaymentSourceAction;
    cleanPaymentSourceAction: typeof cleanPaymentSourceAction;
    cleanCustomersAction: typeof cleanCustomersAction;
    fetchCreditsAction: typeof fetchCreditsAction;
    fetchPromotionsAction: typeof fetchPromotionsAction;
}

type Props = StateProps & DispatchProps & OwnProps;

export function getChargeStatusDescription(status?: CHARGE_STATUS): string {
    if (!status) return '';
    switch (status) {
        case CHARGE_STATUS.CREATED:
            return 'If using the checkout flow: Customer has clicked on the pay button, but the charge could not be authorized (usually due to an insufficient balance). If using the API directly, the integrator has created a charge.';
        case CHARGE_STATUS.AUTHORIZED:
            return 'Customer authorization for the created charge has been obtained and the available balance was checked at authorization time.';
        case CHARGE_STATUS.CAPTURED:
            return 'Customer authorization and bank details have been successfully captured and the available balance was checked at capture time. Captured charges can fail if the customer has insufficient funds and attempts to collect the funds are unsuccessful.';
        case CHARGE_STATUS.CANCELED:
            return 'The transaction has been created and the customer has linked a bank account, but the charge fails or is canceled and is never authorized or captured.';
        case CHARGE_STATUS.ATTEMPTING_COLLECTION:
            return 'Stronghold has already attempted to collect funds; however, the attempt was unsuccessful due to insufficient balances at the time of debit or other bank restrictions.';
        case CHARGE_STATUS.STOPPED_COLLECTION:
            return "Results when the Stronghold support team stops the collection at the merchant's request. A merchant might decide to stop collection if they collected the funds due outside of Stronghold and thus want to prevent double collection.";
        case CHARGE_STATUS.CAPTURE_FAILED:
            return 'Customer lacks sufficient funds for processing, and Stronghold’s attempts to collect funds have failed three times.';
        case CHARGE_STATUS.DISPUTED:
            return 'Customer has claimed that the charge is unauthorized.';
        case CHARGE_STATUS.REFUND_PENDING:
            return 'Merchant has initiated a refund to the customer for this order.';
        case CHARGE_STATUS.REFUNDED:
            return 'Refund has been settled to the customer.';
        default:
            return '';
    }
}

class Transaction extends React.Component<Props> {
    componentDidMount = async () => {
        // Enable loading placeholders again by cleaning
        await this.props.cleanCustomersAction();
        await this.props.cleanPaymentSourceAction();

        try {
            await this.props.fetchChargeAction(this.props.match.params.transactionId);
        } catch {
            // Error happen, redirect
            this.props.history.push(ROUTE.TRANSACTIONS);
            return;
        }

        if (this.props.charge) {
            this.props.fetchCreditsAction({ originatedFromChargeId: [this.props.charge.id] });
            this.props.fetchPromotionsAction();
            this.props.fetchTipAction(this.props.charge.id);
            this.props.fetchReturnAction(this.props.charge.id);
            this.props.fetchCustomerAction(this.props.charge.customer_id);
            this.props.fetchPaymentSourceAction(this.props.charge.payment_source_id);
        }
    };

    render() {
        const { charge, customer, paymentSource, customCustomerName, timezone, returns, isLoading, credit, promotion } =
            this.props;

        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.TRANSACTIONS,
                title: 'Transactions',
            },
        ];

        if (charge) {
            layoutBreadcrumbItems.push({
                href: selectors.global.transactionRoute(charge.id),
                title: charge.id.replace('charge_', ''),
                isId: true,
            });
        }

        const redeemed = credit?.redeemed ? ' (Redeemed)' : '';

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div className="mb-5">
                    <div className="d-flex mb-4">
                        <div className="col-8 px-0">
                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                <h1>{charge && `${charge.id}`}</h1>
                            </PlaceholderLoader>
                        </div>
                    </div>

                    <div className="border-bottom-gray mb-4">
                        <div className="d-flex mb-4">
                            <h2>Transaction details</h2>
                            {charge && charge.external_id ? (
                                <div className="ml-auto mt-auto">
                                    <Tooltip className="d-flex align-items-center" tooltip="External ID">
                                        <span className="id-badge">{charge.external_id}</span>
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <Row className="mb-3">
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Amount</h3>
                                <h2 className="text-success">
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        {charge && selectors.format.formatAmount(charge.amount)}
                                        <div className="h-100">
                                            {charge && <ChargeActions charge={charge} actionType={'update-amount'} />}
                                        </div>
                                    </PlaceholderLoader>
                                </h2>
                            </div>
                        </Col>
                        {charge && (
                            <Col md={4}>
                                <div className="detail-card">
                                    <div className={`${charge.fee === 0 && 'opacity-1'}`}>
                                        <h3>Fee</h3>
                                        <div className="m-0">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {charge.fee > 0 ? selectors.format.formatAmount(charge.fee) : '—'}
                                            </PlaceholderLoader>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )}
                        {charge && (
                            <Col md={4}>
                                <div className="detail-card">
                                    <div className={`${charge.merchant_convenience_fee === 0 && 'opacity-1'}`}>
                                        <h3>Convenience fee</h3>
                                        <div className="m-0 ">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {charge.merchant_convenience_fee > 0
                                                    ? selectors.format.formatAmount(charge.merchant_convenience_fee)
                                                    : '—'}
                                            </PlaceholderLoader>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="mb-3">
                        {charge && (
                            <Col md={4}>
                                <div className="detail-card">
                                    <div className={`${charge.payment_processing_fee === 0 && 'opacity-1'}`}>
                                        <h3>Processing Fee</h3>
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {charge.payment_processing_fee && charge.payment_processing_fee > 0
                                                ? selectors.format.formatAmount(charge.payment_processing_fee)
                                                : '—'}
                                        </PlaceholderLoader>
                                    </div>
                                </div>
                            </Col>
                        )}
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Status</h3>
                                <PlaceholderLoader width="100%" isLoading={isLoading}>
                                    <Tooltip
                                        className="d-flex align-items-center"
                                        tooltip={getChargeStatusDescription(charge?.status)}
                                    >
                                        {charge && <ChargeStatusBadge status={charge.status} />}
                                    </Tooltip>
                                </PlaceholderLoader>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${charge && !charge.statement_reference && 'opacity-1'}`}>
                                    <h3>Statement reference</h3>
                                    <div className="m-0 ">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {(charge && charge.statement_reference) || '—'}
                                        </PlaceholderLoader>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            {charge && (
                                <div className="detail-card">
                                    <h3>Action</h3>
                                    <PlaceholderLoader width="100%" isLoading={isLoading}>
                                        {charge && <ChargeActions charge={charge} />}
                                    </PlaceholderLoader>
                                </div>
                            )}
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Created at</h3>
                                <div className="m-0 ">
                                    {charge && (
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {selectors.format.formatDateTimeSecondsWithYear(
                                                charge.created_at,
                                                timezone,
                                            ) || '—'}
                                        </PlaceholderLoader>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${charge && !charge.authorized_at && 'opacity-1'}`}>
                                    <h3>Authorized at</h3>
                                    <div className="m-0 ">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {charge && (
                                                <>
                                                    {selectors.format.formatDateTimeSecondsWithYear(
                                                        charge.authorized_at,
                                                        timezone,
                                                    ) || '—'}
                                                </>
                                            )}
                                        </PlaceholderLoader>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${charge && !charge.captured_at && 'opacity-1'}`}>
                                    <h3>Captured at</h3>
                                    <div className="m-0 ">
                                        <PlaceholderLoader width="100%" isLoading={isLoading}>
                                            {charge && (
                                                <>
                                                    {selectors.format.formatDateTimeSecondsWithYear(
                                                        charge.captured_at,
                                                        timezone,
                                                    ) || '—'}
                                                </>
                                            )}
                                        </PlaceholderLoader>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${!this.props.promotion && 'opacity-1'}`}>
                                    <h3>Promotion</h3>
                                    <div className="m-0 ">
                                        <Tooltip tooltip="The name of the Promotion associated with the Credit created from this transaction.">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {(promotion && promotion.name) || '—'}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${!this.props.credit && 'opacity-1'}`}>
                                    <h3>Promotional Credit</h3>
                                    <div className="m-0 ">
                                        <Tooltip tooltip="The value of the Credit created from this transaction and its redemption status. Note: the Credit may have been redeemed with a different transaction.">
                                            <PlaceholderLoader width="100%" isLoading={isLoading}>
                                                {(credit && selectors.format.formatAmount(credit.amount)) || '—'}
                                                {redeemed}
                                            </PlaceholderLoader>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex">
                        <div className="ml-auto text-right">
                            <h5>Need help with this transaction?</h5>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://stronghold.co/happiness-support-contact"
                                className=" text-primary view-link"
                            >
                                <span className="mr-2 font-weight-bolder">Contact Support</span>
                                <span>
                                    <img width={'8px'} src={chevronRight} className="link-icon" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />

                <div>
                    {charge && charge.tip_model && (
                        <>
                            <TipDetails tipModel={charge.tip_model} timezone={timezone} />
                        </>
                    )}

                    {returns && (
                        <>
                            <ReturnDetails returnModel={returns} timezone={timezone} />
                        </>
                    )}

                    {customer && (
                        <>
                            <CustomerDetails customer={customer} customCustomerName={customCustomerName} />
                        </>
                    )}

                    <PaymentSourceDetails paymentSource={paymentSource} />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => {
    const charge = state.charge.list.items.find((el) => el.id === ownProps.match.params.transactionId) || null;
    const customer = state.customer.list.items.find((el) => el.id === (charge && charge.customer_id)) || null;
    const returns = state.returns.arr.find((el) => el.charge_id === ownProps.match.params.transactionId) || null;

    // Find all credits created from this charge and sort by amount, we will display the highest value credit if there are multiple
    const transactionCredits =
        state.credits.list.items
            .filter((c) => c.originated_from_charge_id == charge?.id)
            .sort((a, b) => b.amount - a.amount) || null;

    const credit = transactionCredits ? transactionCredits[0] : null;

    return {
        returns,
        charge,
        customer,
        paymentSource: state.paymentSources.list.items.length > 0 ? state.paymentSources.list.items[0] : null,
        isLoading: isActionRequesting(state.global.actions, 'fetch_charge'),
        customCustomerName: selectors.customizations.getCustomization(state, CustomizationType.CustomerName),
        timezone: state.merchant.selected?.timezone || null,
        credit: credit,
        promotion: state.promotions.list.items.find((promotion) => credit?.promotion_id == promotion.id) || null,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchChargeAction,
            fetchReturnAction,
            fetchTipAction,
            fetchCustomerAction,
            fetchPaymentSourceAction,
            cleanPaymentSourceAction,
            cleanCustomersAction,
            fetchCreditsAction,
            fetchPromotionsAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
