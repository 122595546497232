import * as React from 'react';
import { Button } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { deactivateAllPaymentSourcesAction } from '../../store/actions';
import { ResponseError } from '../../apis';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import Loader from '../Loader';
import ConfirmationModal from '../modals/ConfirmationModal';

interface StateProps {
    isLoading: boolean;
    errorMessage: ResponseError | null;
}

interface DispatchProps {
    deactivateAllPaymentSourcesAction: typeof deactivateAllPaymentSourcesAction;
}

interface OwnProps {
    customerId: string;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    ready: boolean;
    showModal: boolean;
    action: string | null;
}

class UnlinkAllButton extends React.Component<Props> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
    };

    onActionClick = async (action: string) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    onSubmit = async () => {
        const { customerId } = this.props;
        this.setState({ ready: false });
        if (this.state.action === null) return;
        try {
            this.props.deactivateAllPaymentSourcesAction(customerId);
            this.toggleModal();
        } finally {
            this.setState({ ready: true });
        }
    };

    render() {
        const { errorMessage, isLoading } = this.props;
        const { showModal, action } = this.state;
        return (
            <>
                <ConfirmationModal
                    amount={0}
                    isLoading={isLoading}
                    errorCode={errorMessage && errorMessage.code}
                    type={action}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    onSubmit={this.onSubmit}
                />
                {errorMessage ? (
                    <Button className="w-75 text-center" color="danger" disabled={true}>
                        There was an error unlinking all payment sources. Please contact Stronghold support.
                    </Button>
                ) : (
                    <Button
                        className="w-75 text-nowrap"
                        color="primary"
                        onClick={() => this.onActionClick('unlink all')}
                    >
                        <div>{isLoading ? <Loader color={'white'} size="sm" /> : `Unlink All`}</div>
                    </Button>
                )}
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorMessage: selectors.global.getResponseError(state.global.actions, 'deactivate_all_payment_sources'),
    paymentSources: state.paymentSources,
    isLoading: selectors.global.isActionRequesting(state.global.actions, 'deactivate_all_payment_sources'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivateAllPaymentSourcesAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkAllButton);
