import * as React from 'react';
import { Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Select, { OptionTypeBase } from 'react-select';
import DatePicker from '../DatePicker';
import { map, xor } from 'lodash';
import selectors from '../../store/selectors';
import { PAY_LINK_STATUS } from '../../apis';
import { DateTime } from 'luxon';
const calendarIcon = '/Calander---Grey.svg';
const searchIcon = '/Search-Icon.svg';

export interface Props {
    from?: DateTime;
    to?: DateTime;
    search?: string;
    onSearchChange?: (search: string) => void;
    payLinkStatuses?: PAY_LINK_STATUS[];
    onDateFromChange: (date: DateTime | null) => void;
    onDateToChange: (date: DateTime | null) => void;
    onPayLinkStatusChange?: (statuses: PAY_LINK_STATUS[]) => void;
}

interface StatusOptionType extends OptionTypeBase {
    value: PAY_LINK_STATUS;
    label: string;
}

const fromPayLinkStatusToOption = (status: PAY_LINK_STATUS): StatusOptionType => ({
    label: selectors.paylink.formatStatus(status),
    value: status,
});

export default class ListPayLinkFilters extends React.Component<Props> {
    notCaptured = (): PAY_LINK_STATUS[] => [PAY_LINK_STATUS.CREATED];

    onStatusChange = (statuses: PAY_LINK_STATUS[]) => {
        if (this.props.onPayLinkStatusChange) {
            this.props.onPayLinkStatusChange(statuses);
        }
    };

    onSearchChange = (search: string) => {
        if (this.props.onSearchChange) {
            this.props.onSearchChange(search);
        }
    };

    render() {
        const { from, to, search, payLinkStatuses } = this.props;
        const dateIcon = <img src={calendarIcon} width={'21px'} />;
        const captured = xor(payLinkStatuses, [PAY_LINK_STATUS.USED]).length === 0;
        const uncaptured = xor(this.props.payLinkStatuses, this.notCaptured()).length === 0;
        const noStatus = payLinkStatuses && payLinkStatuses.length === 0;

        return (
            <>
                <Row>
                    <Col md={6} className="mb-3">
                        <DatePicker
                            selected={from?.toJSDate()}
                            onChange={(date) => this.props.onDateFromChange(date ? DateTime.fromJSDate(date) : null)}
                            maxDate={to?.toJSDate() || DateTime.now().toJSDate()}
                            label={dateIcon}
                            placeholderText={'FROM - Select from date'}
                            className="form-control-lg"
                        />
                    </Col>
                    <Col md={6} className="mb-3">
                        <DatePicker
                            selected={to?.toJSDate()}
                            onChange={(date) => this.props.onDateToChange(date ? DateTime.fromJSDate(date) : null)}
                            minDate={from?.toJSDate()}
                            maxDate={DateTime.now().toJSDate()}
                            label={dateIcon}
                            placeholderText={'TO - Select to date'}
                            className="form-control-lg"
                        />
                    </Col>

                    {this.props.onPayLinkStatusChange && this.props.payLinkStatuses && (
                        <>
                            <Col md={6} className="mb-3">
                                <ButtonGroup className="w-100 list-btn">
                                    <Button
                                        onClick={() => this.onStatusChange([PAY_LINK_STATUS.USED])}
                                        className={`${captured ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        Captured
                                    </Button>
                                    <Button
                                        onClick={() => this.onStatusChange(this.notCaptured())}
                                        className={`${uncaptured ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        Uncaptured
                                    </Button>
                                    <Button
                                        onClick={() => this.onStatusChange([])}
                                        className={`${noStatus ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        All
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col md={6} className="mb-3">
                                <InputGroup>
                                    <Select
                                        options={map(
                                            PAY_LINK_STATUS,
                                            (value): StatusOptionType => fromPayLinkStatusToOption(value),
                                        )}
                                        value={payLinkStatuses && payLinkStatuses.map(fromPayLinkStatusToOption)}
                                        isMulti={true}
                                        components={{ IndicatorSeparator: () => null }}
                                        className="react-select-custom"
                                        onChange={(data) =>
                                            this.onStatusChange(map(data as StatusOptionType[], (el) => el.value))
                                        }
                                    />
                                </InputGroup>
                            </Col>
                        </>
                    )}
                </Row>
                {this.props.onSearchChange && this.props.search && (
                    <Row>
                        <Col md={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <img src={searchIcon} width={'21px'} />;
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    value={search}
                                    onChange={(e) => this.onSearchChange(e.target.value)}
                                    placeholder={'Search by ID'}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}
