import { ApplicationRoleModel, MerchantModel } from '../../apis';
import { RolesType, USER_ROLE } from '../types';
/* eslint-disable */

export function isAdmin(roles: ApplicationRoleModel[] | null) {
    if (!roles) {
        return false;
    }
    return !!roles.find((el) => el.normalized_name === USER_ROLE.MERCHANT_ALLADMIN);
}

export function isRegularUser(roles: ApplicationRoleModel[] | null) {
    if (!roles) {
        return false;
    }
    return !!roles.find((el) => el.normalized_name === USER_ROLE.MERCHANT_ALLUSERS);
}

export function noAccessStrongholdDirect(roles: ApplicationRoleModel[] | null, merchant: MerchantModel | null) {
    if (!roles || !merchant) {
        return false;
    }
    return roles.find((el) => el.normalized_name === USER_ROLE.NO_STRONGHOLD_DIRECT);
}

export function canCreatePayLinkSD(roles: ApplicationRoleModel[] | null, merchant: MerchantModel | null) {
    if (!roles || !merchant) {
        return false;
    }
    return (
        !roles.find((el) => el.normalized_name === USER_ROLE.STRONGHOLD_DIRECT_B2B) &&
        !roles.find((el) => el.normalized_name === USER_ROLE.NO_STRONGHOLD_DIRECT)
    );
}

export function canCreatePayLinkB2B(roles: ApplicationRoleModel[] | null, merchant: MerchantModel | null) {
    if (!roles || !merchant) {
        return false;
    }
    return !!roles.find((el) => el.normalized_name === USER_ROLE.STRONGHOLD_DIRECT_B2B);
}

export function canCreateReports(roles: ApplicationRoleModel[] | null) {
    if (!roles) {
        return false;
    }
    return !!roles.find(
        (el) =>
            el.normalized_name === USER_ROLE.MERCHANT_REPORTING || el.normalized_name === USER_ROLE.MERCHANT_ALLADMIN,
    );
}
export function canUpdateAmount(roles: ApplicationRoleModel[] | null) {
    if (!roles) {
        return false;
    }
    return !!roles.find((el) => el.normalized_name === USER_ROLE.MERCHANT_TRANSACTIONS_EDIT);
}

export function formatRoleType(role: RolesType) {
    let formattedRole;
    switch (role) {
        case 'AllAdmin':
            formattedRole = 'Admin';
            break;
        case 'AllUsers':
            formattedRole = 'Default';
            break;
        case 'StrongholdDirect':
            formattedRole = 'Stronghold Direct';
            break;
        case 'StrongholdDirectB2B':
            formattedRole = 'Stronghold Direct B2B';
            break;
        case 'AllReporting':
            formattedRole = 'Reporting';
            break;
    }
    return formattedRole;
}

export function formatRoleTypeFromString(roleType: string) {
    let formattedRole;
    switch (roleType) {
        case 'Admin':
            formattedRole = 'AllAdmin';
            break;
        case 'Default':
            formattedRole = 'AllUsers';
            break;
        case 'Stronghold Direct':
            formattedRole = 'StrongholdDirect';
            break;
        case 'Stronghold Direct B2B':
            formattedRole = 'StrongholdDirectB2B';
            break;
        case 'Reporting':
            formattedRole = 'AllReporting';
            break;
    }
    return formattedRole as RolesType;
}

export function toolTipMessage(role: RolesType) {
    let message;
    switch (role) {
        case 'AllAdmin':
            message = 'User can create new users.';
            break;
        case 'AllUsers':
            message = 'User with full access to the dashboard.';
            break;
        case 'StrongholdDirect':
            message = 'User with full access and can create Paylinks.';
            break;
        case 'StrongholdDirectB2B':
            message = 'User with full access and can create B2B Paylinks.';
            break;
        case 'AllReporting':
            message = 'User can view customers and reporting.';
            break;
    }
    return message;
}
