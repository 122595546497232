import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import { ApplicationState } from '../../store';
import Input from 'reactstrap/lib/Input';
import { createPayLinkAction, cleanPayLinkAction, resetActionStatusAction } from '../../store/actions';
import { CustomerModel, MerchantModel, PayLinkModel, ResponseError, StrongholdDirectRequestModel } from '../../apis';
import selectors from '../../store/selectors';
import NumberInput from '../../components/NumberInput';
import PayLinkFileUploader from '../../components/payLinks/PayLinkFileUploader';
import CustomersProfileEditModal from '../../components/modals/CustomersProfileEditModal';
const qrIcon = '/qr-code-white.svg';
const mobileIcon = '/SMS-white.svg';
const emailIcon = '/Email-white.svg';

export type LinkType = 'qr' | 'sms' | 'email';

interface OwnProps {
    customer: CustomerModel;
    strongholdDirectRequest: StrongholdDirectRequestModel;
    setStep: (stepNumber: 1 | 2 | 3 | 4) => void;
    setDeliveryType: (deliveryType: LinkType) => void;
    setFiles: (fileList: FileList | null) => void;
    setOrderId: (orderId: string) => void;
    setAmount: (amount: number) => void;
    setCustomer: (customer: CustomerModel) => void;
}

interface StateProps {
    merchant: MerchantModel | null;
    payLink: PayLinkModel | null;
    isCreatingPayLink: boolean;
    error: ResponseError | null;
    isStrongholdDirectB2B: boolean;
}

interface DispatchProps {
    createPayLinkAction: typeof createPayLinkAction;
    cleanPayLinkAction: typeof cleanPayLinkAction;
    resetActionStatusAction: typeof resetActionStatusAction;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    convenienceFee: number;
    showEditForm: boolean;
}

export class CreateStep2 extends React.Component<Props, State> {
    private amountInput: HTMLInputElement | null = null;

    state: State = {
        convenienceFee: this.props.merchant ? parseInt(this.props.merchant.convenience_fee) : 0,
        showEditForm: false,
    };

    componentDidMount = async () => {
        await this.props.cleanPayLinkAction();
    };

    toggleEditForm = () => this.setState({ showEditForm: !this.state.showEditForm });

    onOrderIdChange = async (orderId: string) => {
        this.props.setOrderId(orderId);
    };

    onAmountChange = async (amount: number) => {
        this.props.setAmount(amount);
    };

    onSetCustomer = async (customer: CustomerModel) => {
        this.props.setCustomer(customer);
    };

    onFileChange = (fileList: FileList | null) => {
        if (!fileList) return;
        this.props.setFiles(fileList);
    };

    selectDeliveryType = (deliveryType: LinkType) => {
        this.props.setDeliveryType(deliveryType);
        this.props.setStep(3);
    };

    render() {
        const { error, isStrongholdDirectB2B, customer, strongholdDirectRequest } = this.props;
        const { convenienceFee, showEditForm } = this.state;
        const isLoading = !customer;
        const noFiles =
            isStrongholdDirectB2B &&
            (!strongholdDirectRequest.fileList || strongholdDirectRequest.fileList.length === 0);
        const amount = strongholdDirectRequest.amount ? strongholdDirectRequest.amount : 0;
        const disabled = noFiles || amount < 100;

        return (
            <div>
                <div className="pb-5 mb-5">
                    <div className="h5 mb-4">{`${customer.individual.first_name} ${customer.individual.last_name}`}</div>

                    <Row className="mb-2">
                        <Col>
                            <div className="mb-1 font-weight-bold ">Email</div>
                            <Input
                                bsSize="lg"
                                value={(customer.individual.email && customer.individual.email.toString()) || '—'}
                                readOnly={true}
                                disabled={true}
                                data-sh="customer-details-email-input"
                            />
                        </Col>
                        <Col>
                            <div className="mb-1 font-weight-bold ">Mobile</div>
                            <Input
                                bsSize="lg"
                                value={(customer.individual.mobile && customer.individual.mobile.toString()) || '—'}
                                readOnly={true}
                                disabled={true}
                                data-sh="customer-details-mobile-input"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <div className="mb-1 font-weight-bold ">First name</div>
                            <Input
                                bsSize="lg"
                                value={customer.individual.first_name.toString() || ''}
                                readOnly={true}
                                disabled={true}
                                data-sh="customer-details-first-name-input"
                            />
                        </Col>
                        <Col>
                            <div className="mb-1 font-weight-bold ">Last name</div>
                            <Input
                                bsSize="lg"
                                readOnly={true}
                                value={customer.individual.last_name.toString() || ''}
                                disabled={true}
                                data-sh="customer-details-last-name-input"
                            />
                        </Col>
                    </Row>

                    <div className="h5 mb-4">Paylink details</div>

                    <Row className="mb-2">
                        <Col>
                            <div className="mb-1 font-weight-bold ">Total Amount *</div>
                            <div className="d-block">
                                <NumberInput
                                    innerRef={(input) => {
                                        this.amountInput = input as HTMLInputElement;
                                    }}
                                    className={`mx-auto form-control-tip form-control-lg border-2 bg-light${
                                        error && error.property === 'order.total_amount' ? 'is-invalid' : ''
                                    }`}
                                    onValueChange={this.onAmountChange}
                                    max={1000000}
                                    value={amount}
                                    prefix={'$'}
                                    digits={2}
                                    data-sh="customer-details-amount-input"
                                />
                                {error && error.property === 'order.total_amount' && (
                                    <>
                                        <div className="text-danger my-1">{error.message}</div>
                                    </>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <div className="mb-1 font-weight-bold ">Convenience Fee</div>
                            <Input bsSize="lg" readOnly={true} value={selectors.format.formatAmount(convenienceFee)} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <div className="mb-1 font-weight-bold ">Order ID</div>
                            <Input
                                className={`form-control from-control-lg border-2 bg-light${
                                    error && error.property === 'charge_external_id' ? 'is-invalid' : ''
                                }`}
                                value={
                                    strongholdDirectRequest.externalId
                                        ? strongholdDirectRequest.externalId.toString()
                                        : ''
                                }
                                onChange={(e) => this.onOrderIdChange(e.target.value)}
                                data-sh="customer-details-order-id-input"
                                bsSize="lg"
                            />
                            {error && error.property === 'charge_external_id' && (
                                <>
                                    <div className="text-danger my-1">{error.message}</div>
                                </>
                            )}
                        </Col>
                    </Row>
                    {isStrongholdDirectB2B && (
                        <Row className="mb-5">
                            <Col md={4} className="mb-5">
                                <div className="mb-1 font-weight-bold ">Documents *</div>
                                <PayLinkFileUploader
                                    onChangeFile={this.onFileChange}
                                    fileType={'.pdf'}
                                    multiple={true}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="py-3 border-top-gray fixed-bottom bg-light">
                    <Row className="justify-content-center">
                        <Col xs={4} md={2}>
                            <Button
                                color="primary"
                                onClick={() => this.selectDeliveryType('qr')}
                                disabled={disabled}
                                className="h-100 w-100 ml-1"
                                data-sh="create-qr-button"
                                size="lg"
                            >
                                <div className="d-flex justify-content-center">
                                    <div className="mr-2">Create QR code</div>
                                    <div>
                                        <img width="19px" height="19px" src={qrIcon} />
                                    </div>
                                </div>
                            </Button>
                        </Col>
                        {customer.individual.mobile ? (
                            <Col xs={4} md={{ size: 2 }}>
                                <Button
                                    onClick={() => this.selectDeliveryType('sms')}
                                    color="primary"
                                    disabled={disabled}
                                    className="h-100 w-100"
                                    data-sh="create-sms-button"
                                    size="lg"
                                >
                                    <div className="d-flex justify-content-center">
                                        <div className="mr-2">Send SMS</div>
                                        <div>
                                            <img width="19px" height="19px" src={mobileIcon} />
                                        </div>
                                    </div>
                                </Button>
                            </Col>
                        ) : (
                            <Col xs={4} md={{ size: 2 }}>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            showEditForm: true,
                                        })
                                    }
                                    color="primary"
                                    disabled={disabled}
                                    className=" h-100 w-100"
                                    data-sh="create-sms-button"
                                    size="lg"
                                >
                                    <div className="d-flex justify-content-center">
                                        <div className="mr-2">Send SMS</div>
                                        <div>
                                            <img width="19px" height="19px" src={mobileIcon} />
                                        </div>
                                    </div>
                                </Button>
                            </Col>
                        )}
                        {customer.individual.email ? (
                            <Col xs={4} md={{ size: 2 }}>
                                <Button
                                    onClick={() => this.selectDeliveryType('email')}
                                    color="primary"
                                    disabled={disabled}
                                    data-sh="create-email-button"
                                    size="lg"
                                    className="w-100 h-100 mr-1"
                                >
                                    <div className="d-flex justify-content-center">
                                        <div className="mr-2">Send email</div>
                                        <div>
                                            <img width="19px" height="19px" src={emailIcon} />
                                        </div>
                                    </div>
                                </Button>
                            </Col>
                        ) : (
                            <Col xs={4} md={{ size: 2 }}>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            showEditForm: true,
                                        })
                                    }
                                    color="primary"
                                    disabled={disabled}
                                    className="h-100 w-100"
                                    data-sh="create-email-button"
                                    size="lg"
                                >
                                    <div className="mr-2">Send email</div>
                                    <div>
                                        <img width="19px" height="19px" src={emailIcon} />
                                    </div>
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
                {customer && (
                    <CustomersProfileEditModal
                        customer={customer}
                        showModal={showEditForm}
                        toggle={this.toggleEditForm}
                        isLoading={isLoading}
                        showEmailOnly={!customer.individual.email}
                        showMobileOnly={!customer.individual.mobile}
                        setCustomer={this.onSetCustomer}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        merchant: state.merchant.selected,
        payLink: state.payLinks.selected,
        error: selectors.global.getResponseError(state.global.actions, 'create_pay_link'),
        isCreatingPayLink: selectors.global.isActionRequesting(state.global.actions, 'create_pay_link'),
        isStrongholdDirectB2B: selectors.user.canCreatePayLinkB2B(
            state.authentication.user.roles,
            state.merchant.selected,
        ),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createPayLinkAction,
            cleanPayLinkAction,
            resetActionStatusAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateStep2);
