import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { PayLinkModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import selectors from '../../store/selectors';
import Pagination from '../../components/Pagination';
import PayLinkStatusBadge from './PayLinkStatusBadge';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
const chevronRight = '/Text-Link-Arrow.svg';
interface StateProps {
    payLinks: PayLinkModel[];
    payLinksTotal: number;
    payLinksSkipped: number;
    payLinksLimit: number;
    isLoading: boolean;
    timezone: string | null;
}

interface OwnerProps {
    merchantId?: string;
    isLoading?: boolean;
    onRowClick: (payLink: PayLinkModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
}

type Props = StateProps & OwnerProps;

class PayLinksTable extends React.Component<Props> {
    hasNoRows = () => this.props.payLinks.length === 0 && !this.props.isLoading;

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no pay links to display'}
            </Alert>
        );
    };

    render() {
        return (
            <>
                <Table hover responsive className={['table-lg', this.hasNoRows() ? 'mb-0' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 165 }}>Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Order ID</th>
                            <th>Customer</th>
                            <th>ID</th>
                            <th style={{ minWidth: 200 }}></th>
                        </tr>
                    </thead>
                    <TableBody isLoading={this.props.isLoading} rows={this.props.payLinksLimit} columns={7}>
                        {this.props.payLinks.map((payLink, index) => (
                            <tr onClick={() => this.props.onRowClick(payLink)} key={index}>
                                <td>
                                    {selectors.format.formatDateTimeMinutesWithYear(
                                        payLink.created_at,
                                        this.props.timezone,
                                    )}
                                </td>
                                <td>
                                    <PayLinkStatusBadge size={'sm'} status={payLink.status} />
                                </td>
                                <td>{selectors.format.formatAmount(payLink.order.total_amount)}</td>
                                <td>{payLink.external_charge_id}</td>
                                <td>{payLink.customer_id}</td>
                                <td>{payLink.id}</td>
                                <td className="text-primary">
                                    <span className="mr-2">View</span>
                                    <span>
                                        <img width={'8px'} src={chevronRight} className="link-icon" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.payLinksTotal}
                    limit={this.props.payLinksLimit}
                    skipped={this.props.payLinksSkipped}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.payLinksLimit + 1, skip)}
                    disabled={this.props.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    payLinks: state.payLinks.list.items,
    payLinksSkipped: state.payLinks.list.skipped,
    payLinksTotal: state.payLinks.list.total,
    payLinksLimit: state.payLinks.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_pay_links') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
});

export default connect(mapStateToProps)(PayLinksTable);
