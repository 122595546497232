import { CustomerState } from '../types';
import { ADD_CUSTOMER, CLEAN_CUSTOMERS, SET_CUSTOMERS, SWITCH_CONTEXT, UPDATE_CUSTOMER } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { map, unionBy } from 'lodash';
import { CustomerModel, segmentListLimit } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CustomerState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
};

export const customerReducer: Reducer<CustomerState, ApplicationAction> = (
    state = unloadedState,
    action,
): CustomerState => {
    switch (action.type) {
        case SWITCH_CONTEXT:
            return action.payload.switching ? unloadedState : state;
        case SET_CUSTOMERS:
            return {
                ...state,
                list: action.payload.list,
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: unionBy<CustomerModel>(state.list.items, [action.payload.customer], (el) => el.id),
                },
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: map(state.list.items, (item) =>
                        item.id === action.payload.data.id ? action.payload.data : item,
                    ),
                },
            };
        case CLEAN_CUSTOMERS:
            return {
                ...state,
                list: unloadedState.list,
            };
        default:
            return state;
    }
};
