import * as React from 'react';
import { AutomatedMailingReportModel } from '../../apis';
import TableBody from '../TableBody';
import Table from 'reactstrap/lib/Table';
import Alert from 'reactstrap/lib/Alert';
import AutomatedReportsActions from './AutomatedReportsActions';

interface Props {
    automatedReports: AutomatedMailingReportModel[] | null;
    isLoading: boolean;
}

export default function AutomatedReportsTable(props: Props) {
    const { isLoading, automatedReports } = props;

    const renderNoRows = () => {
        if (props.automatedReports && props.automatedReports.length > 0) return null;
        return (
            <Alert className="w-100" color="info-light">
                There are no automated reports to display
            </Alert>
        );
    };
    return (
        <>
            {automatedReports && (
                <Table responsive className="table-lg">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Interval</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <TableBody loaderWidth="100%" isLoading={isLoading} rows={3} columns={4}>
                        {automatedReports.map((report, index) => (
                            <tr key={index}>
                                <td>{report && report.email}</td>
                                <td>{report && report.interval}</td>
                                <td>
                                    <span className="text-capitalize id-badge py-1 text-dark">
                                        {report && report.type}
                                    </span>
                                </td>
                                <td>
                                    <AutomatedReportsActions
                                        className={'p-0 m-0'}
                                        reportId={report.id}
                                        disabled={isLoading}
                                        action={'remove'}
                                        size={'small'}
                                    />
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
            )}
            {!isLoading && renderNoRows()}
        </>
    );
}
