/**
 * Set the requesting status for an action
 */
export const SET_ACTION_REQUEST_STATUS = 'SET_ACTION_REQUEST_STATUS';
export type SET_ACTION_REQUEST_STATUS = typeof SET_ACTION_REQUEST_STATUS;
/**
 * Clean all the actions from the store
 */
export const RESET_ACTION_STATUS = 'RESET_ACTION_STATUS';
export type RESET_ACTION_STATUS = typeof RESET_ACTION_STATUS;
/**
 * Set an error for an action, this will set the action as terminated
 */
export const SET_ACTION_RESPONSE_ERROR = 'SET_ACTION_RESPONSE_ERROR';
export type SET_ACTION_RESPONSE_ERROR = typeof SET_ACTION_RESPONSE_ERROR;
export const RESET_ACTION_RESPONSE_ERROR = 'RESET_ACTION_RESPONSE_ERROR';
export type RESET_ACTION_RESPONSE_ERROR = typeof RESET_ACTION_RESPONSE_ERROR;
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export type SET_CONFIGURATION = typeof SET_CONFIGURATION;

export const LOGIN = 'LOGIN';
export type LOGIN = typeof LOGIN;

export const READY = 'READY';
export type READY = typeof READY;

/**
 * Register the action of switching for a different environment or merchant
 */
export const SWITCH_CONTEXT = 'SWITCH_CONTEXT';
export type SWITCH_CONTEXT = typeof SWITCH_CONTEXT;

// Api Keys
export const SET_ENVIRONMENTS = 'SET_ENVIRONMENTS';
export type SET_ENVIRONMENTS = typeof SET_ENVIRONMENTS;
export const SET_ENVIRONMENT_DATA = 'SHOW_LIVE_DATA';
export type SET_ENVIRONMENT_DATA = typeof SET_ENVIRONMENT_DATA;
export const SHOW_REQUEST_LIVE_DATA_MESSAGE = 'SHOW_REQUEST_LIVE_DATA_MESSAGE';
export type SHOW_REQUEST_LIVE_DATA_MESSAGE = typeof SHOW_REQUEST_LIVE_DATA_MESSAGE;
export const SHOW_SWITCHED_SANDBOX_MESSAGE = 'SHOW_SWITCHED_SANDBOX_MESSAGE';
export type SHOW_SWITCHED_SANDBOX_MESSAGE = typeof SHOW_SWITCHED_SANDBOX_MESSAGE;

// Merchants
export const SET_MERCHANT = 'SET_MERCHANT';
export type SET_MERCHANT = typeof SET_MERCHANT;
export const SET_MERCHANTS = 'SET_MERCHANTS';
export type SET_MERCHANTS = typeof SET_MERCHANTS;

// Charges
export const SET_CHARGES = 'SET_CHARGES';
export type SET_CHARGES = typeof SET_CHARGES;
export const UPSERT_CHARGE = 'UPSERT_CHARGE';
export type UPSERT_CHARGE = typeof UPSERT_CHARGE;
export const CLEAN_CHARGES = 'CLEAN_CHARGES';
export type CLEAN_CHARGES = typeof CLEAN_CHARGES;
export const REFUND_CHARGE = 'REFUND_CHARGE';
export type REFUND_CHARGE = typeof REFUND_CHARGE;
export const SET_CHARGE_TIP = 'SET_CHARGE_TIP';
export type SET_CHARGE_TIP = typeof SET_CHARGE_TIP;

// Returns
export const SET_RETURN = 'SET_RETURN';
export type SET_RETURN = typeof SET_RETURN;
export const CLEAN_RETURNS = 'CLEAN_RETURNS';
export type CLEAN_RETURNS = typeof CLEAN_RETURNS;

// Rewards
export const SET_EARNED_POINTS_YTD = 'SET_EARNED_POINTS_YTD';
export type SET_EARNED_POINTS_YTD = typeof SET_EARNED_POINTS_YTD;

// Settlements
export const SET_SETTLEMENTS = 'SET_SETTLEMENTS';
export type SET_SETTLEMENTS = typeof SET_SETTLEMENTS;
export const ADD_SETTLEMENT = 'ADD_SETTLEMENT';
export type ADD_SETTLEMENT = typeof ADD_SETTLEMENT;

// Balance transfers
export const SET_BALANCE_TRANSFERS = 'SET_BALANCE_TRANSFERS';
export type SET_BALANCE_TRANSFERS = typeof SET_BALANCE_TRANSFERS;
export const SET_BALANCE_TRANSFER_SUMMARY = 'SET_BALANCE_TRANSFER_SUMMARY';
export type SET_BALANCE_TRANSFER_SUMMARY = typeof SET_BALANCE_TRANSFER_SUMMARY;

// Customers
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export type SET_CUSTOMERS = typeof SET_CUSTOMERS;
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export type ADD_CUSTOMER = typeof ADD_CUSTOMER;
export const CLEAN_CUSTOMERS = 'CLEAN_CUSTOMERS';
export type CLEAN_CUSTOMERS = typeof CLEAN_CUSTOMERS;
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export type UPDATE_CUSTOMER = typeof UPDATE_CUSTOMER;

// Payment sources
export const SET_PAYMENT_SOURCE = 'SET_PAYMENT_SOURCE';
export type SET_PAYMENT_SOURCE = typeof SET_PAYMENT_SOURCE;
export const SET_PAYMENT_SOURCES = 'SET_PAYMENT_SOURCES';
export type SET_PAYMENT_SOURCES = typeof SET_PAYMENT_SOURCES;
export const CLEAN_PAYMENT_SOURCE = 'CLEAN_PAYMENT_SOURCE';
export type CLEAN_PAYMENT_SOURCE = typeof CLEAN_PAYMENT_SOURCE;
export const UPDATE_PAYMENT_SOURCE = 'UPDATE_PAYMENT_SOURCE';
export type UPDATE_PAYMENT_SOURCE = typeof UPDATE_PAYMENT_SOURCE;

// Reports
export const SET_TRANSACTIONS_REPORT = 'SET_TRANSACTIONS_REPORT';
export type SET_TRANSACTIONS_REPORT = typeof SET_TRANSACTIONS_REPORT;
export const SET_AUTOMATED_MAILING_REPORTS = 'SET_AUTOMATED_MAILING_REPORTS';
export type SET_AUTOMATED_MAILING_REPORTS = typeof SET_AUTOMATED_MAILING_REPORTS;
export const ADD_AUTOMATED_MAILING_REPORT = 'ADD_AUTOMATED_MAILING_REPORT';
export type ADD_AUTOMATED_MAILING_REPORT = typeof ADD_AUTOMATED_MAILING_REPORT;
export const REMOVE_AUTOMATED_MAILING_REPORT = 'REMOVE_AUTOMATED_MAILING_REPORT';
export type REMOVE_AUTOMATED_MAILING_REPORT = typeof REMOVE_AUTOMATED_MAILING_REPORT;

export const SET_PROMOTION_REPORT = 'SET_PROMOTION_REPORT';
export type SET_PROMOTION_REPORT = typeof SET_PROMOTION_REPORT;

// Users
export const SET_USERS = 'SET_USERS';
export type SET_USERS = typeof SET_USERS;
export const SET_ROLES = 'SET_ROLES';
export type SET_ROLES = typeof SET_ROLES;
export const ADD_USER = 'ADD_USER';
export type ADD_USER = typeof ADD_USER;
export const REMOVE_USER = 'REMOVE_USER';
export type REMOVE_USER = typeof REMOVE_USER;
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';
export type UPDATE_USER_ROLES = typeof UPDATE_USER_ROLES;
export const SHOW_DEVELOPER_SIGN_UP = 'SHOW_DEVELOPER_SIGN_UP';
export type SHOW_DEVELOPER_SIGN_UP = typeof SHOW_DEVELOPER_SIGN_UP;

// Pay Links
export const SET_PAYLINKS = 'SET_PAYLINKS';
export type SET_PAYLINKS = typeof SET_PAYLINKS;
export const UPSERT_PAYLINK = 'UPSERT_PAYLINK';
export type UPSERT_PAYLINK = typeof UPSERT_PAYLINK;
export const ADD_PAYLINK = 'ADD_PAYLINK';
export type ADD_PAYLINK = typeof ADD_PAYLINK;
export const SET_PAYLINK = 'SET_PAYLINK';
export type SET_PAYLINK = typeof SET_PAYLINK;
export const CLEAN_PAYLINK = 'CLEAN_PAYLINK';
export type CLEAN_PAYLINK = typeof CLEAN_PAYLINK;

// Promotions
export const SET_PROMOTION = 'SET_PROMOTION';
export type SET_PROMOTION = typeof SET_PROMOTION;
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export type SET_PROMOTIONS = typeof SET_PROMOTIONS;

// Credits
export const SET_CREDIT = 'SET_CREDIT';
export type SET_CREDIT = typeof SET_CREDIT;
export const SET_CREDITS = 'SET_CREDITS';
export type SET_CREDITS = typeof SET_CREDITS;

// Processing Fees
export const SET_PROCESSING_FEE_AMOUNT = 'SET_PROCESSING_FEE_AMOUNT';
export type SET_PROCESSING_FEE_AMOUNT = typeof SET_PROCESSING_FEE_AMOUNT;
