import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import Layout from '../../components/Layout';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ROUTE } from '../../config';
import { history } from '../../index';
import UsersTable from '../../components/users/UsersTable';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchDashboardUsersAction } from '../../store/actions';
import { DashboardUserModel } from '../../apis';

interface StateProps {
    isLoading: boolean;
    dashboardUsers: DashboardUserModel[] | null;
}

interface DispatchProps {
    fetchDashboardUsersAction: typeof fetchDashboardUsersAction;
}
type Props = StateProps & DispatchProps;

export class Admin extends React.Component<Props> {
    componentDidMount = async () => {
        try {
            await this.props.fetchDashboardUsersAction();
        } catch {
            // handle silently
        }
    };

    render() {
        const { dashboardUsers, isLoading } = this.props;

        return (
            <Layout>
                <div>
                    <div className="row mb-4">
                        <div className="col-xs-12 col-md-8">
                            <h3>Admin</h3>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="d-flex flex-column justify-content-center mt-2">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="px-4 py-2 w-100"
                                    onClick={() => history.push(ROUTE.ADMIN_ADD_USER)}
                                >
                                    <FontAwesomeIcon icon={faPlus} fixedWidth className="mr-2" />
                                    Add a user
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <UsersTable dashboardUsers={dashboardUsers} isLoading={isLoading} />
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    isLoading: isActionRequesting(state.global.actions, 'fetch_dashboard_users'),
    dashboardUsers: state.users.arr || [],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchDashboardUsersAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
