import { SET_CREDIT, SET_CREDITS } from '../constants';
import { CreditModel, ListSegment } from '../../apis';
import { AppThunkAction } from '../index';
import { ApplicationAction, call } from './index';
import * as api from '../../apis';

export interface SetCreditAction {
    type: SET_CREDIT;
    payload: {
        selectedCredit: CreditModel;
    };
}

export const setCreditAction = (selectedCredit: CreditModel): SetCreditAction => ({
    type: SET_CREDIT,
    payload: {
        selectedCredit: selectedCredit,
    },
});

export interface SetCreditsAction {
    type: SET_CREDITS;
    payload: {
        list: ListSegment<CreditModel>;
    };
}

export const setCreditsAction = (list: ListSegment<CreditModel>): SetCreditsAction => ({
    type: SET_CREDITS,
    payload: {
        list,
    },
});

export interface FetchCreditsActionOptions {
    skip?: number;
    limit?: number;
    customerId?: string;
    promotionId?: string;
    originatedFromChargeId?: string[];
    redeemedChargeId?: string;
}

export function fetchCreditAction(creditId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_credit',
            call: () => api.getCredit(creditId),
            dispatch,
            onSuccess: async (body) => {
                await dispatch(setCreditAction(body));
            },
        });
    };
}

export function fetchCreditsAction(options: FetchCreditsActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await call({
            actionName: 'fetch_credits',
            call: () => api.listCredits(options),
            dispatch,
            onSuccess: async (body) => {
                await dispatch(setCreditsAction(body));
            },
        });
    };
}

export type CreditsAction = SetCreditAction | SetCreditsAction;
