import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { CreditModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import Pagination from '../../components/Pagination';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
import selectors from '../../store/selectors';
interface StateProps {
    credits: CreditModel[];
    creditsTotal: number;
    creditsSkipped: number;
    creditsLimit: number;
    isLoading: boolean;
    timezone: string | null;
}

interface OwnerProps {
    isLoading?: boolean;
    onOriginChargeClick: (credit: CreditModel) => void;
    onRedeemedChargeClick: (credit: CreditModel) => void;
    onFlexColumnClick: (credit: CreditModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
    showPromoColumn: boolean;
}

type Props = StateProps & OwnerProps;

class CreditsTable extends React.Component<Props> {
    hasNoRows = () => this.props.credits.length === 0 && !this.props.isLoading;

    formatRedeemedDate = (credit: CreditModel) => {
        if (!credit.redeemed) {
            return 'Unredeemed';
        } else {
            // If the credit has been redeemed and updatedAt is null, it was redeemed at time of creation
            const redeemedDate = credit.updated_at ? credit.updated_at : credit.created_at;
            return selectors.format.formatDateTimeSecondsWithYear(redeemedDate, this.props.timezone);
        }
    };

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no credits to display'}
            </Alert>
        );
    };

    render() {
        const { timezone } = this.props;
        return (
            <>
                <div>
                    <h2>Promotional Credits</h2>
                </div>
                <Table hover responsive className={['table-lg', this.hasNoRows() ? 'mb-0' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th>Amount</th>
                            <th>Earned On</th>
                            <th>Redeemed On</th>
                            {/*Fourth column flexes between promotion or customer depending which page it's displayed on*/}
                            <th>{this.props.showPromoColumn ? 'Promotion' : 'Customer'}</th>
                        </tr>
                    </thead>
                    <TableBody
                        isLoading={this.props.isLoading}
                        rows={this.props.creditsLimit}
                        columns={4}
                        loaderWidth="100%"
                    >
                        {this.props.credits.map((credit, index) => (
                            <tr key={index}>
                                <td>{selectors.format.formatAmount(credit.amount)}</td>
                                <td onClick={() => this.props.onOriginChargeClick(credit)} key={index}>
                                    {selectors.format.formatDateTimeSecondsWithYear(credit.created_at, timezone)}
                                </td>
                                <td
                                    onClick={() => credit.redeemed && this.props.onRedeemedChargeClick(credit)}
                                    key={index}
                                >
                                    {this.formatRedeemedDate(credit)}
                                </td>
                                <td onClick={() => this.props.onFlexColumnClick(credit)} key={index}>
                                    {this.props.showPromoColumn
                                        ? credit.promotion_id
                                        : credit.customer.individual.first_name.concat(
                                              ' ',
                                              credit.customer.individual.last_name,
                                          )}
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.creditsTotal}
                    limit={this.props.creditsLimit}
                    skipped={this.props.creditsSkipped}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.creditsLimit + 1, skip)}
                    disabled={this.props.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    credits: state.credits.list.items,
    creditsSkipped: state.credits.list.skipped,
    creditsTotal: state.credits.list.total,
    creditsLimit: state.credits.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_credits') || isLoading,
    timezone: state.merchant.selected?.timezone || null,
});

export default connect(mapStateToProps)(CreditsTable);
