import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ROUTE } from '../../config';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import { Button, TabContent, TabPane } from 'reactstrap';
import { DateTime } from 'luxon';
import { downloadOutstandingReturnsReport } from '../../apis';
import ListFilters from '../../components/ListFilters';
import selectors from '../../store/selectors';
import MonthSelector from '../../components/reports/MonthSelector';

interface StateProps {
    isLoading: boolean;
}

type OwnProps = RouteComponentProps<{
    filterType: string;
}>;

type Props = StateProps & OwnProps;

interface State {
    from?: DateTime;
    to?: DateTime;
    open: boolean;
    activeTab: number;
}

class OutstandingReturnsReport extends React.Component<Props, State> {
    private today = DateTime.now();
    private monthStart = this.today.startOf('month');

    getFilterType() {
        let filter = '';
        switch (this.props.match.params.filterType) {
            case 'transaction-date':
                filter = 'by Transaction Date';
                break;
            case 'return-date':
                filter = 'by Return Date';
                break;
            default:
                break;
        }

        return filter;
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            from: this.monthStart,
            to: this.today,
            open: false,
            activeTab: 1,
        };
    }

    downloadOutstandingReturnsReport = () => {
        const { from, to } = this.state;
        downloadOutstandingReturnsReport({
            from: selectors.format.formatDateIso(from) as string,
            to: selectors.format.formatDateIso(to) as string,
            filterType: this.props.match.params.filterType?.replace('-', '_'),
        });
    };

    handleDateFromChange = async (date: DateTime | null) => {
        await this.setState({ from: date || undefined });
    };

    handleDateToChange = (date: DateTime | null) => {
        this.setState({ to: date || undefined });
    };

    toggleTab = (activeTab: number) => {
        this.setState({ activeTab });
    };

    onChangeFrom = (date: DateTime) => {
        this.setState({ from: date });
    };

    onChangeTo = (date: DateTime) => {
        this.setState({ to: date });
    };

    renderSearch = () => {
        return (
            <div className="d-flex">
                <div className="w-100 mb-3">
                    <MonthSelector
                        from={this.state.from}
                        to={this.state.to}
                        onChangeFrom={this.onChangeFrom}
                        onChangeTo={this.onChangeTo}
                    />
                </div>
            </div>
        );
    };

    renderAdvancedSearch = () => {
        return (
            <ListFilters
                to={this.state.to}
                from={this.state.from}
                onDateFromChange={this.handleDateFromChange}
                onDateToChange={this.handleDateToChange}
            />
        );
    };

    render() {
        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.REPORTS,
                title: 'Reports',
            },
            {
                href: ROUTE.REPORTS_OUTSTANDING_REPORTS,
                title: `Outstanding Returns Reports ${this.getFilterType()}`,
            },
        ];

        const { from, to, activeTab } = this.state;

        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div>
                    <div className="col-8 px-0">
                        <h1>Outstanding Returns Report {this.getFilterType()}</h1>
                    </div>

                    <div className="mb-4 border-bottom-gray-sm">
                        <div className="d-flex mt-3 col-12 col-md-6 mb-4">
                            <div>
                                <Button
                                    color="none"
                                    className={`mr-4 ${activeTab === 1 ? 'btn-primary-2' : 'text-primary-2'}`}
                                    onClick={() => this.toggleTab(1)}
                                >
                                    By month
                                </Button>
                            </div>
                            <div>
                                <Button
                                    color="none"
                                    className={`mr-4 ${activeTab === 2 ? 'btn-primary-2' : 'text-primary-2'}`}
                                    onClick={() => this.toggleTab(2)}
                                >
                                    Advanced - Date range
                                </Button>
                            </div>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane className="fade-in" tabId={1}>
                            {this.renderSearch()}
                        </TabPane>
                        <TabPane className="fade-in" tabId={2}>
                            {this.renderAdvancedSearch()}
                        </TabPane>
                    </TabContent>

                    <div className="d-flex justify-content-end">
                        <Button
                            color="primary"
                            disabled={!from || !to}
                            onClick={() => this.downloadOutstandingReturnsReport()}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <div>Download report</div>
                            </div>
                        </Button>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(OutstandingReturnsReport);
