import { DateTime } from 'luxon';
import { CustomerModel } from '../../apis';

// Date
// formateDateIso ignores timezones and should only be used when generating local dates for URLs/API requests.
const toLowerCaseLastTwoChars = (value: string) => value.slice(0, -2) + value.slice(-2).toLowerCase();

export const formatDateIso = (value: DateTime | undefined) => (value ? value.toFormat('yyyy-MM-dd') : null);
export const formatDate = (value: string | null, tz: string | null) =>
    value ? (tz ? DateTime.fromISO(value).setZone(tz) : DateTime.fromISO(value)).toFormat('MMM dd, yyyy') : null;
export const formatDateTimeMinutesWithYear = (value: string | null, tz: string | null) =>
    value
        ? toLowerCaseLastTwoChars(
              (tz ? DateTime.fromISO(value).setZone(tz) : DateTime.fromISO(value)).toFormat('MMM dd, yyyy h:mm a'),
          )
        : null;
export const formatDateTimeSecondsWithYear = (value: string | null, tz: string | null) =>
    value
        ? toLowerCaseLastTwoChars(
              (tz ? DateTime.fromISO(value).setZone(tz) : DateTime.fromISO(value)).toFormat('MMM dd, yyyy h:mm:ss a'),
          )
        : null;

// Currency
export function formatAmount(amount: number | string, withoutSign = false) {
    const num = typeof amount === 'string' ? parseInt(amount) : amount;
    const value = ((num || 0) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (withoutSign) {
        return `${value}`;
    }
    return `$${value}`;
}

export function formatTipAmount(tipAmount: number | string, withoutSign = false) {
    const num = typeof tipAmount === 'string' ? parseInt(tipAmount) : tipAmount;
    const value = ((num || 0) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (withoutSign) {
        return `${value}`;
    }
    return `$${value}`;
}

// Number
export function formatNumberNoDecimal(amount: number | string) {
    const num = typeof amount === 'string' ? parseInt(amount) : amount;
    const value = (num || 0).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return `${value}`;
}

// Customer
export function formatCustomerName(customer: CustomerModel) {
    return `${customer.individual.first_name} ${customer.individual.last_name}`;
}

export function formatMobile(mobile: string, isSearch = false) {
    // Remove spaces and special chars
    mobile = mobile
        .replace(/[^\w\s +]/gi, '')
        .replace(/ /g, '')
        .replace(/_/g, '');
    if (mobile === '') {
        return '';
    }
    // Add country code if not search because we do not to pass in the E168 format for fuzzy searches
    if (!isSearch && mobile.charAt(0) !== '+') {
        mobile = ['+1', mobile].join('');
    }
    return mobile;
}

const domesticUsRegex = /^1?\d{10}$/;

export function formatMobilePhoneNumberReadable(mobile?: string): string {
    if (!mobile) {
        return '';
    }

    if (domesticUsRegex.test(mobile)) {
        // domestic US phone number
        const formatted = mobile.length == 11 ? mobile.substr(1) : mobile;
        return `(${formatted.substr(0, 3)}) ${formatted.substr(3, 3)}-${formatted.substr(6)}`;
    } else {
        const formatted = mobile.replace(/[+]/g, '');
        return `(${formatted.substr(1, 3)}) ${formatted.substr(4, 3)}-${formatted.substr(7)}`;
    }
}

// strings
export function formatHypenated(hyphenatedString: string) {
    return hyphenatedString.replace('-', ' ');
}

export function formatUnderscored(hyphenatedString: string) {
    return hyphenatedString.replace(/\_/g, ' ');
}

export function formatCapitalizeFirstLetter(text: string) {
    return text[0].toUpperCase() + text.slice(1);
}

// Bytes
export function formatKB(size: number) {
    return Math.round(size / 1024).toFixed(2);
}
