import { RewardsState } from '../types';
import { SET_EARNED_POINTS_YTD } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RewardsState = {
    // reward balance of -1 will display the 'Coming soon' text
    earnedPointsYTD: -1,
};

export const rewardsReducer: Reducer<RewardsState, ApplicationAction> = (
    state = unloadedState,
    action,
): RewardsState => {
    switch (action.type) {
        case SET_EARNED_POINTS_YTD:
            return {
                ...state,
                earnedPointsYTD: action.payload.earnedPointsYTD,
            };
        default:
            return state;
    }
};
