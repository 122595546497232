import { ActionName, ActionStatus, SPLIT_FLAG } from '../types';
import { ROUTE } from '../../config';
import { ISplitTreatmentsProps } from '@splitsoftware/splitio-react/types/types';

export function numberRange(start: number, end: number, step = 1): number[] {
    const output = [];
    if (typeof end === 'undefined') {
        end = start;
        start = 0;
    }
    for (let i = start; i < end + 1; i += step) {
        output.push(i);
    }
    return output;
}

export function isActionRequesting(requests: ActionStatus[], name: ActionName): boolean {
    const status = requests.find((el) => el.name === name);
    if (!status) return false;
    return status.isRequesting;
}

export function getActionStatus(statuses: ActionStatus[], name: ActionName) {
    return statuses.find((el) => el.name === name);
}

export function getResponseError(statuses: ActionStatus[], name: ActionName) {
    const status = getActionStatus(statuses, name);
    if (!status) return null;
    return status.responseError || null;
}

export const customerRoute = (customerId: string) => ROUTE.CUSTOMER.replace(':customerId', customerId);
export const payLinkRoute = (payLinkId: string) => ROUTE.PAYLINK.replace(':payLinkId', payLinkId);
export const transactionRoute = (chargeId: string) => ROUTE.TRANSACTION.replace(':transactionId', chargeId);
export const transactionsPaymentFilterRoute = (paymentSourceId: string) =>
    ROUTE.TRANSACTIONS + `?paymentSourceId=${paymentSourceId}`;
export const settlementRoute = (settlementId: string) => ROUTE.SETTLEMENT.replace(':settlementId', settlementId);
export const promotionRoute = (promotionId: string) => ROUTE.PROMOTION.replace(':promotionId', promotionId);
export const outstandingReportsRoute = (filterType: string) =>
    ROUTE.REPORTS_OUTSTANDING_REPORTS.replace(':filterType', filterType);

/**
 * This function set the default value for the flag
 * TODO: See if the return type is something else than a boolean, we could type the flags directly
 * @param name
 * @param flags
 */
export function getSplitFlagValue(name: SPLIT_FLAG, flags: ISplitTreatmentsProps['names']): boolean {
    flags = flags || {};
    switch (name) {
        case SPLIT_FLAG.REPORTS_TAB:
            return flags.includes(name) || false;
        case SPLIT_FLAG.TRANSACTIONS_REPORTS:
            return flags.includes(name) || false;
        default:
            throw new Error('Not implemented flag value');
    }
}
