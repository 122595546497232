import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { CustomerModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import Pagination from '../../components/Pagination';
import TableBody from '../TableBody';
import Alert from 'reactstrap/lib/Alert';
import selectors from '../../store/selectors';
const chevronRight = '/Text-Link-Arrow.svg';
interface StateProps {
    customers: CustomerModel[];
    customersTotal: number;
    customersSkipped: number;
    customersLimit: number;
    isLoading: boolean;
}

interface OwnerProps {
    isLoading?: boolean;
    onRowClick: (customer: CustomerModel) => void;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
    strongholdDirectPage?: boolean;
}

type Props = StateProps & OwnerProps;

class CustomersTable extends React.Component<Props> {
    hasNoRows = () => this.props.customers.length === 0 && !this.props.isLoading;

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <Alert color="info-light">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no customers to display'}
            </Alert>
        );
    };

    render() {
        const { strongholdDirectPage } = this.props;
        return (
            <>
                <Table hover responsive className={['table-lg', this.hasNoRows() ? 'mb-0' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>External ID</th>
                            <th style={{ minWidth: 140 }}></th>
                        </tr>
                    </thead>
                    <TableBody
                        isLoading={this.props.isLoading}
                        rows={this.props.customersLimit}
                        columns={5}
                        loaderWidth="100%"
                    >
                        {this.props.customers.map((customer, index) => (
                            <tr onClick={() => this.props.onRowClick(customer)} key={index}>
                                <td>{selectors.customer.getCustomerName(customer)}</td>
                                <td>{customer.individual.email}</td>
                                <td>{selectors.format.formatMobilePhoneNumberReadable(customer.individual.mobile)}</td>
                                <td>{customer.external_id}</td>
                                <td className="text-primary">
                                    <span className="mr-2">{strongholdDirectPage ? 'Select' : 'View'}</span>
                                    <span>
                                        <img width={'8px'} src={chevronRight} className="link-icon" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.customersTotal}
                    limit={this.props.customersLimit}
                    skipped={this.props.customersSkipped}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.customersLimit + 1, skip)}
                    disabled={this.props.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    customers: state.customer.list.items,
    customersSkipped: state.customer.list.skipped,
    customersTotal: state.customer.list.total,
    customersLimit: state.customer.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_customers') || isLoading,
});

export default connect(mapStateToProps)(CustomersTable);
