import { ListSegment } from './response';
import { stringify } from 'querystring';
import { request, segmentListLimit } from './utils';

export interface SettlementModel {
    id: string;
    gross_amount: number;
    net_amount: number;
    fee: number;
    currency: string;
    description: string;
    date: string;
}

export async function listSettlements(
    options: {
        skip?: number;
        limit?: number;
        from?: string;
        to?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });
    const res = await request<ListSegment<SettlementModel>>({
        method: 'GET',
        url: `/api/settlements?${query}`,
    });
    return res.result;
}

export async function getSettlement(settlementId: string) {
    const res = await request<SettlementModel>({
        method: 'GET',
        url: `/api/settlements/${settlementId}`,
    });
    return res.result;
}
