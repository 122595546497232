import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { history } from '../../index';
import selectors from '../../store/selectors';
import SHQRCode from '../../components/SHQRCode';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { ResponseError } from '../../apis';
import PayLinkSendErrorModal from '../../components/modals/PayLinkSendErrorModal';
import { formatErrorMessage } from '../../store/selectors/paylink';

type OwnProps = {
    payLinkId: string;
    url: string | null;
    resetForm: () => void;
};

interface StateProps {
    errorMessage: ResponseError | null;
}

interface State {
    showSendErrorModal: boolean;
}

type Props = OwnProps & StateProps & State;

class CreatePaylink4 extends React.Component<Props, State> {
    public state: State = {
        showSendErrorModal: this.props.errorMessage !== null,
    };

    onResetForm = () => {
        this.props.resetForm();
    };

    onViewDetails = () => {
        const { payLinkId } = this.props;
        history.push(selectors.global.payLinkRoute(payLinkId));
    };

    togglePayLinkSendErrorModal = () => this.setState({ showSendErrorModal: !this.state.showSendErrorModal });

    renderSuccess = () => {
        const { url, errorMessage } = this.props;
        const { showSendErrorModal } = this.state;
        return (
            <>
                {url && (
                    <div className="mb-5 pb-5">
                        <Row className="mb-2 pb-5">
                            <Col className="">
                                <div className="d-flex flex-column justify-content-center h-75">
                                    <div>
                                        <div className="mb-5 font-weight-bold">
                                            {errorMessage ? (
                                                <div>
                                                    <div>{formatErrorMessage(errorMessage)}</div>
                                                    <div>
                                                        Please visit the Details page to attempt to send the pay link
                                                        again.
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>The pay link has been sent.</p>
                                            )}
                                        </div>
                                        <div>You can also show this QR code to your customer.</div>
                                        <div>
                                            <a href={url}>{url}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <SHQRCode url={url} />
                            </Col>
                        </Row>
                    </div>
                )}
                <div className="mt-5 py-3 border-top-gray fixed-bottom bg-light">
                    <Row>
                        <Col xs={{ offset: 1, size: 5 }} md={{ offset: 4, size: 2 }}>
                            <Button
                                color="outline-primary"
                                className="mr-2  w-100 h-100"
                                data-sh="confirm-paylink-button"
                                size="lg"
                                onClick={() => this.onViewDetails()}
                            >
                                <span className="d-none d-md-block">View Details</span>
                                <span className="d-md-none">View</span>
                            </Button>
                        </Col>
                        <Col xs={{ size: 5 }} md={{ size: 2 }}>
                            <Button
                                color="outline-secondary"
                                className=" w-100 h-100"
                                data-sh="back-paylink-button"
                                size="lg"
                                onClick={() => this.onResetForm()}
                            >
                                <span className="d-none d-md-block">Create another paylink</span>
                                <span className="d-md-none">New</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                {errorMessage && (
                    <PayLinkSendErrorModal
                        toggle={this.togglePayLinkSendErrorModal}
                        showModal={showSendErrorModal}
                        error={errorMessage}
                        onCreate={true}
                    />
                )}
            </>
        );
    };

    render() {
        return (
            <div>
                <div className="my-3 text-center">{this.renderSuccess()}</div>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    errorMessage:
        selectors.global.getResponseError(state.global.actions, 'send_sms') ||
        selectors.global.getResponseError(state.global.actions, 'send_email'),
});

export default connect(mapStateToProps)(CreatePaylink4);
